import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: 'What is The Wellness?',
      answer:
        'The Wellness offers personalised AI-driven healthcare to re-energise your wellness and confidence. We connect patients to licensed healthcare professionals in London. We offer support for mental health, weight loss, hair regrowth, skincare, blood tests, and private GP appointments. Through our simple online process you can connect with licensed medical providers who can recommend customized treatment plans including prescription treatments, if appropriate, shipped right to your door.',
    },
    {
      question: 'How does The Wellness work?',
      answer: 'The Wellness offers a streamlined process for accessing healthcare services online, connecting users with licensed providers for personalized treatment plans.',
    },
    {
      question: 'Who are the providers at The Wellness?',
      answer: 'The providers at The Wellness are licensed healthcare professionals with expertise in various medical fields.',
    },
    {
      question: 'Does The Wellness require insurance?',
      answer: 'No, The Wellness services are available without the need for insurance. Pricing is transparent and affordable.',
    },
    {
      question: 'What country is The Wellness available in?',
      answer: 'The Wellness currently operates in our London Clinic only. The Wellness Los Angeles and Dubai are coming soon.',
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>
      <span>Frequently Asked Questions</span></h2>
      <ul className="faq-list">
        {faqData.map((faq, index) => (
          <li key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <span>{faq.question}</span>
              <p className="toggle-btn">{activeIndex === index ? '-' : '+'}</p>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
