// src/pages/BloodTestsPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import './ServiceSummary.css';

const BloodTestsPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/blood-tests');
  };

  return (
    <div className="service-summary-page">
      <div className="animated-bg-wave" />

      <SEO
        title="Comprehensive Blood Tests in London | Preventative Health | The Wellness London"
        description="Access comprehensive blood tests for preventative medicine in London. Early detection of potential conditions helps maintain optimal health."
        keywords="blood tests london, preventative medicine, blood work, medical tests, early detection"
      />

      <div className="service-summary-container">
        <h1>Blood Tests &amp; Preventative Medicine</h1>
        <p>
          Regular <strong>blood tests </strong> are key to early detection and ongoing
          management of many health conditions. At <em>The Wellness London</em>, we offer
          a wide range of panels, from basic wellness checks to advanced biomarker screening,
          ensuring you stay proactive about your health.
        </p>
        <p>
          <strong>Why Get Blood Tests Done?</strong><br />
          - <strong>Preventative Care</strong>: Spot potential issues—like anemia, thyroid problems, or cholesterol imbalances—before they become serious.<br />
          - <strong>Chronic Condition Monitoring</strong>: Keep tabs on existing conditions (e.g., diabetes, hormonal imbalances) and tailor treatments accordingly.<br />
          - <strong>Nutritional &amp; Hormonal Insights</strong>: Gain clarity on vitamin deficiencies, hormone levels, and other biomarkers to optimize your diet and lifestyle.<br />
          - <strong>Peace of Mind</strong>: A simple blood draw can provide invaluable insights into your overall wellbeing and help you make informed decisions about your health.
        </p>
        <p>
          Our lab partners use advanced technology to deliver accurate and timely results. We
          will discuss your results with you and recommend any necessary follow-up steps or
          lifestyle adjustments to support your best health.
        </p>

        <div className="button-wrapper" style={{ textAlign: 'center' }}>
  <button className="questionnaire-button" onClick={handleClick}>
  Your Personalised Treatment
  </button>
</div>

      </div>
    </div>
  );
};

export default BloodTestsPage;
