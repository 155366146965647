// HomePage.js

import React from 'react';
import './HomePage.css'; 
import Services from '../components/Services';
import Footer from '../components/Footer';
import FAQ from '../components/FAQ';
import PopularTreatments from '../components/PopularTreatments';
import CardsRow from '../components/CardsRow';
import GradientCard from '../components/GradientCard';
import HairLossBanner from '../LandingPages/HairLossBanner';

// 1) Import your SEO component
import SEO from '../components/SEO';

const HomePage = () => {
  // 2) Create your structured data object
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "MedicalClinic",
    "name": "The Wellness London Clinic",
    "url": "https://www.thewellnesslondon.com", // Replace with your site URL
    "logo": "https://thewellnesslondon.com/logo6.png", // Replace with your logo URL
    "image": "https://thewellnesslondon.com/logo6.png", // Replace with a representative image
    "description": "The Wellness London Clinic provides PRP therapy for hair, joints, face, and under-eye; blood tests; GP appointments; weight loss clinic; and Mounjaro prescriptions.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "10 Portman Square",
      "addressLocality": "London",
      "addressRegion": "London",
      "postalCode": "W1H 6AZ",
      "addressCountry": "UK"
    },
    "telephone": "+44 7799828614",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "07:00",
        "closes": "21:00"
      }
    ],
    "medicalSpecialty": "GeneralPractice",
    "priceRange": "$$"
  };

  return (
    <div>
      {/* 3) Add your SEO component at the top of your page content */}
      <SEO
        title="The Wellness London Clinic | PRP, GP Appointments & Weight Loss Clinic"
        description="Visit The Wellness London Clinic for advanced PRP (hair, joints, face, under-eye), comprehensive blood tests, GP appointments, weight loss clinic, and Mounjaro prescriptions."
        keywords="PRP hair, PRP joints, PRP face, PRP under eye, blood tests, GP appointments, weight loss clinic, Mounjaro prescriptions, The Wellness London Clinic, London healthcare"
        structuredData={structuredData}
      />

      {/* Page Content */}
      {/* First Hero Section */}
      <section className="hero">
        <Services />
      </section>

      <section className="hero1">
        <GradientCard />
      </section>

      <section className="hero2">
        <PopularTreatments />
      </section>

      <section className="hero3">
        <FAQ />
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;
