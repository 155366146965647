import React from "react";
import "./HairLossBanner.css";
import { useNavigate } from 'react-router-dom';

const BloodTestsBanner = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Update the path to wherever you want users to go for blood tests
    navigate('/blood-tests');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <section className="hairloss-banner">
      <div className="banner-content">
        
        {/* LEFT COLUMN: Heading Only */}
        <div className="left-column">
          <h1>Own Your Health. Outsmart Tomorrow’s Risks.</h1>
        </div>

        {/* RIGHT COLUMN: Checklist + CTA Box */}
        <div className="right-column">
          <ul className="checklist">
            <li>
              <span className="check-icon">&#10003;</span> Reveal hormone, cardiovascular, and organ red flags early
            </li>
            <li>
              <span className="check-icon">&#10003;</span> Clinically proven, cutting-edge testing methods
            </li>
            <li>
              <span className="check-icon">&#10003;</span> Personalized insights and expert guidance—completely online
            </li>
          </ul>
          <div className="cta-box">
            <p className="cta-text">
              Don’t wait for symptoms. Answer a few quick questions to build your custom prevention plan:
            </p>
            <button className="cta-button" onClick={handleClick}>
              Get Started <span className="arrow">→</span>
            </button>
          </div>
        </div>
      </div>

      {/* FOOTER / DISCLAIMER */}
      <p className="disclaimer">
        Restrictions may apply. See our website for full details and important safety information.
        <br />
        <sup>*</sup>Our advanced tests help you spot potential concerns early, promoting proactive care over reactive treatment.
      </p>
    </section>
  );
};

export default BloodTestsBanner;
