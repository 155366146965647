// src/components/CardsRow.js
import React from 'react';
import GradientCard from './GradientCard';

/* Example local images */
import ivfImg from '../images/ivf.png';
import glp1Img from '../images/GLP-1.png';

const CardsRow = () => {
  return (
    <div style={styles.container}>
      <GradientCard
        titlePart1="The Importance of"
        titleBoldPart="Hormone Tests"
        gradient="linear-gradient(to right, #fff5ce, #ffd6eb)"
        imageSrc={ivfImg}
        onClick={() => console.log('Hormone card clicked!')}
      />

      <GradientCard
        titlePart1="Why GLP-1s are the secret to"
        titleBoldPart="losing weight"
        gradient="linear-gradient(to right, #dff2e3, #dceaff)"
        imageSrc={glp1Img}
        onClick={() => console.log('GLP-1 card clicked!')}
      />
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '1400px',        // The entire row is up to 1400px wide
    margin: '0 auto',          // center horizontally
    display: 'flex',
    flexWrap: 'wrap',          // allow wrapping on smaller screens
    gap: '32px',               // space between cards
    padding: '40px 20px',      // overall padding
    boxSizing: 'border-box',
  },
};

export default CardsRow;
