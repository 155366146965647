import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PopularTreatments.css';
import ozempic from '../images/ozempic.png';
import therapyImage from '../images/therapy.png';
import bloodTestsImage from '../images/blood2.png';
import exosomesImage from '../images/exosome1.png';
import ivf from '../images/ivf.png';
import gal from '../images/gal.png';

const treatments = [
  {
    category: "Hormones",
    title: "Hormone Panels",
    bgColor: "#c3a2b9",
    image: ivf,
    route: "/learn-more/hormone-test",
    routes: "/blood-tests"
  },
  {
    category: "Weight",
    title: "GLP-1 Injections",
    bgColor: "#D2A46F",
    image: ozempic,
    route: "/learn-more/glp-1-injections",
    routes: "/weight-loss"
  },
  {
    category: "Energy",
    title: "Biomarker Blood Screen",
    bgColor: "#5A8CAC",
    image: bloodTestsImage,
    route: "/learn-more/biomarker-blood-screen",
    routes: "/blood-tests"
  },
  {
    category: "Anxiety",
    title: "Hair Growth Foam",
    bgColor: "#d7bbce",
    image: gal,
    route: "/hair-packages",
    routes: "/hair-packages"
  },
  {
    category: "Anxiety",
    title: "Personalised Therapy",
    bgColor: "#2B9046",
    image: therapyImage,
    route: "/learn-more/personalised-therapy",
    routes: "/therapy"
  },
  {
    category: "Skin",
    title: "Exosome Treatment",
    bgColor: "#9E7C59",
    image: exosomesImage,
    route: "/learn-more/exosome-treatment",
    routes: "/exosomes",
  }
];

const PopularTreatments = () => {
  const navigate = useNavigate();

  const handleLearnMore = (route) => {
    navigate(route);
  };
  
  const handleGetStarted = (routes) => {
    navigate(routes);
  };

  return (
    <div className="popular-treatments-wrapper">
      <div className="title-container">
        <h1 className="main-title">
          <span className="highlight">Specialist Treatments</span>
        </h1>
      </div>

      <div className="popular-treatments-container">
        <div className="cards-scroll-container">
          {treatments.map((treatment, index) => (
            <div
              key={index}
              className="treatment-card"
              style={{ backgroundColor: treatment.bgColor }}
            >
              <div className="card-content">
                {/* Top section: category, title, image */}
                <div className="top-section">
                  <h2 className="treatment-title">{treatment.title}</h2>
                
                </div>
                
                {/* Bottom section: safety info and buttons */}
                <div className="bottom-section">
                <img src={treatment.image} alt={treatment.title} className="treatment-image" />
                  <div className="buttons-container">
                    {/* Only show 'Get started' for treatments OTHER than Personalised Therapy or Exosome Treatment */}
                    {treatment.title !== "Personalised Therapy" && 
                     treatment.title !== "Exosome Treatment" && (
                      <button
                        className="get-started"
                        onClick={() => handleGetStarted(treatment.routes)}
                      >
                        Get started
                      </button>
                    )}
                    <button
                      className="learn-more"
                      onClick={() => handleLearnMore(treatment.route)}
                    >
                      Learn more
                    </button>
                  </div>
                </div>
                
              </div>
            </div>
          ))}
        </div>
        <a className="footer-note" href="#/safety" >Important safety information.
             </a>
      </div>
    </div>
  );
};

export default PopularTreatments;
