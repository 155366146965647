import React, { useState, useEffect } from 'react';
import './EnquiryPage.css';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const EnquiryPage = () => {
  const [formData, setFormData] = useState({
    treatment: '',
    name: '',
    number: '',
    email: '',
  });

  // New state to track submission success
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await addDoc(collection(db, 'enquiries'), {
        ...formData,
        timestamp: new Date(),
      });
      // If successful, show success text
      setSubmitted(true);

      // Clear the form
      setFormData({
        treatment: '',
        name: '',
        number: '',
        email: '',
      });
    } catch (err) {
      console.error('Error submitting enquiry:', err);
      setError('Error submitting enquiry. Please try again.');
    }
  };

  return (
    <div className={`page ${isVisible ? 'fade-in' : ''}`}>
      <div className="enquiry-container">
        <h1>Enquiry Form</h1>

        {/* Conditional Rendering for success or error */}
        {submitted && (
          <p style={{ color: 'green', textAlign: 'center', fontWeight: 'bold' }}>
            Your enquiry has been sent!
            <br />
            Email team@thewellnesslondon.com if you would like to follow up on any enquiry.
          </p>
        )}
        {error && (
          <p style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}>
            {error}
          </p>
        )}

        <form onSubmit={handleSubmit}>
          <label>
            <select
              name="treatment"
              placeholder="Select Treatment"
              value={formData.treatment}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Treatment</option>
              <option value="PRP">PRP</option>
              <option value="Private GP Appointment">Private GP Appointment</option>
              <option value="Exosomes">Exosomes</option>
              <option value="Weight Loss">Weight Loss</option>
              <option value="Therapy">Therapy</option>
              <option value="Blood Tests">Blood Tests</option>
            </select>
          </label>
          <label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            <input
              type="text"
              name="number"
              placeholder="Phone number"
              value={formData.number}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default EnquiryPage;
