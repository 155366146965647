// src/components/LearnMore/LearnMoreExosome.js
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import SEO from '../SEO';
import './LearnMorePage.css';
import exosomeImage from '../../images/exosome1.png';

const LearnMoreExosome = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <article className="learn-more-container">
      <SEO
        title="Exosome Treatment for Skin - The Wellness London"
        description="Explore how exosome treatments can rejuvenate your skin. Learn about the science, safety, and benefits at The Wellness London."
        keywords="exosome treatment, skin rejuvenation, wellness, skincare"
      />

      <motion.header
        className="learn-more-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h1>Exosome Treatment</h1>
        <p className="subheading">Advanced Skin Rejuvenation</p>
      </motion.header>

      <motion.section
        className="learn-more-hero"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2, duration: 0.6, ease: 'easeOut' }}
      >
        <img src={exosomeImage} alt="Exosome DNA strand illustration" className="learn-more-image" />
      </motion.section>

      <motion.section
        className="learn-more-content"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h2>What are Exosome Treatments?</h2>
        <p>
          Exosomes are tiny, naturally occurring vesicles that facilitate cell-to-cell communication. In skincare, 
          harnessing exosomes can promote collagen production, reduce inflammation, and support a more youthful complexion. 
          At The Wellness London, we use scientifically advanced exosome treatments to rejuvenate your skin.
        </p>

        <h2>How It Works</h2>
        <p>
          After evaluating your skin’s health, our experts apply exosomes topically or through micro-infusion to 
          encourage cell renewal. This can help improve skin texture, reduce fine lines, and enhance overall radiance. 
          The procedure is minimally invasive and tailored to your individual needs.
        </p>

        <h2>Important Safety Information</h2>
        <p>
          Exosome treatments are generally well-tolerated. As with any cosmetic procedure, there may be mild 
          redness or irritation post-treatment. Our team will provide detailed aftercare instructions and ensure you’re comfortable 
          with every aspect of the process.
        </p>

        <h2>Next Steps</h2>
        <p>
          Interested in revitalizing your skin with cutting-edge science? Schedule a consultation to learn if exosome 
          treatments are right for you and take the first step toward healthier, more radiant skin.
        </p>
      </motion.section>

      <motion.footer
        className="learn-more-footer"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <button className="primary-cta" onClick={() => (window.location.href = '#/exosomes')}>
          Get Started
        </button>
        <button className="secondary-cta" onClick={() => (window.location.href = '#/enquiry')}>
          Contact Us
        </button>
      </motion.footer>
    </article>
  );
};

export default LearnMoreExosome;
