// src/components/LearnMore/LearnMoreGLP1.js
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import SEO from '../SEO';
import './LearnMorePage.css';
import glp1Image from '../../images/ozempic.png';

const LearnMoreGLP1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <article className="learn-more-container">
      <SEO
        title="GLP-1 Injections for Weight Management - The Wellness London"
        description="Discover how GLP-1 injections can support your weight management goals. Learn about benefits, safety information, and what to expect at The Wellness London."
        keywords="GLP-1, weight management, injections, wellness, healthcare"
      />

      <motion.header
        className="learn-more-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h1>GLP-1 Injections for Weight Management</h1>
        <p className="subheading">A modern approach to supporting your health journey</p>
      </motion.header>

      <motion.section
        className="learn-more-hero"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2, duration: 0.6, ease: 'easeOut' }}
      >
        <img src={glp1Image} alt="GLP-1 Injection vial" className="learn-more-image" />
      </motion.section>

      <motion.section
        className="learn-more-content"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h2>What are GLP-1 Injections?</h2>
        <p>
          GLP-1 (Glucagon-Like Peptide-1) injections are a medically supervised treatment designed 
          to help regulate appetite, improve blood sugar control, and support sustainable weight management. 
          At The Wellness London, we tailor this therapy to your individual health profile, ensuring you receive 
          the most effective and safe care.
        </p>

        <h2>How It Works</h2>
        <p>
          GLP-1 is a naturally occurring hormone in your body that helps regulate how you digest food, feel full, 
          and maintain healthy blood sugar levels. By supplementing this hormone, GLP-1 injections can help reduce hunger, 
          making it easier to achieve and maintain a healthy weight. Our qualified healthcare professionals will guide 
          you through every step, from initial consultation to ongoing support.
        </p>

        <h2>Important Safety Information</h2>
        <p>
          Before starting any new treatment, it’s important to understand both the benefits and potential risks. 
          Our team will provide comprehensive safety guidelines, discuss possible side effects, and ensure that 
          GLP-1 injections are the right choice for your unique needs.
        </p>

        <h2>Next Steps</h2>
        <p>
          Ready to take the next step in your wellness journey? Book a consultation to learn more about how GLP-1 injections 
          can fit into your weight management program. Our friendly team is here to support you every step of the way.
        </p>
      </motion.section>

      <motion.footer
        className="learn-more-footer"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <button className="primary-cta" onClick={() => (window.location.href = '#/weight-loss')}>
          Get Started
        </button>
        <button className="secondary-cta" onClick={() => (window.location.href = '#/enquiry')}>
          Contact Us
        </button>
      </motion.footer>
    </article>
  );
};

export default LearnMoreGLP1;
