// Footer.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';
import logo from '../images/moccet.png';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer-content">
        {/* Additional footer content if needed */}
      </div>
      <div className="footer-bottom">
        <p>
          United Kingdom | London <br /> Copyright © 2024 The London Wellness
          Clinic Ltd. All rights reserved.
        </p>
        <div className="footer-links">
          <p onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
          <p onClick={() => navigate('/terms')}>Terms of Service</p>
          <p onClick={() => navigate('/site-map')}>Site Map</p>
        </div>
      </div>
      <img
            src={logo}
            alt="Moccet Logo"
            className="logo-powered"
          />
    </div>
  );
};

export default Footer;
