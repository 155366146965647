// src/components/SafetyInformationTabs.js

import React, { useState } from 'react';
import './Safety.css';
import SEO from './SEO';  // Your SEO component

const SafetyInformationTabs = () => {
  // State to keep track of the current active tab
  const [activeTab, setActiveTab] = useState('Weight Loss');

  // Define SEO data for each tab
  const seoData = {
    'Weight Loss': {
      title: 'Weight Loss Safety Information | The Wellness London',
      description:
        'Discover important safety details about our Weight Loss treatments, including Mounjaro, at The Wellness London. Learn about potential side effects, required assessments, and disclaimers.',
      keywords: 'weight loss, mounjaro, wellness london, diet, side effects, disclaimers',
    },
    Bloods: {
      title: 'Blood Tests Safety Information | The Wellness London',
      description:
        'Get informed about the safety of Blood Tests at The Wellness London. Understand potential risks, how to prepare, and aftercare steps to ensure accurate results and patient comfort.',
      keywords: 'blood tests, blood work, wellness london, side effects, disclaimers',
    },
    PRP: {
      title: 'PRP Safety Information | The Wellness London',
      description:
        'Platelet-Rich Plasma (PRP) therapy safety guidelines from The Wellness London. Learn about possible swelling, suitability, and how PRP can support joint and cosmetic benefits.',
      keywords: 'prp therapy, platelet-rich plasma, wellness london, disclaimers, therapy',
    },
    Exosome: {
      title: 'Exosome Safety Information | The Wellness London',
      description:
        'Understand Exosome therapy safety details at The Wellness London. Discover the emerging science, potential side effects, and guidelines for optimal care.',
      keywords: 'exosome therapy, regenerative medicine, wellness london, disclaimers',
    },
    Minoxidil: {
      title: 'Minoxidil Safety Information | The Wellness London',
      description:
        'Safety information for Minoxidil hair regrowth treatments at The Wellness London. Find out about application, possible side effects, and disclaimers for users.',
      keywords: 'minoxidil, hair regrowth, wellness london, disclaimers',
    },
    Therapy: {
      title: 'Therapy Safety Information | The Wellness London',
      description:
        'Explore safety considerations for therapy services at The Wellness London. Learn about mental health counseling, confidentiality, and disclaimers for potential clients.',
      keywords: 'therapy, mental health, wellness london, disclaimers',
    },
  };

  // Tab definitions
  const tabs = [
    { name: 'Weight Loss', label: 'Weight Loss' },
    { name: 'Bloods', label: 'Bloods' },
    { name: 'PRP', label: 'PRP' },
    { name: 'Exosome', label: 'Exosome' },
    { name: 'Minoxidil', label: 'Minoxidil' },
    { name: 'Therapy', label: 'Therapy' },
  ];

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      {/* Dynamically render SEO tags based on the active tab */}
      {seoData[activeTab] && (
        <SEO
          title={seoData[activeTab].title}
          description={seoData[activeTab].description}
          keywords={seoData[activeTab].keywords}
        />
      )}

      <div className="safety-tabs-container">
        {/* Animated background wave container (optional) */}
        <div className="animated-bg-wave1" />

        {/* Tab Headers */}
        <ul className="tabs-list">
          {tabs.map((tab) => (
            <li
              key={tab.name}
              className={activeTab === tab.name ? 'active' : ''}
              onClick={() => handleTabClick(tab.name)}
            >
              {tab.label}
            </li>
          ))}
        </ul>

        {/* Tab Content */}
        <div className="tab-content">
          {/* WEIGHT LOSS */}
          {activeTab === 'Weight Loss' && (
            <div className="tab-panel fade-in">
              <h3>Weight Loss Safety Information</h3>
              <p>
                Our weight loss treatments, including medications such as <strong>Mounjaro</strong>,
                are provided under the guidance of our licensed healthcare professionals.
                However, individual results can vary significantly based on factors such as
                personal health history, lifestyle, and compliance with recommended protocols.
              </p>
              <p>
                <strong>Key Points for Patients:</strong>
                <ul>
                  <li>
                    <strong>Medical Assessment Required:</strong> You must complete a thorough 
                    medical assessment to determine suitability for prescription weight loss 
                    treatments.
                  </li>
                  <li>
                    <strong>Potential Side Effects:</strong> Common side effects may include 
                    nausea, gastrointestinal upset, or changes in blood sugar. If you experience 
                    severe or persistent symptoms, contact us or seek medical attention.
                  </li>
                  <li>
                    <strong>Doctor Review Needed:</strong> All medications require a 
                    doctor’s review and authorization before you can begin treatment. 
                    Regular follow-ups help ensure safe progress.
                  </li>
                  <li>
                    <strong>No Guaranteed Outcomes:</strong> While many patients see positive 
                    results, effectiveness depends on individual factors. We do not guarantee 
                    specific weight loss achievements.
                  </li>
                </ul>
              </p>
              <p className="legal-disclaimer">
                <em>
                  **All statements are for informational purposes and are not intended as a 
                  substitute for professional medical advice. Always consult your healthcare 
                  provider regarding any treatments and follow their guidance closely.**
                </em>
              </p>
            </div>
          )}

          {/* BLOODS */}
          {activeTab === 'Bloods' && (
            <div className="tab-panel fade-in">
              <h3>Blood Tests (Bloods) Safety Information</h3>
              <p>
                Blood tests are an important diagnostic tool that can provide insight into
                various health conditions. Despite being generally safe, some individuals may
                experience discomfort or bruising.
              </p>
              <p>
                <strong>Key Points for Patients:</strong>
                <ul>
                  <li>
                    <strong>Discomfort and Bruising:</strong> Mild pain, bruising, or lightheadedness
                    after a blood draw can occur. These symptoms usually resolve quickly.
                  </li>
                  <li>
                    <strong>Disclosure of Conditions:</strong> If you have any known bleeding disorders
                    or take medications that affect clotting, please let our team know in advance.
                  </li>
                  <li>
                    <strong>Follow-Up:</strong> We provide results and guidance based on your tests. 
                    However, it is your responsibility to communicate any concerning symptoms and 
                    follow up with recommended care.
                  </li>
                </ul>
              </p>
              <p className="legal-disclaimer">
                <em>
                  **All blood tests must be properly interpreted by a healthcare professional. 
                  We are not liable for any untreated or mismanaged conditions that are not 
                  communicated to us or detected during testing.**
                </em>
              </p>
            </div>
          )}

          {/* PRP */}
          {activeTab === 'PRP' && (
            <div className="tab-panel fade-in">
              <h3>Platelet-Rich Plasma (PRP) Safety Information</h3>
              <p>
                PRP therapy uses components of your own blood to support tissue repair and 
                cell growth. It is often applied in joint health, hair restoration, and
                cosmetic treatments.
              </p>
              <p>
                <strong>Key Points for Patients:</strong>
                <ul>
                  <li>
                    <strong>Suitability Assessment:</strong> Not all patients are candidates 
                    for PRP. A thorough medical consultation will help determine if PRP is 
                    right for your specific condition.
                  </li>
                  <li>
                    <strong>Local Reactions:</strong> Mild swelling or discomfort at the 
                    injection site is common. If you notice signs of infection or severe 
                    pain, please seek prompt medical attention.
                  </li>
                  <li>
                    <strong>Variable Outcomes:</strong> PRP results can differ between individuals. 
                    We do not guarantee specific outcomes regarding hair growth, pain relief, or 
                    aesthetic improvement.
                  </li>
                </ul>
              </p>
              <p className="legal-disclaimer">
                <em>
                  **All procedures carry risk. Ensure you follow post-treatment instructions 
                  carefully and report any adverse effects. We disclaim liability for outcomes 
                  if aftercare guidelines are not followed.**
                </em>
              </p>
            </div>
          )}

          {/* EXOSOME */}
          {activeTab === 'Exosome' && (
            <div className="tab-panel fade-in">
              <h3>Exosome Safety Information</h3>
              <p>
                Exosome therapy is a novel, regenerative approach focusing on cell-to-cell
                communication and potential tissue repair. While research is evolving, many 
                patients find these treatments beneficial.
              </p>
              <p>
                <strong>Key Points for Patients:</strong>
                <ul>
                  <li>
                    <strong>Emerging Treatment:</strong> As exosome therapy is still relatively new, 
                    long-term data are limited. Your practitioner will help you understand potential 
                    benefits and risks.
                  </li>
                  <li>
                    <strong>Possible Side Effects:</strong> Mild swelling, redness, or immune reactions 
                    can occur. Notify us if you experience abnormal or persistent symptoms.
                  </li>
                  <li>
                    <strong>Professional Guidance:</strong> Exosome therapies must be administered 
                    by qualified professionals. Self-administration or unverified providers may 
                    present significant risks.
                  </li>
                </ul>
              </p>
              <p className="legal-disclaimer">
                <em>
                  **Outcomes and safety are not guaranteed due to the emerging nature of exosome 
                  research. We are not responsible for results outside our direct care or oversight.**
                </em>
              </p>
            </div>
          )}

          {/* MINOXIDIL */}
          {activeTab === 'Minoxidil' && (
            <div className="tab-panel fade-in">
              <h3>Minoxidil Safety Information</h3>
              <p>
                Minoxidil is widely used for hair regrowth, commonly applied topically to
                slow hair loss and stimulate new growth. Results can vary based on individual
                response.
              </p>
              <p>
                <strong>Key Points for Patients:</strong>
                <ul>
                  <li>
                    <strong>Proper Application:</strong> Follow instructions for application 
                    precisely to avoid irritation, scalp dryness, or unwanted hair growth in 
                    unintended areas.
                  </li>
                  <li>
                    <strong>Possible Side Effects:</strong> If you experience severe itching, 
                    rash, or other reactions, discontinue use and seek medical advice.
                  </li>
                  <li>
                    <strong>No Guarantee:</strong> Some patients may not experience significant 
                    improvement. Continuous use is often required to maintain results.
                  </li>
                </ul>
              </p>
              <p className="legal-disclaimer">
                <em>
                  **Our practice provides Minoxidil under a medically guided approach. Failure to 
                  follow directions or misuse may reduce effectiveness and increase risks. We are 
                  not liable for misuse outside our guidance.**
                </em>
              </p>
            </div>
          )}

          {/* THERAPY */}
          {activeTab === 'Therapy' && (
            <div className="tab-panel fade-in">
              <h3>Therapy Safety Information</h3>
              <p>
                Our therapy services can include mental health counseling, cognitive behavioral 
                therapy (CBT), and other supportive modalities. Personal growth and emotional 
                well-being are key objectives.
              </p>
              <p>
                <strong>Key Points for Clients:</strong>
                <ul>
                  <li>
                    <strong>Licensed Professionals:</strong> We strive to provide credentialed 
                    therapists who adhere to professional standards. Verify credentials if you 
                    have any concerns.
                  </li>
                  <li>
                    <strong>Variable Outcomes:</strong> Therapy benefits differ among individuals. 
                    Factors such as openness to change, consistency in sessions, and personal 
                    history affect results.
                  </li>
                  <li>
                    <strong>Not Emergency Care:</strong> Therapy sessions are not a substitute 
                    for crisis intervention. If you experience a mental health emergency, please 
                    contact emergency services immediately.
                  </li>
                </ul>
              </p>
              <p className="legal-disclaimer">
                <em>
                  **While we support your mental health journey, we cannot guarantee specific 
                  therapeutic outcomes. Ongoing communication and compliance with treatment 
                  recommendations are essential.**
                </em>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SafetyInformationTabs;
