// src/pages/ExosomesPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import './ServiceSummary.css';

const ExosomesPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/exosomes-questionnaire');
  };

  return (
    <div className="service-summary-page">
      <div className="animated-bg-wave" />

      <SEO
        title="Exosome Therapy in London | Advanced Regenerative Treatment | The Wellness London"
        description="Discover how exosome therapy can support cellular communication, tissue repair, and overall wellness. Evidence-backed approach for recovery and rejuvenation."
        keywords="exosomes, exosome therapy, regenerative medicine, cell repair, wellness london"
      />

      <div className="service-summary-container">
        <h1>Exosome Therapy</h1>
        <p>
          <strong>Exosomes</strong> are tiny, cell-derived vesicles that play a crucial role
          in cell-to-cell communication. Recent studies suggest they hold regenerative potential,
          supporting tissue repair and overall wellness. At <em>The Wellness London</em>, we
          harness this emerging science to deliver cutting-edge <strong>Exosome Therapy </strong>
          designed to enhance recovery and cellular function.
        </p>
        <p>
          <strong>Potential Benefits & Uses:</strong><br/>
          - <strong>Tissue Repair</strong>: May assist in accelerating healing after injury or surgery by promoting healthy cell growth.<br/>
          - <strong>Skin Rejuvenation</strong>: Exosomes could improve skin texture, hydration, and elasticity.<br/>
          - <strong>Inflammation Management</strong>: Emerging research indicates exosomes may help modulate inflammatory responses in the body.<br/>
          - <strong>Ongoing Research</strong>: While exosome therapy shows promise in regenerative medicine, large-scale studies are still in progress.
        </p>
        <p>
          Our approach emphasizes safety, thorough patient assessment, and a
          commitment to the latest evidence. If you’re seeking innovative,
          science-based solutions for rejuvenation or recovery, <strong>Exosome Therapy </strong>
          might be right for you.
        </p>

        <div className="button-wrapper" style={{ textAlign: 'center' }}>
  <button className="questionnaire-button" onClick={handleClick}>
  Your Personalised Treatment
  </button>
</div>

      </div>
    </div>
  );
};

export default ExosomesPage;
