import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";
import "../BLOOD/BloodTesting.css";
import middleLifestyle from "../../images/hair.png";
import arrow from "../../images/button.png";
import foam from '../../images/gal.png';
import joint from '../../images/joint.png';
import undereye from '../../images/undereye.png';
import mask from '../../images/mask.png';
import klarna from '../../images/klarna.png';

function BloodTesting() {
  const location = useLocation();

  const tests = [
    {
      id: 0,
      title: "PRP Only",
      price: "£395.00",
      middleImage: middleLifestyle,
      middleClass: "image-lifestyle1",
      stripeLink: "https://buy.stripe.com/eVa6rndG95J36LCcMR",
    },
    {
      id: 1,
      title: "PRP + Hair Growth Foam",
      price: "£495.00",
      middleImage: foam,
      middleClass: "image-lifestyle",
      stripeLink: "https://buy.stripe.com/00g6rn9pT0oJ1ri8wA",
    },
    {
      id: 2,
      title: "Joint PRP + Collagen + Glucosamine supplements",
      price: "£650.00",
      middleImage: joint,
      middleClass: "image-lifestyle",
      stripeLink: "https://buy.stripe.com/cN26rn59D3AVb1SaER",
    },
    {
        id: 2,
        title: "Under-eye PRP",
        price: "£285.00",
        middleImage: undereye,
        middleClass: "image-lifestyle",
        stripeLink: "https://buy.stripe.com/dR6eXT0Tnefzc5WbIW",
      },

      {
        id: 2,
        title: "Facial PRP",
        price: "£345.00",
        middleImage: mask,
        middleClass: "image-lifestyle",
        stripeLink: "https://buy.stripe.com/bIYeXT59D0oJ1ri28n",
      },
  ];

  const [openModalId, setOpenModalId] = useState(null);
  const cardsRef = useRef([]);

  // Payment success logic
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useState("");

  // If user came from BloodTestsJourney => location.state.userData
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Animate with GSAP
    gsap.fromTo(
      cardsRef.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.2,
        stagger: 0.2,
        ease: "power3.out",
      }
    );
  }, []);

  useEffect(() => {
    // If we have location.state, user came from the 14-page journey
    if (location.state && location.state.userData) {
      setUserData(location.state.userData);
    }
  }, [location]);

  const handleOpenModal = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setOpenModalId(id);
  };
  const handleCloseModal = () => setOpenModalId(null);

  // On card click => store data + open Stripe
  const openStripeLink = (link, test) => {
    // We'll store the user data in localStorage
    const storeObj = {
      ...userData,
      packageName: test.title,
    };
    localStorage.setItem("bloodUserData", JSON.stringify(storeObj));

    // Then open Stripe in a new tab or same tab
    window.open(link, "_blank", "noopener,noreferrer");
  };

  // On mount or re-render, if URL has #/blood-test-success, final Slack update
  useEffect(() => {
    if (window.location.href.includes("#/blood-test-success")) {
      const stored = localStorage.getItem("bloodUserData");
      if (!stored) return;
      const storedData = JSON.parse(stored);

      const pkgName = storedData.packageName || "Unknown Package";
      setPurchasedPackage(pkgName);
      setPurchaseSuccess(true);

      // Post Slack update => /api/slack
      fetch("/api/slack", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fullName: storedData.fullName || "",
          email: storedData.email || "",
          phone: storedData.phone || "",
          packageName: pkgName,
          message: `Blood Test purchase successful: ${pkgName}`,
        }),
      })
        .then((res) => {
          if (!res.ok) throw new Error("Slack request failed");
        })
        .catch((err) => {
          console.error("Error updating Slack after purchase:", err);
        })
        .finally(() => {
          // Clear localStorage
          localStorage.removeItem("bloodUserData");
        });
    }
  }, []);

  return (
    <div className="blood-testing-container">
      {/* if purchaseSuccess => show success message, else show the original structure */}
      {!purchaseSuccess ? (
        <>
          <div className="placeholder-text1">
            <h1>Better Health</h1>
            <p>personalised to you</p>
          </div>

          <div className="cards-container">
            {tests.map((test, index) => (
              <div
                className="card-wrapper"
                key={test.id}
                ref={(el) => (cardsRef.current[index] = el)}
              >
                <div
                  className="test-card11"
                  onClick={() => openStripeLink(test.stripeLink, test)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="card-content">
                    <h2>{test.title}</h2>
                    <img
                      src={test.middleImage}
                      alt={`${test.title} - Additional Preview`}
                      className={`middle-image ${test.middleClass}`}
                    />
                  </div>
                  <div className="price-row">
                    <span className="purchase-link">{test.price}</span>
                    <img className="arrow-icon-right" src={arrow} alt="Arrow" />
                  </div>
                </div>

                <p className="outside-text">{test.text}</p>
              </div>
            ))}
                        <img
                      src={klarna}
                      alt="klarna available"
                      className="klarna"
                    />
          </div>
        </>
      ) : (
        <div className="success-section">
          <h2>Congratulations!</h2>
          <p>
            Your payment was successful for{" "}
            <strong>{purchasedPackage}</strong>. 
            A member of the team will be in contact soon.
          </p>
        </div>
      )}
    </div>
  );
}

export default BloodTesting;
