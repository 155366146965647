import React from 'react';
import './LandingPage.css';
import Footer from '../components/Footer';
import ResultsSection from './BloodResults';
import CardScroll from './BloodScroll';
import BloodTestsBanner from './BloodBanner';
import PreventativeBanner from './Blood2Banner';
import VideoPlayer from './VideoPlayer';

const HomePage = () => {
  return (
    <div>
      {/* First Hero Section */}
      <section className="Landing1">
      <BloodTestsBanner />
      </section>
      <section className="Landing2">
      <PreventativeBanner/>
      </section>
      <section className="Landing212">
      <VideoPlayer/>
      </section>
      <section className="Landing3">
      <ResultsSection />
      </section>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;
