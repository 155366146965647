// src/pages/PrivacyPolicy.js

import React, { useEffect } from 'react';
import './PrivacyPolicy.css'; // CSS for styling
import SEO from '../components/SEO'; // If you have an SEO component

const PrivacyPolicy = () => {
  // Scroll to top on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title="Privacy Policy | The Wellness London"
        description="Read our comprehensive Privacy Policy, which details how The Wellness London collects, stores, and protects your personal data, in compliance with UK law."
        keywords="privacy policy, data protection, GDPR, personal data, The Wellness London"
      />

      <div className="privacy-page">
        <div className="animated-bg-wave2" />
        <div className="privacy-container">
          <h1>Privacy Policy</h1>
          <p>
            Welcome to The Wellness London (“<strong>we</strong>,” “
            <strong>us</strong>,” or “<strong>our</strong>”). Your privacy is
            of paramount importance to us. This Privacy Policy outlines how we
            collect, use, disclose, secure, and otherwise process your personal
            data in compliance with the UK General Data Protection Regulation
            (UK GDPR), the Data Protection Act 2018, and other applicable laws.
            By accessing or using our services, you acknowledge that you have
            read, understood, and agree to the practices described herein.
          </p>

          {/* 1. Scope */}
          <h2>1. Scope of This Policy</h2>
          <p>
            This Privacy Policy applies to all personal data collected via our
            website (<strong>thewellnesslondon.com</strong>), mobile/online
            questionnaires, subdomains, or any direct communications with us
            (collectively, the “<strong>Services</strong>”). It forms part of
            our terms and conditions governing your use of our Services. In the
            event of any conflict between this Policy and other statements, the
            more protective provision to us shall apply unless otherwise
            specified by law.
          </p>

          {/* 2. Data Controller */}
          <h2>2. Data Controller</h2>
          <p>
            The data controller responsible for your personal data is
            <strong> The Wellness London</strong>, a UK-based entity. We reserve
            the right to appoint additional or alternative data controllers or
            processors if our business needs require it. We will update this
            Policy accordingly where legally required.
          </p>

          {/* 3. Personal Data We Collect */}
          <h2>3. Personal Data We Collect</h2>
          <p>
            <strong>(a) Information You Provide Voluntarily</strong><br />
            - <strong>Contact Information:</strong> Such as your name, email
            address, phone number, or postal address, which you provide when
            making an enquiry, creating an account, or booking an appointment.
            <br />
            - <strong>Health &amp; Medical Data:</strong> If you fill out our
            questionnaires or otherwise disclose information relating to your
            health history, concerns, or treatment goals. This constitutes
            sensitive personal data, processed <strong>only</strong> with your
            explicit consent and solely for the purposes you have permitted.{' '}
            <br />
            - <strong>Payment Details (if applicable):</strong> Where relevant
            to process transactions or confirm bookings. We do not store full
            payment card details on our servers; any storage or handling is done
            through secure, PCI-compliant service providers.
          </p>
          <p>
            <strong>(b) Information Automatically Collected</strong><br />
            - <strong>Technical Data:</strong> IP addresses, browser types,
            device identifiers, operating system details, etc. These may be
            collected via cookies or similar technologies to optimize and secure
            our Services.<br />
            - <strong>Usage Data:</strong> Interaction logs (e.g., pages
            visited, time spent, links clicked) to help us improve functionality
            and user experience.
          </p>
          <p>
            <strong>(c) Accuracy of Data:</strong> You are responsible for
            ensuring that any personal data you provide to us is complete,
            accurate, and up to date. We disclaim liability for any issues that
            arise from your provision of incomplete or inaccurate data.
          </p>

          {/* 4. Legal Basis for Processing */}
          <h2>4. Our Legal Basis for Processing Personal Data</h2>
          <p>
            We process your personal data in accordance with UK GDPR, relying
            upon one or more of the following legal grounds:
          </p>
          <ul>
            <li>
              <strong>Consent:</strong> Where you have clearly consented to
              processing for a specified purpose (e.g., completing a health
              questionnaire).
            </li>
            <li>
              <strong>Contractual Necessity:</strong> Where processing is
              required to fulfill our contractual obligations to you or to take
              steps prior to entering into a contract.
            </li>
            <li>
              <strong>Legal Obligation:</strong> Where processing is essential
              for compliance with a legal requirement under UK law or EU law
              (where applicable).
            </li>
            <li>
              <strong>Legitimate Interests:</strong> Where processing is
              necessary to pursue our legitimate interests (e.g., securing our
              Services, improving user experience), except where your rights
              override those interests.
            </li>
          </ul>
          <p>
            In the context of sensitive data, especially health-related
            information, we will secure explicit consent or rely upon other
            specific legal bases permissible under data protection law (e.g.,
            medical diagnosis or preventive healthcare).
          </p>

          {/* 5. How We Use Your Data */}
          <h2>5. How We Use Your Personal Data</h2>
          <p>
            We may use your data for the following purposes, including but not
            limited to:
          </p>
          <ul>
            <li>
              <strong>Providing Services:</strong> Scheduling appointments,
              delivering personalized treatments, creating and managing your
              account or booking, and communicating essential information about
              your health inquiries.
            </li>
            <li>
              <strong>Customer Support:</strong> Addressing your questions,
              feedback, or complaints, and resolving any technical or logistical
              issues.
            </li>
            <li>
              <strong>Marketing &amp; Promotions:</strong> With your consent,
              sending you updates about new services, offers, or wellness tips.
              You can withdraw this consent at any time without affecting the
              lawfulness of prior communications.
            </li>
            <li>
              <strong>Site Analytics &amp; Improvement:</strong> Monitoring user
              behavior to enhance our Services, identify usage trends, and
              troubleshoot technical difficulties.
            </li>
            <li>
              <strong>Legal &amp; Regulatory Compliance:</strong> Cooperating
              with legal, regulatory, or public authority requests where
              required by law.
            </li>
          </ul>

          {/* 6. Cookies and Similar Technologies */}
          <h2>6. Cookies and Similar Technologies</h2>
          <p>
            We use cookies, web beacons, and other similar technologies to
            personalize and optimize your experience, understand user behavior,
            and facilitate analytics. Depending on your cookie consent
            preferences, certain features of our Services may be limited if you
            reject or disable cookies. For more information, please review our
            dedicated <strong>Cookie Policy</strong> (if available), which forms
            part of this Privacy Policy.
          </p>

          {/* 7. How We Share Your Data */}
          <h2>7. Disclosure of Personal Data</h2>
          <p>
            We do <strong>not</strong> sell or rent your personal data to any
            third parties. However, we may share your data under circumstances
            including:
          </p>
          <ul>
            <li>
              <strong>Trusted Service Providers:</strong> Payment processors, IT
              support, cloud hosting, analytics services, or subcontractors who
              process data on our behalf. We require such providers to adhere to
              contractual obligations to maintain confidentiality, data
              integrity, and security.
            </li>
            <li>
              <strong>Legal Obligations &amp; Protection:</strong> Where we
              believe in good faith that disclosure is necessary to comply with
              an applicable law, enforce our terms, protect our property, or
              safeguard the rights, safety, or well-being of our clients, staff,
              or the public.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the context of a merger,
              acquisition, or sale of company assets. In such events, we will
              make reasonable efforts to ensure that the acquiring entity
              upholds the privacy commitments we have made to you.
            </li>
            <li>
              <strong>Consent:</strong> Where you have expressly agreed to
              particular disclosures (e.g., sharing medical data with a
              specialist or external partner).
            </li>
          </ul>
          <p>
            We disclaim liability for third-party acts or omissions beyond what
            is required under applicable law. However, we will take reasonable
            steps to ensure that any recipient of your data provides the same
            level of data protection as required by the UK GDPR.
          </p>

          {/* 8. International Transfers */}
          <h2>8. International Data Transfers</h2>
          <p>
            If your personal data is transferred outside the UK or EEA, we
            implement appropriate safeguards (such as Standard Contractual
            Clauses or equivalent mechanisms) to ensure a level of data
            protection that meets UK GDPR standards. You may request details of
            these safeguards by contacting us.
          </p>

          {/* 9. Data Retention */}
          <h2>9. Data Retention</h2>
          <p>
            We will only retain your personal data for as long as is
            <strong> strictly necessary</strong> to fulfill the purposes
            outlined in this Privacy Policy, including satisfying any legal,
            accounting, or reporting requirements. Once these purposes are met,
            we will securely delete or anonymize your data. We may retain
            minimal information (e.g., transaction records) to comply with legal
            obligations or to protect our legal interests.
          </p>

          {/* 10. Your Rights */}
          <h2>10. Your Privacy Rights</h2>
          <p>
            Under the UK GDPR and applicable data protection laws, you have the
            right to:
          </p>
          <ul>
            <li>
              <strong>Access:</strong> Obtain confirmation that we are
              processing your data, and request a copy of the personal data we
              hold about you.
            </li>
            <li>
              <strong>Rectification:</strong> Correct or complete any
              inaccurate or incomplete personal data.
            </li>
            <li>
              <strong>Erasure (“Right to be Forgotten”):</strong> Request
              deletion of your personal data where there is no overriding
              legitimate ground for us to continue processing.
            </li>
            <li>
              <strong>Restriction of Processing:</strong> Limit how we process
              your data in certain scenarios (e.g., if the accuracy of your data
              is contested).
            </li>
            <li>
              <strong>Data Portability:</strong> Receive your personal data in a
              structured, commonly used, and machine-readable format, and
              transmit it to another controller where technically feasible.
            </li>
            <li>
              <strong>Object:</strong> Object to processing based on legitimate
              interests, or for direct marketing purposes, at any time.
            </li>
            <li>
              <strong>Withdraw Consent:</strong> If we rely on consent to
              process your data, you may revoke it at any time without affecting
              the legality of any processing conducted prior to withdrawal.
            </li>
          </ul>
          <p>
            To exercise any of these rights, please contact us at the details
            provided below. We may need to verify your identity before
            fulfilling your request, and certain exemptions may apply under
            relevant law. Failure to provide the information needed for
            verification may delay or limit our ability to comply with your
            request.
          </p>

          {/* 11. Security Measures */}
          <h2>11. Security Measures</h2>
          <p>
            We implement appropriate technical and organizational measures
            (e.g., encryption, access controls, secure hosting environments) to
            protect your data against unauthorized or unlawful processing, and
            against accidental loss, destruction, or damage. However, no system
            is impenetrable. We disclaim liability for breaches caused by events
            beyond our reasonable control, such as sophisticated cyberattacks or
            force majeure.
          </p>

          {/* 12. Third-Party Links */}
          <h2>12. Third-Party Links</h2>
          <p>
            Our website or Services may include links to third-party websites,
            plugins, or content not operated by us. We do not control, endorse,
            or assume responsibility for the privacy practices of these external
            entities. We encourage you to review their privacy policies before
            submitting any personal data.
          </p>

          {/* 13. Updates to This Policy */}
          <h2>13. Changes to This Privacy Policy</h2>
          <p>
            We reserve the right to modify or update this Privacy Policy at any
            time. Any significant changes will be communicated via email (if you
            have provided one) or by prominently posting a notice on our site,
            along with the “Effective Date” at the top of this policy. Your
            continued use of our Services after the revised Policy takes effect
            will be deemed acceptance of the updated terms, except where
            prohibited by law.
          </p>

          {/* 14. Disclaimers and Liability */}
          <h2>14. Disclaimers and Liability</h2>
          <p>
            While we strive to comply fully with applicable data protection
            regulations, <strong>we do not guarantee</strong> that our Services
            will be error-free or immune from malicious attacks. To the maximum
            extent permitted by law, we shall not be liable for any indirect,
            incidental, consequential, or punitive damages arising out of or
            related to data protection incidents, unless such incidents result
            from our wilful misconduct or gross negligence.
          </p>
          <p>
            <strong>No Medical Advice:</strong> The information and services
            provided by The Wellness London are for informational purposes only
            and do not constitute medical advice, diagnosis, or treatment. We
            disclaim liability for any adverse outcomes if you rely solely on
            our content without consulting a qualified medical practitioner.
          </p>

          {/* 15. Contact Us */}
          <h2>15. Contact Us</h2>
          <p>
            If you have questions, concerns, or requests regarding this Privacy
            Policy or wish to exercise any of your data protection rights,
            please contact:
          </p>
          <p>
            <strong>The Wellness London</strong>
            <br />
            Unit 4, 10 Portman Square, W1H 6AZ,
            <br />
            London, UK
            <br />
            <a href="mailto:team@thewellnesslondon.com">team@thewellnesslondon.com</a>
            <br />
            Phone: +44 (0) 7399 323620
          </p>
          <p className="closing-note">
            Thank you for reviewing our Privacy Policy. We remain committed to
            maintaining the integrity and security of your personal data in
            accordance with applicable law.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
