import React from 'react';
import './LandingPage.css';
import Footer from '../components/Footer';
import FAQ from '../components/FAQ';
import PopularTreatments from '../components/PopularTreatments';
import HairBanner from './HairBanner';
import HairLossBanner from './HairLossBanner';
import ResultsSection from './ResultsSection';
import CardScroll from './CardScroll';
import HairProducts from '../QUESTIONNAIRES/HAIRPRODUCTS/HairPackages';
import CanvaAnimation from './CanvaAnimation';

const HomePage = () => {
  return (
    <div>
      {/* First Hero Section */}
      <section className="Landing1">
      <HairLossBanner />
      </section>
      <section className="Landing2">
      <HairBanner />
      </section>
      <section className="Landing3">
      <ResultsSection />
      </section>
      <section className="Landing3">
      <CanvaAnimation />
      </section>
      <section className="Landing21">
      <CardScroll />
      </section>
      <section className="Landing4">
      <HairProducts />
      </section>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;
