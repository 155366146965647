// src/pages/PrivateGPPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import './ServiceSummary.css';

const PrivateGPPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to your private GP questionnaire
    navigate('/private-gp-intro');
  };

  return (
    <div className="service-summary-page">
      <div className="animated-bg-wave" />

      <SEO
        title="Private GP in London | Same-Day Appointments | The Wellness London"
        description="Book a same-day private GP appointment in London for prompt, personalized healthcare. Our dedicated team ensures minimal wait times, comprehensive check-ups, and ongoing medical support."
        keywords="private gp london, same-day gp appointment, medical clinic, private healthcare, gp services"
      />

      <div className="service-summary-container">
        <h1>Private GP Services in London</h1>
        <p>
          Experience prompt, personalized healthcare without the long waiting times. Our
          <strong> Private GP</strong> service offers same-day or next-day appointments,
          ensuring you receive the medical attention you need as soon as possible. Our team
          believes in a patient-centric approach, providing thorough check-ups, preventative
          screenings, and ongoing support for chronic conditions.
        </p>
        <p>
          <strong>Why Choose Private GP?</strong><br />
          - <strong>Fast and Flexible Appointments</strong>: Skip the wait and book at a time that fits your schedule.<br />
          - <strong>Personalized Care</strong>: From minor ailments to long-term health management, we tailor every step to your unique medical history.<br />
          - <strong>Extended Consultations</strong>: Get the time you deserve with our doctors, discussing all health concerns in a relaxed environment.<br />
          - <strong>Referrals to Specialists</strong>: If specialist care is required, we connect you to our network of trusted experts in London.
        </p>
        <p>
          Whether you’re dealing with a sudden illness or need routine check-ups, our
          <strong> Private GP in London</strong> will ensure you’re on the right path to
          optimal health. We combine modern medical practices with compassionate care
          to deliver an unparalleled healthcare experience.
        </p>

        <div className="button-wrapper" style={{ textAlign: 'center' }}>
  <button className="questionnaire-button" onClick={handleClick}>
  Your Personalised Treatment
  </button>
</div>
      </div>
    </div>
  );
};

export default PrivateGPPage;
