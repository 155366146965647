import React, { useState, useEffect } from 'react';
import '../PRP/BloodTestsHairJourney.css'; // Ensure the path is correct; contains our new fadeIn classes
import { db } from '../../firebase';  // Adjust path if needed
import { collection, addDoc } from 'firebase/firestore';

const WeightLossJourney = () => {
  // ---------------------------------------------------------------------------
  // 1) Page & Answers State
  // ---------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 14;

  // We'll hold user answers in a single object
  const [answers, setAnswers] = useState({
    goal: null,              
    pastDiets: null,         
    activity: null,          
    medicalConditions: null, 
    stressLevel: null,       
    habits: null,            
    budget: null,            
    pace: null,              
    contactMethod: null,     
    fullName: '',
    email: '',
    phone: '',
  });

  // 2) Progress bar & thinking overlay
  const [progress, setProgress] = useState(0);
  const [showThinking, setShowThinking] = useState(false);

  // 3) Summaries for final pages
  const [summaryProse, setSummaryProse] = useState('');
  const [finalIntro, setFinalIntro] = useState('');

  // 4) Submission states
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  // ---------------------------------------------------------------------------
  // UTILS
  // ---------------------------------------------------------------------------
  const goToPage = (num) => {
    setCurrentPage(num);
    updateProgressBar(num);

    // If user arrives on Page 7, fill the mid-journey summary
    if (num === 7) {
      fillMidReview();
    }
  };

  const storeAnswer = (key, value) => {
    setAnswers((prev) => ({ ...prev, [key]: value }));
    console.log(`Storing answer: ${key} = ${value}`);
  };

  const updateProgressBar = (targetPage) => {
    const fraction = (targetPage - 1) / (totalPages - 1);
    const percentage = Math.min(100, fraction * 100);
    setProgress(percentage);
  };

  // Highlights the chosen contact method
  const getContactButtonClass = (method) =>
    answers.contactMethod === method ? 'choice-btn active' : 'choice-btn';

  // ---------------------------------------------------------------------------
  // PAGE 7: Mid-Journey Check-In
  // ---------------------------------------------------------------------------
  const fillMidReview = () => {
    const goalMap = {
      loseFew: 'Lose a moderate amount',
      addressObesity: 'Address significant/obesity-level weight',
      maintain: 'Maintain / tone up',
      unsure: 'Not sure yet',
    };
    const dietMap = {
      yesSuccess: 'Some success in the past',
      yesFail: 'Tried but weight returned',
      no: 'No formal attempts',
    };
    const activityMap = {
      sedentary: 'Sedentary (<5k steps)',
      light: 'Light (occasional workouts)',
      moderate: 'Moderate (2–3 sessions/week)',
      intense: 'Intense / daily exercise',
    };
    const condMap = {
      none: 'No major issues',
      hypertension: 'High Blood Pressure',
      diabetes: 'Diabetes / Prediabetes',
      thyroid: 'Thyroid',
      other: 'Other condition(s)',
    };

    const box = document.getElementById('midReviewBox');
    if (!box) return;

    // Build the short summary
    const g = goalMap[answers.goal] || 'N/A';
    const d = dietMap[answers.pastDiets] || 'N/A';
    const a = activityMap[answers.activity] || 'N/A';
    const c = condMap[answers.medicalConditions] || 'N/A';

    box.innerHTML = `
      <strong>Goal:</strong> ${g}<br>
      <strong>Previous Diet Attempts:</strong> ${d}<br>
      <strong>Activity:</strong> ${a}<br>
      <strong>Medical Factors:</strong> ${c}
    `;
  };

  // ---------------------------------------------------------------------------
  // PAGE 12 -> 13 with "thinking" overlay
  // ---------------------------------------------------------------------------
  const showThinkingOverlay = () => {
    // Hide current page
    setCurrentPage((prev) => {
      const pageEl = document.getElementById(`page${prev}`);
      if (pageEl) pageEl.classList.remove('active');
      return prev;
    });
    // Show overlay
    setShowThinking(true);

    // After 2s, hide overlay, build summary, go to page13
    setTimeout(() => {
      setShowThinking(false);
      buildProseSummary();
      goToPage(13);
    }, 2000);
  };

  // ---------------------------------------------------------------------------
  // PAGE 13: Build final summary
  // ---------------------------------------------------------------------------
  const buildProseSummary = () => {
    const goalMap = {
      loseFew: 'a moderate (10–15kg) reduction',
      addressObesity: 'tackling a more significant or obesity-level concern',
      maintain: 'maintaining or slightly improving your shape',
      unsure: 'clarifying your ideal targets',
    };
    const dietMap = {
      yesSuccess: 'had some success previously',
      yesFail: 'didn’t maintain results from past diets',
      no: 'not tried any formal plan',
    };
    const actMap = {
      sedentary: 'mostly sedentary',
      light: 'light or occasional exercise',
      moderate: 'moderate workouts (2–3/week)',
      intense: 'intense daily exercise',
    };
    const condMap = {
      none: 'no major conditions',
      hypertension: 'high blood pressure',
      diabetes: 'diabetes/prediabetes',
      thyroid: 'a thyroid issue',
      other: 'other considerations',
    };

    const g = goalMap[answers.goal] || 'some form of weight improvement';
    const d = dietMap[answers.pastDiets] || 'unspecified';
    const a = actMap[answers.activity] || 'an unspecified routine';
    const c = condMap[answers.medicalConditions] || 'no major medical flags';
    const s = answers.stressLevel || 'standard stress factors';
    const h = answers.habits || 'general eating triggers';
    const b = answers.budget || 'no specific budget given';
    const p =
      answers.pace === 'steady'
        ? 'a slower, more consistent route'
        : 'a faster, more intensive plan';

    const finalText = `
      You’re aiming for <strong>${g}</strong>, having <strong>${d}</strong>. 
      Your exercise routine is <strong>${a}</strong>, with <strong>${c}</strong> to keep in mind. 
      You mention <strong>${s}</strong> in your lifestyle, and your main habit or trigger is <strong>${h}</strong>. 
      You’re comfortable investing around <strong>${b}</strong> per month, 
      preferring <strong>${p}</strong> for weight loss.
      <br><br>
      At <strong>The Wellness</strong>, we can offer:
      <ul style="list-style-type:disc; margin: 10px 0 0 20px;">
        <li><em>GLP-1 medication</em> (if approved), to curb cravings and supercharge results.</li>
        <li><em>Personalised diet &amp; fitness</em> guides, fitting your schedule &amp; preferences.</li>
        <li><em>Regular GP check-ins</em>, ensuring you never feel alone.</li>
        <li><em>Convenient UK pharmacy delivery</em> if medication is part of your plan.</li>
      </ul>
      Many pay <strong>£600–£700/month</strong> on partial solutions (meal kits, PT sessions). 
      Our comprehensive plan often costs less—yet provides stronger medical backing, appetite control, 
      and ongoing guidance for a genuinely transformative journey.
    `;
    setSummaryProse(finalText);
  };

  // ---------------------------------------------------------------------------
  // PAGE 14: Final submission (Firestore)
  // ---------------------------------------------------------------------------
  const finishJourney = async (e) => {
    e.preventDefault();
    const { fullName, email, phone } = answers;

    if (!fullName || !email || !phone) {
      setError('Please fill in your name, email, and phone to proceed.');
      return;
    }

    try {
      // Store in Firestore
      await addDoc(collection(db, 'weightLossJourneys'), {
        ...answers,
        timestamp: new Date(),
      });

      setSubmitted(true);

      // Build final success text
      setFinalIntro(`
        Thank you, <strong>${fullName}</strong>. We’ll contact you 
        via <strong>${answers.contactMethod || 'Email/Phone'}</strong> shortly 
        to confirm any medical checks and set up your first step. 
        We’re thrilled to guide you—let’s make this transformation happen, together!
      `);
    } catch (err) {
      console.error('Error submitting Weight Loss journey:', err);
      setError('Sorry, there was a problem submitting your information. Please try again.');
    }
  };

  // ---------------------------------------------------------------------------
  // On mount or page change, recalc progress
  // ---------------------------------------------------------------------------
  useEffect(() => {
    updateProgressBar(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  // Helper to show/hide pages
  const pageIsActive = (pageNumber) =>
    currentPage === pageNumber ? 'form-page active' : 'form-page';

  // ---------------------------------------------------------------------------
  // RENDER
  // ---------------------------------------------------------------------------
  return (
    <div className="outer-container">
      {/* Progress Bar */}
      <div className="progress-bar-fixed">
        <div className="progress-wrap">
          <div className="progress-bar" style={{ width: progress + '%' }}></div>
        </div>
      </div>

      {/* Container with fade-in on mount */}
      <div className="container fade-in">

        {/* PAGE 1 */}
        <div className={pageIsActive(1)} id="page1">
          <h2 className="fadeInRightTitle">Unlock Lasting Weight Loss</h2>
          <p className="fadeInRightContent">
            At <strong>The Wellness</strong>, we believe in a steady, sustainable approach—no crash diets or endless workouts. 
            Our doctor-led plans pair tailored nutrition and fitness with <em>GLP-1 medication</em> (if you qualify) 
            to curb hunger, letting you feel full on less.
          </p>
          <div className="info-box fadeInRightContent">
            <p>
              <strong>GLP-1 injections</strong> (e.g. semaglutide) can help you lose <strong> 15–20% of your body weight</strong> when paired with a healthy lifestyle. 
              If it’s right for you, we’ll handle secure UK pharmacy delivery plus frequent GP check-ins 
              for maximum safety and support.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(2)}>
            Next
          </button>
        </div>

        {/* PAGE 2 */}
        <div className={pageIsActive(2)} id="page2">
          <h2 className="fadeInRightTitle">Here’s What We’ll Cover</h2>
          <p className="fadeInRightContent">
            <ul style={{ listStyleType: 'disc', margin: '12px 0 16px 20px' }}>
              <li>Your weight loss goals &amp; health background</li>
              <li>Any past diets or medical considerations</li>
              <li>A glimpse of our plan, including GLP-1 medication and diet/fitness advice</li>
            </ul>
            Then we’ll reveal exactly how we can help—showing you a custom plan, often at a 
            fraction of the cost of private meal deliveries or personal trainers.
          </p>
          <p className="testimonial-bubble fadeInRightContent" style={{ animationDelay: '0.6s' }}>
            “After trying fad diets for years, I finally found success with GLP-1 support. 
             Lost <strong>2 stone in 5 months</strong>, and The Wellness GPs gave me 
             total confidence. — <em>Sandra, London</em>”
          </p>
          <button className="btn fadeInRightContent" onClick={() => goToPage(3)}>
            Begin
          </button>
        </div>

        {/* PAGE 3: Weight Goal */}
        <div className={pageIsActive(3)} id="page3">
          <h2 className="fadeInRightTitle">1. Your Primary Weight Goal</h2>
          <p className="fadeInRightContent">
            Are you aiming to lose a moderate amount, tackle obesity, or maintain/shape up?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('goal', 'loseFew');
                goToPage(4);
              }}
            >
              Lose a moderate amount
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('goal', 'addressObesity');
                goToPage(4);
              }}
            >
              Address significant/obesity-level weight
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('goal', 'maintain');
                goToPage(4);
              }}
            >
              Maintain / Tone up
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('goal', 'unsure');
                goToPage(4);
              }}
            >
              Not sure, need guidance
            </button>
          </div>
          <div className="testimonial-bubble fadeInRightContent" style={{ animationDelay: '0.6s' }}>
            “I shed <strong>12 kg</strong> in just 3 months with their plan. 
             Having a GP and daily appetite support changed everything! — <em>Rob, Manchester</em>”
          </div>
        </div>

        {/* PAGE 4: Past Diet Attempts */}
        <div className={pageIsActive(4)} id="page4">
          <h2 className="fadeInRightTitle">2. Have You Tried Any Diets or Plans Before?</h2>
          <p className="info-box fadeInRightContent">
            This helps us avoid repeating what didn’t work, or refine strategies that did. 
            Calorie counting, keto, fasting, or any weight loss medication—tell us about it.
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pastDiets', 'yesSuccess');
                goToPage(5);
              }}
            >
              Yes, had some success
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pastDiets', 'yesFail');
                goToPage(5);
              }}
            >
              Yes, but it didn’t last
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pastDiets', 'no');
                goToPage(5);
              }}
            >
              No formal attempts
            </button>
          </div>
        </div>

        {/* PAGE 5: Activity Level */}
        <div className={pageIsActive(5)} id="page5">
          <h2 className="fadeInRightTitle">3. Current Activity Level</h2>
          <p className="fadeInRightContent">
            How active are you weekly? This helps shape your fitness recommendations, 
            whether that’s gentle walks or more advanced workouts.
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('activity', 'sedentary');
                goToPage(6);
              }}
            >
              Sedentary / &lt;5k steps
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('activity', 'light');
                goToPage(6);
              }}
            >
              Light (walks, occasional workouts)
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('activity', 'moderate');
                goToPage(6);
              }}
            >
              Moderate (2–3 sessions/week)
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('activity', 'intense');
                goToPage(6);
              }}
            >
              Intense or daily exercise
            </button>
          </div>
        </div>

        {/* PAGE 6: Medical Conditions */}
        <div className={pageIsActive(6)} id="page6">
          <h2 className="fadeInRightTitle">4. Any Known Medical Conditions?</h2>
          <p className="fadeInRightContent">
            High blood pressure, diabetes, thyroid issues, or anything else that might affect your plan?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalConditions', 'none');
                goToPage(7);
              }}
            >
              None / Unsure
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalConditions', 'hypertension');
                goToPage(7);
              }}
            >
              High Blood Pressure
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalConditions', 'diabetes');
                goToPage(7);
              }}
            >
              Diabetes / Prediabetes
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalConditions', 'thyroid');
                goToPage(7);
              }}
            >
              Thyroid
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalConditions', 'other');
                goToPage(7);
              }}
            >
              Other / multiple
            </button>
          </div>
          <div className="testimonial-bubble fadeInRightContent" style={{ animationDelay: '0.6s' }}>
            “My doctor and The Wellness worked together to ensure GLP-1 was safe. 
             I’ve dropped <strong>10 kg</strong>, and my blood pressure is nearly normal! — <em>Mia, Leeds</em>”
          </div>
        </div>

        {/* PAGE 7: Mid-Journey Check-In */}
        <div className={pageIsActive(7)} id="page7">
          <h2 className="fadeInRightTitle">Mid-Journey Check-In</h2>
          <p className="fadeInRightContent">
            Here’s what we’ve gathered about you so far:
          </p>
          <div className="info-box fadeInRightContent" id="midReviewBox"></div>
          <p className="fadeInRightContent">
            Based on these answers, you might be suitable for GLP-1 medication. 
            But first, let's learn more about your lifestyle, triggers, and budget 
            before revealing your personalised plan.
          </p>
          <button className="btn fadeInRightContent" onClick={() => goToPage(8)}>
            Continue
          </button>
        </div>

        {/* PAGE 8: Stress & Lifestyle */}
        <div className={pageIsActive(8)} id="page8">
          <h2 className="fadeInRightTitle">5. Stress & Lifestyle Factors</h2>
          <p className="fadeInRightContent">
            How often do stress, emotional eating, or poor sleep disrupt your weight management?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'often');
                goToPage(9);
              }}
            >
              Often
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'sometimes');
                goToPage(9);
              }}
            >
              Sometimes
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'rarely');
                goToPage(9);
              }}
            >
              Rarely
            </button>
          </div>
        </div>

        {/* PAGE 9: Eating Habits */}
        <div className={pageIsActive(9)} id="page9">
          <h2 className="fadeInRightTitle">6. Eating Habits</h2>
          <p className="fadeInRightContent">Which best describes you?</p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('habits', 'multitasking');
                goToPage(10);
              }}
            >
              I multitask while eating
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('habits', 'stressSnacker');
                goToPage(10);
              }}
            >
              I snack when stressed
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('habits', 'comfortFood');
                goToPage(10);
              }}
            >
              I turn to food for comfort
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('habits', 'foodReward');
                goToPage(10);
              }}
            >
              I reward myself with food
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('habits', 'socialDining');
                goToPage(10);
              }}
            >
              Frequent social dining
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('habits', 'other');
                goToPage(10);
              }}
            >
              Other triggers
            </button>
          </div>
        </div>

        {/* PAGE 10: Emotional Benefit */}
        <div className={pageIsActive(10)} id="page10">
          <h2 className="fadeInRightTitle">7. What Would Meeting Your Goal Mean?</h2>
          <p className="fadeInRightContent">
            Whether it’s more confidence, better health, or fitting into certain clothes—this is your deeper motivation.
          </p>
          <div className="info-box fadeInRightContent">
            <p>
              Our most successful members say the biggest changes go beyond the scales—it's the newfound energy, 
              the self-belief, and the freedom from constant dieting struggles.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(11)}>
            Next
          </button>
        </div>

        {/* PAGE 11: Budget */}
        <div className={pageIsActive(11)} id="page11">
          <h2 className="fadeInRightTitle">8. Monthly Budget</h2>
          <p className="info-box fadeInRightContent">
            Some fancy meal services can cost <strong>£600–£700/month</strong> alone. 
            Our GP-led programme—potentially including medication, personal diet &amp; fitness plans, 
            and ongoing support—often comes in lower. Which range best suits you?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('budget', '£200');
                goToPage(12);
              }}
            >
              ~£200
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('budget', '£300');
                goToPage(12);
              }}
            >
              ~£300
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('budget', '£400+');
                goToPage(12);
              }}
            >
              £400+
            </button>
          </div>
        </div>

        {/* PAGE 12: Pace & Thinking Overlay */}
        <div className={pageIsActive(12)} id="page12">
          <h2 className="fadeInRightTitle">9. Preferred Pace</h2>
          <p className="fadeInRightContent">
            Do you prefer a gentler, more gradual approach, or a faster, more intensive route?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pace', 'steady');
                showThinkingOverlay();
              }}
            >
              Steady &amp; manageable
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pace', 'intensive');
                showThinkingOverlay();
              }}
            >
              Faster, more intensive transformation
            </button>
          </div>
        </div>

        {showThinking && (
          <div className="thinking-overlay" style={{ display: 'flex' }}>
            <h3>Finalising Your Personalised Plan...</h3>
            <p>Just a moment while we integrate your details.</p>
            <div className="spinner"></div>
          </div>
        )}

        {/* PAGE 13: Final Reveal */}
        <div className={pageIsActive(13)} id="page13">
          <h2 className="fadeInRightTitle">Your Tailor-Made Weight Loss Programme</h2>
          <p
            id="summaryProse"
            className="fadeInRightContent"
            style={{ marginBottom: '16px' }}
            dangerouslySetInnerHTML={{ __html: summaryProse }}
          ></p>
          <div className="cta-block fadeInRightContent">
            <p style={{ margin: 0, fontSize: '14px', color: '#777' }}>
              We accept only a limited number of new clients monthly, ensuring everyone gets 
              true GP-led attention. If GLP-1 is clinically safe, we’ll supply it via a UK pharmacy, 
              plus personal diet &amp; fitness guidance—and frequent check-ins to keep you on track.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(14)}>
            Next
          </button>
        </div>

        {/* PAGE 14: Contact Form & Final CTA */}
        <div
          id="page14"
          className={`form-page ${pageIsActive(14)} ${!submitted ? 'not-submitted' : ''}`}
        >
          {/* If not submitted, show heading */}
          {!submitted && <h2 className="fadeInRightTitle">Final Step: Confirm Your Details</h2>}

          {/* Error if any */}
          {error && (
            <p
              style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}
              className="fadeInRightContent"
            >
              {error}
            </p>
          )}

          {/* If submitted, show success text */}
          {submitted && (
            <div className="success-message">
              <p className="success-title">
                Your Weight Loss Submission was successful!
              </p>
              <p
                id="finalPlanIntro"
                dangerouslySetInnerHTML={{ __html: finalIntro }}
              ></p>
            </div>
          )}

          {/* If not submitted, show form */}
          {!submitted && (
            <>
              <p
                id="finalPlanIntro"
                className="fadeInRightContent"
                style={{ marginBottom: '16px' }}
              >
                Ready to start? Please fill in your details so we can finalise your plan.
              </p>
              <form onSubmit={finishJourney} className="fadeInRightContent">
                <label htmlFor="fullName">
                  Full Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  id="fullName"
                  required
                  value={answers.fullName}
                  onChange={(e) => storeAnswer('fullName', e.target.value)}
                />

                <label htmlFor="email">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  required
                  value={answers.email}
                  onChange={(e) => storeAnswer('email', e.target.value)}
                />

                <label htmlFor="phone">
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  placeholder="+44 ..."
                  required
                  value={answers.phone}
                  onChange={(e) => storeAnswer('phone', e.target.value)}
                />

                <h3 style={{ marginTop: '15px' }}>Preferred Contact Method</h3>
                <div className="choice-container" style={{ marginBottom: 0 }}>
                  <button
                    className={getContactButtonClass('Email')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Email')}
                  >
                    Email
                  </button>
                  <button
                    className={getContactButtonClass('Call')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Call')}
                  >
                    Call
                  </button>
                  <button
                    className={getContactButtonClass('WhatsApp')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'WhatsApp')}
                  >
                    WhatsApp
                  </button>
                  <button
                    className={getContactButtonClass('NoCall')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'NoCall')}
                  >
                    No call, just start now
                  </button>
                </div>

                <button className="btn" style={{ marginTop: '20px' }}>
                  Submit &amp; Begin
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeightLossJourney;
