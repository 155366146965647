import React, { useState, useEffect } from 'react';
import '../PRP/BloodTestsHairJourney.css'; // Reuse the .css with fadeInRightTitle & fadeInRightContent
import { db } from '../../firebase';  // Adjust if needed
import { collection, addDoc } from 'firebase/firestore';
// If using React Router, you might also want:
// import { useNavigate } from 'react-router-dom';

const GpJourney = () => {
  // -------------------------------------------------------------------------
  // 1) Page & Answers State
  // -------------------------------------------------------------------------
  // Now start on page 0 instead of 1
  const [currentPage, setCurrentPage] = useState(0);

  // Increase totalPages by 1 (we now have pages 0..11 = 12 pages total)
  const totalPages = 12;

  // We'll hold user answers in a single object
  const [answers, setAnswers] = useState({
    reason: null,
    concerns: null,
    pastGP: null,
    frequency: null,
    stressLevel: null,
    budget: null,
    urgency: null,
    style: null,
    contactMethod: null,
    fullName: '',
    email: '',
    phone: '',
  });

  // 2) For progress bar & "thinking" overlay
  const [progress, setProgress] = useState(0);
  const [showThinking, setShowThinking] = useState(false);

  // 3) Summaries for page10 + final success text
  const [summaryProse, setSummaryProse] = useState('');
  const [gpPlanSuggestions, setGpPlanSuggestions] = useState(''); // New for condition-based recommendations
  const [finalText, setFinalText] = useState('');

  // 4) Submission states
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  // -------------------------------------------------------------------------
  // UTILS
  // -------------------------------------------------------------------------
  const goToPage = (num) => {
    setCurrentPage(num);
    updateProgressBar(num);

    // If user just landed on Page 6, fill the mid-journey summary
    if (num === 6) {
      fillMidReview();
    }
  };

  const storeAnswer = (key, value) => {
    setAnswers((prev) => ({ ...prev, [key]: value }));
    console.log(`Storing: ${key} = ${value}`);
  };

  const updateProgressBar = (targetPage) => {
    // If on page 0, show 0% progress
    if (targetPage <= 0) {
      setProgress(0);
      return;
    }
    // Otherwise, calculate fraction from page 1..11
    const fraction = (targetPage - 1) / (totalPages - 1);
    const percentage = Math.min(100, fraction * 100);
    setProgress(percentage);
  };

  // A small helper to highlight the chosen contact method button
  const getContactButtonClass = (method) =>
    answers.contactMethod === method ? 'choice-btn active' : 'choice-btn';

  // -------------------------------------------------------------------------
  // PAGE-FLOW
  // -------------------------------------------------------------------------
  const chooseBudget = (value) => {
    // No alerts anymore; storing and continuing
    storeAnswer('budget', value);
    goToPage(9);
  };

  // Page9 -> page10 with short "thinking" overlay
  const showThinkingOverlay = () => {
    // Hide current page
    setCurrentPage((prev) => {
      document.getElementById(`page${prev}`)?.classList.remove('active');
      return prev;
    });
    // Show overlay
    setShowThinking(true);

    setTimeout(() => {
      setShowThinking(false);
      buildSummary(); // build final summary for page10
      goToPage(10);
    }, 2000);
  };

  // -------------------------------------------------------------------------
  // PAGE6: Mid-Journey summary (review)
  // -------------------------------------------------------------------------
  const fillMidReview = () => {
    let reasonStr;
    switch (answers.reason) {
      case 'fasterAppt':
        reasonStr = 'Faster Appointments / Avoiding Delays';
        break;
      case 'secondOpinion':
        reasonStr = 'Expert Second Opinion / Specialist Guidance';
        break;
      case 'ongoingCare':
        reasonStr = 'Long-Term Ongoing Care';
        break;
      default:
        reasonStr = 'General Check-Up';
    }

    let concernsStr;
    switch (answers.concerns) {
      case 'newSymptoms':
        concernsStr = 'New or lingering symptoms (cough, rash, minor pains)';
        break;
      case 'chronicIssue':
        concernsStr = 'Chronic conditions needing management';
        break;
      case 'mentalHealth':
        concernsStr = 'Mental health or stress concerns';
        break;
      default:
        concernsStr = 'No specific or major issues';
    }

    let pastGPStr;
    switch (answers.pastGP) {
      case 'yes':
        pastGPStr = 'Recently consulted a GP in the last 6 months';
        break;
      case 'longAgo':
        pastGPStr = 'No GP consult for over 6 months';
        break;
      default:
        pastGPStr = 'Haven’t seen a doctor recently';
    }

    let freqStr;
    switch (answers.frequency) {
      case 'oneOff':
        freqStr = 'Likely just a one-off appointment';
        break;
      case 'fewTimes':
        freqStr = 'A few sessions per year';
        break;
      default:
        freqStr = 'More regular or ongoing care';
    }

    const box = document.getElementById('midReviewBox');
    if (box) {
      box.innerHTML = `
        <strong>Primary Reason:</strong> ${reasonStr}<br>
        <strong>Current Concerns:</strong> ${concernsStr}<br>
        <strong>Past GP Visits:</strong> ${pastGPStr}<br>
        <strong>Appointment Frequency:</strong> ${freqStr}
      `;
    }
  };

  // -------------------------------------------------------------------------
  //  Condition-Based GP Recommendations
  // -------------------------------------------------------------------------
  const getGpPlan = () => {
    const recs = [];

    // If user has newSymptoms or mentalHealth => consider thorough check-up
    if (answers.concerns === 'newSymptoms' || answers.concerns === 'mentalHealth') {
      recs.push(
        'A thorough 30-minute consult to discuss new symptoms, mental well-being, or lifestyle changes.'
      );
    }

    // If user has chronicIssue => highlight regular check-ups or advanced follow-ups
    if (answers.concerns === 'chronicIssue') {
      recs.push(
        'Ongoing GP support with follow-up sessions to keep your chronic condition under control.'
      );
    }

    // If user is 'ongoingCare' => mention membership or discounted follow-ups
    if (answers.reason === 'ongoingCare') {
      recs.push(
        'Our ongoing GP membership plan for discounted follow-ups and priority booking.'
      );
    }

    // If user is 'fasterAppt' => mention same-day or next-day availability
    if (answers.reason === 'fasterAppt') {
      recs.push('Same-day or next-day appointment slots to avoid NHS wait times.');
    }

    // If no specific reason => generic "Flexible scheduling" or "Peace of mind"
    if (recs.length === 0) {
      recs.push('A flexible GP consult tailored to your needs—book at your convenience.');
    }

    // Convert to HTML
    let recHTML = '<ul>';
    recs.forEach((r) => {
      recHTML += `<li>${r}</li>`;
    });
    recHTML += '</ul>';
    return recHTML;
  };

  // -------------------------------------------------------------------------
  // PAGE10: Build final summary + recommendations
  // -------------------------------------------------------------------------
  const buildSummary = () => {
    let reasonTxt;
    switch (answers.reason) {
      case 'fasterAppt':
        reasonTxt = 'you need quick, convenient GP access without waiting weeks';
        break;
      case 'secondOpinion':
        reasonTxt = 'you’re looking for an expert second opinion or deeper guidance';
        break;
      case 'ongoingCare':
        reasonTxt = 'you want ongoing GP support';
        break;
      default:
        reasonTxt = 'you’re seeking a general check-up';
    }

    let concernsTxt;
    switch (answers.concerns) {
      case 'newSymptoms':
        concernsTxt = 'new or lingering symptoms (like cough, rash, minor pains)';
        break;
      case 'chronicIssue':
        concernsTxt = 'a chronic condition that needs regular management';
        break;
      case 'mentalHealth':
        concernsTxt = 'mental health or stress-related concerns';
        break;
      default:
        concernsTxt = 'no specific issues—just a general consult';
    }

    let pastTxt;
    switch (answers.pastGP) {
      case 'yes':
        pastTxt = 'recently saw a GP (within the last 6 months)';
        break;
      case 'longAgo':
        pastTxt = 'no GP visits for over 6 months';
        break;
      default:
        pastTxt = 'haven’t consulted a GP in quite a while';
    }

    let freqTxt;
    switch (answers.frequency) {
      case 'oneOff':
        freqTxt = 'one-off appointment to address immediate needs';
        break;
      case 'fewTimes':
        freqTxt = 'a few GP sessions per year';
        break;
      default:
        freqTxt = 'ongoing, more frequent support';
    }

    let stressTxt = answers.stressLevel || 'no major stressors mentioned';
    let budgetTxt = answers.budget || 'an unspecified budget range';
    let styleTxt =
      answers.style === 'inPerson'
        ? 'prefer an in-person consult'
        : 'prefer a virtual consult';
    let urgencyTxt =
      answers.urgency === 'immediate'
        ? 'looking to see someone as soon as possible'
        : 'planning ahead';

    const finalProse = `
      We see that <strong>${reasonTxt}</strong>, with <strong>${concernsTxt}</strong>. 
      You’ve <strong>${pastTxt}</strong>, and anticipate <strong>${freqTxt}</strong>.
      Meanwhile, you have <strong>${stressTxt}</strong>, and indicated <strong>${budgetTxt}</strong>
      in mind. You’re <strong>${urgencyTxt}</strong> and <strong>${styleTxt}</strong>
      for your private GP appointments.<br><br>
      
      Great news: you’re fully eligible to see our <strong>senior GPs</strong> at The Wellness. 
      We can help streamline test referrals, provide thorough check-ups, and ensure your concerns 
      are addressed quickly—no more waiting. Whether it’s a one-off or a 
      longer-term relationship, our specialists will adapt to your schedule and healthcare goals.
      <br><br>
      Many patients find private GP appointments 
      reduce anxiety and uncertainty—knowing a doctor is readily available and truly invested in their health 
      can give you priceless peace of mind.
    `;
    setSummaryProse(finalProse);

    // Condition-based plan suggestions
    const planHtml = getGpPlan();
    setGpPlanSuggestions(planHtml);
  };

  // -------------------------------------------------------------------------
  // PAGE11: Final Form Submission (Firestore)
  // -------------------------------------------------------------------------
  const finishJourney = async (e) => {
    e.preventDefault();
    const { fullName, email, phone } = answers;

    if (!fullName || !email || !phone) {
      setError('Please fill in your name, email, and phone so we can finalise your appointment.');
      return;
    }

    try {
      // Attempt to add to Firestore (create a new doc in "gpJourneys")
      await addDoc(collection(db, 'gpJourneys'), {
        ...answers,
        timestamp: new Date(),
      });

      setSubmitted(true);

      // Build final success text
      setFinalText(`
        Thank you, <strong>${fullName}</strong>. We’ll confirm your private GP appointment details 
        very soon—so you can get the care you need right away, from a senior doctor who truly listens.
      `);
    } catch (err) {
      console.error('Error submitting GP journey data:', err);
      setError('Sorry, there was a problem submitting your information. Please try again.');
    }
  };

  // -------------------------------------------------------------------------
  // On mount or page change, recalc progress
  // -------------------------------------------------------------------------
  useEffect(() => {
    updateProgressBar(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  // A helper to conditionally render pages
  const pageIsActive = (pageNumber) =>
    currentPage === pageNumber ? 'form-page active' : 'form-page';

  // -------------------------------------------------------------------------
  // RENDER
  // -------------------------------------------------------------------------
  return (
    <div className="outer-container">
      {/* PROGRESS BAR */}
      <div className="progress-bar-fixed">
        <div className="progress-wrap">
          <div className="progress-bar" style={{ width: progress + '%' }}></div>
        </div>
      </div>

      <div className="container fade-in">
        {/* PAGE 0: New “Welcome” Page with Two Buttons */}
        <div className={pageIsActive(0)} id="page0">
          <h2 className="fadeInRightTitle">Welcome to Your GP Journey</h2>
          <p className="fadeInRightContent">
            Choose one of the options below to continue:
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => goToPage(1)}
            >
              Personalise My Care
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                // If using React Router: navigate('/gp-packages')
                // For plain JS:
                window.location.href = '#/gp-packages';
              }}
            >
              Book GP Now
            </button>
          </div>
        </div>

        {/* PAGE 1: Intro */}
        <div className={pageIsActive(1)} id="page1">
          <h2 className="fadeInRightTitle">Discover the Freedom of Private GP Care</h2>
          <p className="fadeInRightContent">
            At <strong>The Wellness</strong>, our dedicated team of <strong>senior GPs</strong> offers 
            faster access, more flexible scheduling, and truly personalized care. 
            Over the next few questions, we’ll uncover exactly why you’re here 
            so we can book the appointment that best supports your health goals—whether it’s a quick check-up, 
            an in-depth review, or continuous care.
          </p>
          <div className="info-box fadeInRightContent">
            <p>
              <strong>Important:</strong> We do not handle emergency situations. 
              If you believe you’re experiencing a serious or life-threatening issue (chest pain, severe injury, etc.), 
              please dial 999 or go to the nearest A&amp;E immediately.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(2)}>
            Start
          </button>
        </div>

        {/* PAGE 2: Reason for GP */}
        <div className={pageIsActive(2)} id="page2">
          <h2 className="fadeInRightTitle">1. What Brings You to a Private GP?</h2>
          <p className="fadeInRightContent">
            Are you looking for a faster appointment, a second opinion, or ongoing GP access that’s more convenient?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('reason', 'fasterAppt');
                goToPage(3);
              }}
            >
              Faster Appointments / Avoid Delays
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('reason', 'secondOpinion');
                goToPage(3);
              }}
            >
              Second Opinion / Specialist Guidance
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('reason', 'ongoingCare');
                goToPage(3);
              }}
            >
              Long-Term Ongoing Care
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('reason', 'general');
                goToPage(3);
              }}
            >
              General Check-Up
            </button>
          </div>
          <div className="testimonial-bubble fadeInRightContent" style={{ animationDelay: '0.6s' }}>
            "Booking a private GP appointment was a godsend—I was seen the same day, 
            and my concerns were handled thoroughly by a senior doctor.
            <br/>— <em>Amelia, London</em>"
          </div>
        </div>

        {/* PAGE 3: Specific Health Concerns */}
        <div className={pageIsActive(3)} id="page3">
          <h2 className="fadeInRightTitle">2. Any Specific Health Concerns Right Now?</h2>
          <p className="info-box fadeInRightContent">
            For instance, unresolved aches, persistent coughs, minor injuries, mental health concerns, or 
            anything else you’d like a GP to check out.
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('concerns', 'newSymptoms');
                goToPage(4);
              }}
            >
              New Symptoms (cough, rash, minor pains)
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('concerns', 'chronicIssue');
                goToPage(4);
              }}
            >
              Chronic Issue / Condition Management
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('concerns', 'mentalHealth');
                goToPage(4);
              }}
            >
              Mental Health / Stress
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('concerns', 'no');
                goToPage(4);
              }}
            >
              None / General Check
            </button>
          </div>
        </div>

        {/* PAGE 4: Past GP or Specialist Experience */}
        <div className={pageIsActive(4)} id="page4">
          <h2 className="fadeInRightTitle">3. Have You Seen a GP or Specialist Recently?</h2>
          <p className="fadeInRightContent">
            If so, is there any ongoing diagnosis or treatment plan you’d like us to review?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pastGP', 'yes');
                goToPage(5);
              }}
            >
              Yes, within last 6 months
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pastGP', 'longAgo');
                goToPage(5);
              }}
            >
              Yes, over 6 months ago
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pastGP', 'no');
                goToPage(5);
              }}
            >
              No, haven’t seen a doctor recently
            </button>
          </div>
        </div>

        {/* PAGE 5: Frequency of Needing Appointments */}
        <div className={pageIsActive(5)} id="page5">
          <h2 className="fadeInRightTitle">4. Anticipated Frequency of GP Support</h2>
          <p className="fadeInRightContent">
            Do you foresee a single appointment to address an immediate concern, or multiple sessions for ongoing needs?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('frequency', 'oneOff');
                goToPage(6);
              }}
            >
              Likely just a one-off
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('frequency', 'fewTimes');
                goToPage(6);
              }}
            >
              A few times a year
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('frequency', 'regular');
                goToPage(6);
              }}
            >
              Regular / Ongoing care
            </button>
          </div>
          <div className="testimonial-bubble fadeInRightContent" style={{ animationDelay: '0.6s' }}>
            "I initially thought I’d need just one check, but having ongoing access to a 
            dedicated GP at The Wellness has been incredibly reassuring.
            <br/>— <em>Matt, Manchester</em>"
          </div>
        </div>

        {/* PAGE 6: Mid-Journey Review */}
        <div className={pageIsActive(6)} id="page6" style={{ animation: 'fadeInUp 0.6s forwards ease' }}>
          <h2 className="fadeInRightTitle">Mid-Journey Check-In</h2>
          <p className="fadeInRightContent">
            Great job so far! Here’s a quick look at what we’ve noted about your GP needs:
          </p>
          <div className="info-box fadeInRightContent" id="midReviewBox"></div>
          <p className="fadeInRightContent">
            At The Wellness, you already qualify for a private GP appointment with our 
            <strong> senior specialists</strong>. We can address new or ongoing concerns promptly, 
            schedule check-ups around your timetable, and facilitate any needed referrals—often 
            faster than traditional routes.
          </p>
          <p className="fadeInRightContent">
            Let’s gather a few more details about your budget and appointment preferences, 
            then we’ll show you how quickly we can get you seen.
          </p>
          <button className="btn fadeInRightContent" onClick={() => goToPage(7)}>
            Continue
          </button>
        </div>

        {/* PAGE 7: Lifestyle & Stress Factors */}
        <div className={pageIsActive(7)} id="page7">
          <h2 className="fadeInRightTitle">5. Lifestyle &amp; Stress</h2>
          <p className="fadeInRightContent">
            How often do stress, limited sleep, or time constraints factor into 
            why you’re seeking a private GP (instead of or alongside the NHS)?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'often');
                goToPage(8);
              }}
            >
              Often
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'sometimes');
                goToPage(8);
              }}
            >
              Sometimes
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'rarely');
                goToPage(8);
              }}
            >
              Rarely
            </button>
          </div>
        </div>

        {/* PAGE 8: Budget */}
        <div className={pageIsActive(8)} id="page8">
          <h2 className="fadeInRightTitle">6. Your Budget Range</h2>
          <p className="info-box fadeInRightContent">
            Private GP appointments can vary in cost depending on complexity, tests, and follow-ups. 
            Which approximate range suits you best?
          </p>
          <div className="choice-container fadeInRightContent">
            <button className="choice-btn" onClick={() => chooseBudget('<£100')}>
              Under £100
            </button>
            <button className="choice-btn" onClick={() => chooseBudget('£100-£200')}>
              £100–£200
            </button>
            <button className="choice-btn" onClick={() => chooseBudget('£200+')}>
              £200+
            </button>
          </div>
        </div>

        {/* PAGE 9: Urgency & Appointment Preference */}
        <div className={pageIsActive(9)} id="page9">
          <h2 className="fadeInRightTitle">7. Urgency &amp; Appointment Preference</h2>
          <p className="fadeInRightContent">
            Are you seeking an appointment ASAP, or planning ahead? Do you prefer in-person or virtual consults?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('urgency', 'immediate');
                storeAnswer('style', 'inPerson');
                showThinkingOverlay();
              }}
            >
              ASAP &amp; In-Person
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('urgency', 'immediate');
                storeAnswer('style', 'virtual');
                showThinkingOverlay();
              }}
            >
              ASAP &amp; Virtual
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('urgency', 'planned');
                storeAnswer('style', 'inPerson');
                showThinkingOverlay();
              }}
            >
              Future Date &amp; In-Person
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('urgency', 'planned');
                storeAnswer('style', 'virtual');
                showThinkingOverlay();
              }}
            >
              Future Date &amp; Virtual
            </button>
          </div>
        </div>

        {/* THINKING OVERLAY (page9->10) */}
        {showThinking && (
          <div className="thinking-overlay" style={{ display: 'flex' }}>
            <h3>Finalising Your Personalised GP Plan...</h3>
            <p>Just a few seconds while we review your answers.</p>
            <div className="spinner"></div>
          </div>
        )}

        {/* PAGE 10: Full-Prose Summary + Condition-Based Suggestions */}
        <div className={pageIsActive(10)} id="page10">
          <h2 className="fadeInRightTitle">Your Personalised Private GP Overview</h2>
          <p
            id="summaryProse"
            className="fadeInRightContent"
            style={{ marginBottom: '16px' }}
            dangerouslySetInnerHTML={{ __html: summaryProse }}
          ></p>

          {/* Condition-based GP plan recommendations */}
          <div className="info-box fadeInRightContent" style={{ marginBottom: '16px' }}>
            <h3>Recommended Next Steps:</h3>
            <div dangerouslySetInnerHTML={{ __html: gpPlanSuggestions }} />
            <p style={{ marginTop: '0.5rem' }}>
              Our doctors will confirm the exact approach during your consult, ensuring you only get 
              the services and tests that truly benefit you.
            </p>
          </div>

          <div className="cta-block fadeInRightContent" style={{ marginTop: '0' }}>
            <p style={{ fontSize: '12px', color: '#777', margin: 0 }}>
              Please remember: We do not handle life-threatening emergencies. For urgent matters, call 999 or visit A&amp;E.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(11)}>
            Next
          </button>
        </div>

        {/* PAGE 11: Contact & Final Page */}
        <div
          id="page11"
          className={`form-page ${pageIsActive(11)} ${!submitted ? 'not-submitted' : ''}`}
        >
          {/* Only show heading if not submitted */}
          {!submitted && (
            <h2 className="fadeInRightTitle">Almost Done: Let’s Confirm Your Details</h2>
          )}

          {error && (
            <p
              style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}
              className="fadeInRightContent"
            >
              {error}
            </p>
          )}

          {/* If submitted, show success text only */}
          {submitted && (
            <div className="success-message">
              {/* Title fades in from right slower */}
              <p className="success-title">
                Your Private GP Submission was successful!
              </p>
              {/* Then final plan text also slides from right with delay */}
              <p
                id="finalPlanIntro"
                dangerouslySetInnerHTML={{ __html: finalText }}
              ></p>
            </div>
          )}

          {/* If not submitted, show the form */}
          {!submitted && (
            <>
              <p
                id="finalPlanIntro"
                className="fadeInRightContent"
                style={{ marginBottom: '16px' }}
              >
                Please provide your details below, and we’ll confirm your private GP appointment.
              </p>
              <form onSubmit={finishJourney} className="fadeInRightContent">
                <label htmlFor="fullName">
                  Full Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  required
                  value={answers.fullName}
                  onChange={(e) => storeAnswer('fullName', e.target.value)}
                />

                <label htmlFor="email">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={answers.email}
                  onChange={(e) => storeAnswer('email', e.target.value)}
                />

                <label htmlFor="phone">
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="+44 ..."
                  required
                  value={answers.phone}
                  onChange={(e) => storeAnswer('phone', e.target.value)}
                />

                <h3 style={{ marginTop: '15px' }}>Preferred Contact Method</h3>
                <div className="choice-container" style={{ marginBottom: 0 }}>
                  <button
                    className={getContactButtonClass('Email')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Email')}
                  >
                    Email
                  </button>
                  <button
                    className={getContactButtonClass('Call')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Call')}
                  >
                    Call
                  </button>
                  <button
                    className={getContactButtonClass('WhatsApp')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'WhatsApp')}
                  >
                    WhatsApp
                  </button>
                  <button
                    className={getContactButtonClass('NoCall')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'NoCall')}
                  >
                    No call, just start now
                  </button>
                </div>

                <button className="btn" style={{ marginTop: '20px' }}>
                  Submit &amp; Get My Appointment Plan
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GpJourney;
