import React from "react";
import byrhouds from "../images/byrhouds.MP4";
import './VideoPlayer.css';

function VideoPlayer() {
  return (
    <div className="video-player-container">
      <div className="video-title">
        <h2>We’ve helped over 1000 people take control of their health with our comprehensive blood tests.</h2>
        <p>Watch @byrhouds story and see how you can gain valuable insights for a healthier life.</p>
      </div>
      <div className="video123">
        <video className="video-player" controls>
          <source src={byrhouds} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default VideoPlayer;
