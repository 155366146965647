// PopupDiscountModal.js
import React, { useState, useEffect } from "react";
import "./PopupDiscountModal.css"; // Import the CSS file
import { useNavigate } from "react-router-dom";

// How many days until showing popup again
const DAYS_BEFORE_POPUP = 5;

const PopupDiscountModal = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Check localStorage for last dismissal
    const dismissedTime = localStorage.getItem("popupDismissedTime");
    if (dismissedTime) {
      const now = Date.now();
      const diffMs = now - parseInt(dismissedTime, 10);
      const diffDays = diffMs / (1000 * 60 * 60 * 24);

      if (diffDays < DAYS_BEFORE_POPUP) {
        // Still within the cool-down period => do nothing
        return;
      }
    }

    // Otherwise, show after 5 seconds
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    // Cleanup if unmounts
    return () => clearTimeout(timer);
  }, []);

  // Closes popup, sets localStorage
  const handleClosePopup = () => {
    localStorage.setItem("popupDismissedTime", Date.now().toString());
    setShowPopup(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // 1) Attempt Cloud Function call
    try {
      const dripResponse = await fetch("https://us-central1-tlwc-a3a85.cloudfunctions.net/subscribeDiscountFlow", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      if (!dripResponse.ok) {
        console.error("Drip subscription failed with status:", dripResponse.status);
      }
    } catch (err) {
      console.error("Cloud Function error:", err);
    }
  
    // // 2) Attempt Slack call (always)
    try {
      const slackResponse = await fetch("/api/slack", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ selectedOption: "Discount Signup", email }),
      });      
      if (!slackResponse.ok) {
        console.error("Slack notification failed with status:", slackResponse.status);
      }
    } catch (err) {
      console.error("Slack error:", err);
    }
  
    // Finally, close popup
    handleClosePopup();
  };
  

  if (!showPopup) return null;

  return (
    <div className="popup-overlay" onClick={handleClosePopup}>
      {/* Prevent closing when clicking inside popup */}
      <div className="popup-container" onClick={(e) => e.stopPropagation()}>
        <p className="close-button" onClick={handleClosePopup}>
          &times;
        </p>
        <h2 className="popup-title">Discount Code</h2>
        <p className="popup-description">
          Enter your email to receive our discount code for 10% off all treatments.
        </p>
        <form onSubmit={handleSubmit} className="popup-form">
          <input
            type="email"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="popup-input"
          />
          <button type="submit" className="popup-button">
            Send Code
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupDiscountModal;
