// src/pages/TherapyPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import './ServiceSummary.css';

const TherapyPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/therapy');
  };

  return (
    <div className="service-summary-page">
      <div className="animated-bg-wave" />

      <SEO
        title="Therapy Services in London | Mental Health & Wellbeing | The Wellness London"
        description="Improve mental health and emotional wellbeing with our therapy services in London. Our experienced therapists use a range of evidence-based techniques to help you thrive."
        keywords="therapy london, mental health, counseling, cbt, psychotherapy, emotional wellbeing"
      />

      <div className="service-summary-container">
        <h1>Therapy Services</h1>
        <p>
          Mental health is as important as physical health. Our <strong>Therapy Services </strong>
          in London encompass a diverse range of approaches, from Cognitive Behavioral Therapy
          (CBT) to more holistic modalities. Our experienced therapists will help you navigate
          emotional challenges, manage stress, and build resilience.
        </p>
        <p>
          <strong>How We Help:</strong><br />
          - <strong>Individualized Treatment</strong>: One-on-one sessions tailored to your concerns and personal goals.<br />
          - <strong>Evidence-Based Techniques</strong>: We incorporate CBT, mindfulness strategies, and more to foster lasting change.<br />
          - <strong>Safe &amp; Confidential Environment</strong>: Speak freely about your thoughts and emotions with full confidentiality.<br />
          - <strong>Flexible Scheduling</strong>: In-person or telehealth sessions to accommodate your lifestyle.
        </p>
        <p>
          Whether you’re dealing with anxiety, depression, relationship issues, or seeking personal
          growth, our mission is to equip you with tools to thrive mentally and emotionally.
        </p>

        <div className="button-wrapper" style={{ textAlign: 'center' }}>
  <button className="questionnaire-button" onClick={handleClick}>
  Your Personalised Treatment
  </button>
</div>

      </div>
    </div>
  );
};

export default TherapyPage;
