// src/components/LearnMore/LearnMoreTherapy.js
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import SEO from '../SEO';
import './LearnMorePage.css';
import therapyImage from '../../images/therapy.png';

const LearnMoreTherapy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <article className="learn-more-container">
      <SEO
        title="Personalised Therapy for Anxiety - The Wellness London"
        description="Explore our personalized therapy programs for anxiety. Discover tailored approaches and professional guidance at The Wellness London."
        keywords="personalised therapy, anxiety, mental health, wellness"
      />

      <motion.header
        className="learn-more-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h1>Personalised Therapy</h1>
        <p className="subheading">Your Path to Emotional Well-Being</p>
      </motion.header>

      <motion.section
        className="learn-more-hero"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2, duration: 0.6, ease: 'easeOut' }}
      >
        <img src={therapyImage} alt="Person sitting in therapy session illustration" className="learn-more-image" />
      </motion.section>

      <motion.section
        className="learn-more-content"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h2>What is Personalised Therapy?</h2>
        <p>
          Personalised Therapy focuses on meeting you where you are. Our approach for addressing anxiety blends 
          evidence-based techniques with a deep understanding of your unique experiences. We create a safe, supportive 
          space to explore your thoughts, feelings, and goals, guiding you toward lasting emotional well-being.
        </p>

        <h2>How It Works</h2>
        <p>
          Through an initial assessment, we learn about your challenges and aspirations. Our qualified therapists then 
          develop a customized plan that may include cognitive-behavioral therapy, mindfulness practices, or other therapeutic modalities.
          We work closely with you, adjusting the approach as you progress to ensure the best outcome.
        </p>

        <h2>Important Safety Information</h2>
        <p>
          Therapy sessions are confidential and designed to respect your emotional boundaries. While some emotional discomfort 
          can arise as you work through challenging issues, your therapist is trained to help navigate these feelings safely.
        </p>

        <h2>Next Steps</h2>
        <p>
          Start your journey toward reduced anxiety and increased well-being. Book a consultation to explore if our personalised 
          therapy is right for you, and take a meaningful step toward a more balanced life.
        </p>
      </motion.section>

      <motion.footer
        className="learn-more-footer"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <button className="primary-cta" onClick={() => (window.location.href = '#/therapy')}>
          Get Started
        </button>
        <button className="secondary-cta" onClick={() => (window.location.href = '#/enquiry')}>
          Contact Us
        </button>
      </motion.footer>
    </article>
  );
};

export default LearnMoreTherapy;
