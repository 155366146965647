// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBpj9KCZomtTJr1bYTNTfWcXsXM3_b2ugA",
  authDomain: "tlwc-a3a85.firebaseapp.com",
  projectId: "tlwc-a3a85",
  storageBucket: "tlwc-a3a85.firebasestorage.app",
  messagingSenderId: "659402069575",
  appId: "1:659402069575:web:fdd0baa285aaa118310362",
  measurementId: "G-8Q01HGS1QH"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };