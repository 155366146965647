import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "../BLOOD/BloodTesting.css";
import middleLifestyle from "../../images/gp1.png";
import middleHormone from "../../images/gp2.png";
import middleAdvanced from "../../images/gp3.png";
import arrow from "../../images/button.png";

function GPAppointment() {
  const tests = [
    {
      id: 0,
      title: "GP Appointment (Classic)",
      price: "180.00/session",
      middleImage: middleLifestyle,
      middleClass: "image-lifestyle",
      stripeLink: "https://buy.stripe.com/fZe9Bj4tJ6eKdr22a7",
      text: "*Routine or non-urgent GP consultations with a focus on preventive health and ongoing care.",
    },
    {
      id: 1,
      title: "GP Appointment (Same Day)",
      price: "220.00/session",
      middleImage: middleHormone,
      middleClass: "image-lifestyle",
      stripeLink: "https://buy.stripe.com/14k4gZf8n6eK5YAbKI",
      text: "*Immediate diagnostic services, swift prescriptions, and expedited referrals. Perfect for individuals or professionals with time-sensitive concerns.",
    },
    {
      id: 2,
      title: "Medical Report",
      price: "from 150.00 a report",
      middleImage: middleAdvanced,
      middleClass: "image-advanced1",
      stripeLink: "https://buy.stripe.com/6oEcNve4jgToaeQ3ed",
      text: "*Formal medical certificates and detailed reports (DVLA, work fitness, travel, etc.).",
    },
  ];

  const cardsRef = useRef([]);

  // GSAP animation
  useEffect(() => {
    gsap.fromTo(
      cardsRef.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.2,
        stagger: 0.2,
        ease: "power3.out",
      }
    );
  }, []);

  // Helper: open Stripe link in new tab
  const openStripeLink = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="blood-testing-container">
      <div className="placeholder-text1">
        <h1>Better Health</h1>
        <p>personalised to you</p>
      </div>

      <div className="cards-container">
        {tests.map((test, index) => (
          <div
            className="card-wrapper"
            key={test.id}
            ref={(el) => (cardsRef.current[index] = el)}
          >
            {/* The card itself */}
            <div
              className="test-card1"
              onClick={() => openStripeLink(test.stripeLink)}
              style={{ cursor: "pointer" }}
            >
              <div className="card-content">
                {/* Title */}
                <h2>{test.title}</h2>
                {/* The main image */}
                <img
                  src={test.middleImage}
                  alt={`${test.title} - Additional Preview`}
                  className={`middle-image ${test.middleClass}`}
                />
              </div>
              {/* Price row (no anchor, entire card is clickable) */}
              <div className="price-row">
                <span className="purchase-link">{test.price}</span>
                <img className="arrow-icon-right" src={arrow} alt="Arrow" />
              </div>
            </div>

            {/* Text below (outside) the card */}
            <p className="outside-text">{test.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GPAppointment;
