// src/components/SEO.js
import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, keywords, structuredData }) => {
  return (
    <Helmet>
      <title>{title}</title>

      {/* Basic SEO meta tags */}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      {/* Potential structured data (JSON-LD) */}
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
