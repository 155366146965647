// src/pages/WeightLossPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import './ServiceSummary.css';

const WeightLossPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/weight-loss');
  };

  return (
    <div className="service-summary-page">
      <div className="animated-bg-wave" />

      <SEO
        title="Weight Loss Programs in London | Personalized Plans | The Wellness London"
        description="Discover personalized weight loss programs, including medications like Mounjaro, Ozempic, and more. Achieve sustainable, healthy results with expert guidance."
        keywords="weight loss london, mounjaro, ozempic, personalized diet, healthy weight management, weight loss medications"
      />

      <div className="service-summary-container">
        <h1>Weight Loss Services</h1>
        <p>
          Attaining and maintaining a healthy weight is crucial for overall wellness. Our
          <strong> Weight Loss</strong> services combine the latest medical innovations,
          including medications like Mounjaro or Ozempic, with evidence-based lifestyle
          counseling for real, sustainable results. 
        </p>
        <p>
          <strong>What We Offer:</strong><br />
          - <strong>Customized Plans</strong>: We tailor our approach to your lifestyle, medical history, and goals—no generic “one-size-fits-all” diets.<br />
          - <strong>Medication Support</strong>: For eligible patients, we prescribe weight loss medications that can help regulate appetite and metabolism.<br />
          - <strong>Nutritional Guidance</strong>: Receive guidance on balanced meal planning, mindful eating, and portion control.<br />
          - <strong>Ongoing Monitoring</strong>: Regular check-ins to track progress, adjust medications, and ensure safe, effective weight management.
        </p>
        <p>
          From mild weight concerns to more significant health challenges, we support you through each step of
          the journey. Our multi-disciplinary team is committed to helping you achieve and maintain a healthy
          body weight without sacrificing your quality of life.
        </p>

        <div className="button-wrapper" style={{ textAlign: 'center' }}>
  <button className="questionnaire-button" onClick={handleClick}>
  Your Personalised Treatment
  </button>
</div>

      </div>
    </div>
  );
};

export default WeightLossPage;
