import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "../BLOOD/BloodTesting.css";
import middleLifestyle from "../../images/gal.png";
import middleHormone from "../../images/guy.png";
import arrow from "../../images/button.png";

function BloodTesting() {
  const tests = [
    {
      id: 0,
      title: "Hair Growth Foam (F)",
      price: "80.00/month",
      middleImage: middleLifestyle,
      middleClass: "image-lifestyle",
      stripeLink: "https://buy.stripe.com/cN2g1X6dHb3ngmcbIQ",
    },
    {
      id: 1,
      title: "Hair Growth Foam (M)",
      price: "80.00/month",
      middleImage: middleHormone,
      middleClass: "image-lifestyle1",
      stripeLink: "https://buy.stripe.com/cN2g1X6dHb3ngmcbIQ",
    },
  ];

  const cardsRef = useRef([]);

  // GSAP animation
  useEffect(() => {
    gsap.fromTo(
      cardsRef.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.2,
        stagger: 0.2,
        ease: "power3.out",
      }
    );
  }, []);

  // Helper: open Stripe link in new tab
  const openStripeLink = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="blood-testing-container">
      <div className="placeholder-text1">
        <h1>Better Health</h1>
        <p>personalised to you</p>
      </div>

      <div className="cards-container">
        {tests.map((test, index) => (
          <div
            className="test-card2"
            key={test.id}
            ref={(el) => (cardsRef.current[index] = el)}
            onClick={() => openStripeLink(test.stripeLink)}
            style={{ cursor: "pointer" }}  // Show pointer cursor
          >
            <div className="card-content">
              <h2>{test.title}</h2>
              <img
                src={test.middleImage}
                alt={`${test.title} - Additional Preview`}
                className={`middle-image ${test.middleClass}`}
              />
            </div>

            <div className="price-row">
              <span className="purchase-link">{test.price}</span>
              <img className="arrow-icon-right" src={arrow} alt="Arrow" />
            </div>
          </div>
        ))}
      </div>
      <p className="footer-hair">*Includes daily growth foam and daily vitamins to nourish the hair.</p>
    </div>
  );
}

export default BloodTesting;
