// src/components/Services.js

import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import './Services.css';
import SEO from './SEO'; // Make sure the SEO component is correctly imported

import gpAppointmentImage from '../images/steth.png';
import blood from '../images/blood.png';
import weightLossImage from '../images/ozempic.png';
import bloodTestsImage from '../images/blood2.png';
import hair from '../images/hair.png';

const Services = () => {
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const placeholders = [
    { text: 'PRP', color: '#0048a6' },
    { text: 'Private GP Appointment', color: '#946b87' },
    { text: 'Weight Loss', color: '#6eb4cf' },
    { text: 'Blood Tests', color: '#5b8275' },
  ];

  const features = [
    {
      title: 'Blood & Hormone Tests',
      image: bloodTestsImage,
      link: '/blood-tests',
      description: 'Access comprehensive blood tests in London for accurate results.',
    },
    {
      title: 'PRP',
      image: blood,
      link: '/prp-journey',
      description: 'Experience regenerative treatments with PRP therapy in London.',
    },
    {
      title: 'Hair Loss',
      image: hair,
      link: '/blood-tests-hair-journey',
      description: 'Kickstart your hair regrowth journey in London.',
    },
    {
      title: 'Weight Loss',
      image: weightLossImage,
      link: '/weight-loss',
      description: 'Explore personalized weight loss solutions tailored to your needs.',
    },
    {
      title: 'Private GP',
      image: gpAppointmentImage,
      link: '/gp-journey',
      description: 'Get same-day private GP appointments in London for prompt care.',
    }
  ];

  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  // Placeholder text animation
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
      }, 400);
      setTimeout(() => {
        setIsAnimating(false);
      }, 800);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // Intersection Observer for animations
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          entries[0].target.classList.add('in-view');
          observer.unobserve(entries[0].target);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleFeatureClick = (link) => {
    navigate(link);
  };

  return (
    <>
      <SEO
        title="Wellness Services | Private GP, Blood Tests & PRP in London"
        description="Discover our wellness services including same-day private GP appointments, comprehensive blood tests in London, platelet-rich plasma (PRP) therapies, weight loss programs, and more."
        keywords="private gp london, blood tests in london, platelet-rich plasma therapy, prp treatments, exosomes, weight loss services, therapy sessions, same-day gp appointments"
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalClinic",
            "name": "Wellness Services",
            "description":
              "Offering private GP appointments, blood tests, platelet-rich plasma (PRP) treatments, weight loss programs, therapy sessions, and exosomes treatments in London.",
            "url": "https://www.example.com/services",
            "image": "https://www.example.com/images/services.png",
            "medicalSpecialty": ["GeneralPractice", "PathologyTest", "RegenerativeMedicine"],
            "availableService": [
              {
                "@type": "MedicalTest",
                "name": "Blood Tests",
                "availableOnDemand": true
              },
              {
                "@type": "MedicalTherapy",
                "name": "Platelet-Rich Plasma (PRP) therapy",
                "availableOnDemand": true
              },
              {
                "@type": "MedicalTest",
                "name": "Private GP Appointment",
                "availableOnDemand": true
              }
            ],
            "areaServed": "London, UK"
          })}
        </script>
      </SEO>

      <div className="how-it-works-container" ref={containerRef}>
        <div className="text-container">
          <h1
            className={`placeholder-text ${isAnimating ? 'animate' : ''}`}
            style={{ color: placeholders[currentPlaceholderIndex].color }}
          >
            {placeholders[currentPlaceholderIndex].text}
          </h1>
          <h1 className="personalised-text">personalised to you</h1>
        </div>

        <div className="services-scroll-container">
          <div className="features-grid">
            {features.map((feature, index) => (
              <div
                className="feature-card"
                key={index}
                onClick={() => handleFeatureClick(feature.link)}
              >
                <div className="feature-content">
                  <div className="feature-text">
                    <h3>{feature.title}</h3>
                    {/* <p>{feature.description}</p> // if you want to show the short desc */}
                  </div>

                  <div className="feature-image-container">
                    <img
                      src={feature.image}
                      alt={`${feature.title} in London`}
                      className="feature-image"
                    />
                    {/* Arrow removed */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
