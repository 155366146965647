import React, { useState, useEffect } from "react";
import "./ResultsSection.css";
import { useNavigate } from 'react-router-dom';

const ResultsSection = () => {
  // Track progress for each circle in state, to animate from 0 to final value.
  const [progress90, setProgress90] = useState(0); // for "90%"
  const [progress80, setProgress80] = useState(0); // for "4/5"

  const navigate = useNavigate();

  const handleClick = () => {
    // Adjust path to match your desired route for blood testing or preventative journey
    navigate("/blood-tests-hair-journey");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    let animationFrame;
    const duration = 2000; // 2 seconds
    const startTime = performance.now();

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime;
      const fraction = Math.min(elapsed / duration, 1);

      // final values: 90% (out of 100), and 80% (out of 100)
      setProgress90(Math.floor(90 * fraction));
      setProgress80(Math.floor(80 * fraction));

      if (fraction < 1) {
        animationFrame = requestAnimationFrame(animate);
      }
    };

    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  return (
    <div className="results-section">
      {/* LEFT COLUMN: text + button */}
      <div className="results-left">
        <h1 className="results-title">
          Real, Actionable <span>Insights</span>
        </h1>
        <button className="get-started-btn" onClick={handleClick}>
          Get started
        </button>
      </div>

      {/* RIGHT COLUMN: arcs + text */}
      <div className="results-right">
        
        {/* Arc 1 */}
        <div className="arc-and-text">
          <div className="radial-chart">
            <svg viewBox="0 0 36 36" className="circular-chart red">
              {/* Background circle */}
              <path
                className="circle-bg"
                d="
                  M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831
                "
              />
              {/* Animated circle */}
              <path
                className="circle"
                strokeDasharray={`${progress90}, 100`}
                d="
                  M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831
                "
              />
              <text x="18" y="20.35" className="percentage">
                {progress90}%
              </text>
            </svg>
          </div>
          <p className="arc-description">
            Identified key hormone levels and potential imbalances through our advanced panels.
          </p>
        </div>

        {/* Arc 2 */}
        <div className="arc-and-text">
          <div className="radial-chart">
            <svg viewBox="0 0 36 36" className="circular-chart red">
              {/* Background circle */}
              <path
                className="circle-bg"
                d="
                  M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831
                "
              />
              {/* Animated circle */}
              <path
                className="circle"
                strokeDasharray={`${progress80}, 100`}
                d="
                  M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831
                "
              />
              <text x="18" y="20.35" className="percentage">
                {Math.round(progress80 / 20)}/5
              </text>
            </svg>
          </div>
          <p className="arc-description">
            Gained clear insights into cardiovascular and organ health risks.
          </p>
        </div>

        {/* Footnotes */}
        <div className="footnotes">
          <p>
            Data based on user-reported outcomes.<br />
            Actual results may vary. Our tests are designed to reveal potential risk factors early, enabling proactive care.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResultsSection;
