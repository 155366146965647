import React, { useState, useEffect } from 'react';
import './BloodTestsHairJourney.css'; // Or your updated CSS path

// FIRESTORE
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';

const PrpMultiAreaJourney = () => {
  console.log("PrpMultiAreaJourney component mounted!"); // Debug line

  // -------------------------------------------------------------------------
  // 1) Page & Answers
  //    - We previously had totalPages = 11. We add a new Page 1 for the "two choices".
  //    - So now totalPages = 12.
  //    - We start on Page 1, the brand-new choice screen.
  // -------------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 12;

  // Existing answers object from your code
  const [answers, setAnswers] = useState({
    prpArea: null,          // hair, face, or joints
    medicalHistory: null,
    problemDuration: null,
    familyHistory: null,
    stressLevel: null,
    dailyRoutine: null,
    budget: null,
    clinicVisits: null,
    contactMethod: null,
    fullName: '',
    email: '',
    phone: ''
  });

  // 2) Progress bar & "thinking" overlay
  const [progress, setProgress] = useState(0);
  const [showThinking, setShowThinking] = useState(false);

  // 3) Summaries for page11 & page12
  const [summaryProse, setSummaryProse] = useState('');
  const [finalPlanIntro, setFinalPlanIntro] = useState('');

  // Submission states
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  // -------------------------------------------------------------------------
  // UTILS
  // -------------------------------------------------------------------------
  const goToPage = (num) => {
    setCurrentPage(num);
    updateProgressBar(num);
  };

  const storeAnswer = (key, value) => {
    setAnswers((prev) => ({ ...prev, [key]: value }));
    console.log('Storing Answer:', key, value);
  };

  // Update progress bar based on the currentPage
  const updateProgressBar = (targetPage) => {
    // We have 12 total pages now
    const fraction = (targetPage - 1) / (totalPages - 1);
    const percentage = Math.min(100, fraction * 100);
    setProgress(percentage);
  };

  // -------------------------------------------------------------------------
  // PAGE-FLOW
  // -------------------------------------------------------------------------
  // Page9 (old page8) -> Page10 (old page9) after choosing budget
  // BUT we fix it so it navigates to page 11
  const chooseBudget = (value) => {
    storeAnswer('budget', value);
    goToPage(11);  // <<-- changed from goToPage(10) to goToPage(11)
  };

  // Page10 (old page9) -> Page11 with short "thinking" overlay
  // Actually now it's the old page10 => new page11, then summary => new page12
  const goToSummary = () => {
    setShowThinking(true);
    setTimeout(() => {
      setShowThinking(false);
      buildProseSummary();
      goToPage(12);
    }, 2000);
  };

  // -------------------------------------------------------------------------
  // PAGE6 (old page5) => Mid-journey summary
  //    (Actually it's now page8 in the new numbering)
  // -------------------------------------------------------------------------
  const fillMidJourneyBox = () => {
    const areaMap = {
      hair: 'Hair PRP (scalp-focused)',
      face: 'Face PRP (skin rejuvenation)',
      joints: 'Joint PRP (mobility/pain relief)'
    };

    const medMap = {
      none: 'No major medical concerns reported',
      thyroid: 'Thyroid/Hormonal issues',
      alopecia: 'Alopecia or scalp concerns',
      postpartum: 'Postpartum condition(s)',
      osteoarthritis: 'Osteoarthritis issues',
      rheumatoid: 'Rheumatoid arthritis',
      cartilage: 'Meniscus/Cartilage injury',
      other: 'Another condition requiring discussion'
    };

    const durMap = {
      recent: 'Noticed the issue in the past year',
      longterm: 'Issue ongoing for over a year'
    };

    const famMap = {
      yes: 'Family history relevant (esp. hair thinning)',
      no: 'No or unsure family history'
    };

    const stressMap = {
      often: 'Frequent stress or lifestyle disruption',
      sometimes: 'Occasional or moderate stress',
      rarely: 'Rarely stressed or impacted'
    };

    return (
      <>
        <strong>PRP Focus:</strong> {areaMap[answers.prpArea] || 'Unspecified'}<br />
        <strong>Medical History:</strong> {medMap[answers.medicalHistory] || 'Unknown'}<br />
        <strong>Issue Duration:</strong> {durMap[answers.problemDuration] || 'Not specified'}<br />
        <strong>Family History (hair):</strong> {famMap[answers.familyHistory] || 'None/unsure'}<br />
        <strong>Stress &amp; Lifestyle:</strong> {stressMap[answers.stressLevel] || 'Not clear'}
      </>
    );
  };

  // -------------------------------------------------------------------------
  // PAGE11 (old page10) => Build summary text
  // -------------------------------------------------------------------------
  const buildProseSummary = () => {
    let areaStr = 'general PRP interest';
    switch (answers.prpArea) {
      case 'hair':
        areaStr = 'Hair PRP (scalp revitalisation)';
        break;
      case 'face':
        areaStr = 'Facial PRP (skin rejuvenation)';
        break;
      case 'joints':
        areaStr = 'Joint PRP (pain & mobility improvement)';
        break;
      default:
        break;
    }

    let medStr = 'no major health conditions';
    switch (answers.medicalHistory) {
      case 'thyroid':
        medStr = 'a thyroid/hormonal factor to account for';
        break;
      case 'alopecia':
        medStr = 'alopecia or scalp concerns needing special attention';
        break;
      case 'postpartum':
        medStr = 'postpartum factors that may influence treatment safety';
        break;
      case 'osteoarthritis':
        medStr = 'osteoarthritis that may require multiple PRP sessions';
        break;
      case 'rheumatoid':
        medStr = 'rheumatoid arthritis that might affect healing time';
        break;
      case 'cartilage':
        medStr = 'cartilage/meniscus injury needing special rehab';
        break;
      case 'other':
        medStr = 'some condition we’ll clarify further with you';
        break;
      default:
        break;
    }

    const durText =
      answers.problemDuration === 'longterm'
        ? 'an ongoing issue for over a year'
        : 'a challenge noticed within the last year';

    const famText =
      answers.familyHistory === 'yes'
        ? 'a strong family link (notably for hair loss) in your immediate family'
        : 'no or uncertain family link';

    const stressStr =
      answers.stressLevel === 'often'
        ? 'often dealing with stress or lifestyle disruptions'
        : answers.stressLevel === 'sometimes'
        ? 'sometimes impacted by stress'
        : 'rarely stressed or impacted by lifestyle';

    const routineMap = {
      simple: 'a simple daily routine (&lt;5 mins)',
      moderate: 'a moderate approach (5–10 mins)',
      complex: 'an advanced or in-depth routine (10+ mins)'
    };
    const routineDesc = routineMap[answers.dailyRoutine] || 'your usual daily approach';

    const budgetDesc = answers.budget || 'unspecified budget range';
    const clinicTxt =
      answers.clinicVisits === 'fine'
        ? 'you’re willing to attend PRP sessions every 4–6 weeks'
        : 'you prefer minimal in-clinic visits';

    const finalText = `
      You’re interested in <strong>${areaStr}</strong> and have <strong>${medStr}</strong>. 
      This issue has been <strong>${durText}</strong>. We note <strong>${famText}</strong>, 
      plus <strong>${stressStr}</strong> factors. Your daily routine is <strong>${routineDesc}</strong>, 
      and <strong>${clinicTxt}</strong>. Your budget is around <strong>${budgetDesc}</strong>.
      <br><br>
      <strong>Great news:</strong> you’re likely an excellent candidate for PRP at <strong>The Wellness</strong>, 
      whether it’s for scalp rejuvenation, facial skin renewal, or easing joint pain. Let’s confirm final details below.
    `;
    setSummaryProse(finalText);
  };

  // -------------------------------------------------------------------------
  // PAGE12 (old page11): final submission
  // -------------------------------------------------------------------------
  // 1) Normal submission => Firestore + Slack + success text
  // 2) No call => skip Firestore, post Slack, navigate to /prp-packages
  // -------------------------------------------------------------------------
  const finishJourney = async (e) => {
    e.preventDefault();
    const { fullName, email, phone } = answers;

    if (!fullName || !email || !phone) {
      setError('Please provide your name, email, and phone so we can finalise your plan.');
      return;
    }
    setError('');

    try {
      // 1) Firestore
      await addDoc(collection(db, 'prpMultiAreaJourneys'), {
        ...answers,
        timestamp: new Date(),
      });

      // 2) Also post to Slack
      try {
        await fetch('/api/slack', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...answers,
            message: 'Final PRP multi-area submission (with name/email/phone).'
          }),
        });
      } catch (slackErr) {
        console.error('Error posting final PRP multi-area data to Slack:', slackErr);
      }

      // 3) Mark as submitted => show success
      setSubmitted(true);
      setFinalPlanIntro(`
        Thank you, <strong>${fullName}</strong>. We’ve reserved you a place in our 
        PRP programme—whether for hair, face, or joint relief. Our doctors will confirm 
        all details—budget, in-clinic schedule, any complementary topicals or medications—
        so you can see real improvements sooner rather than later.
      `);

    } catch (err) {
      console.error('Error submitting final PRP multi-area data:', err);
      setError('Sorry, there was a problem submitting your information. Please try again.');
    }
  };

  // This handles the "No call, just start now" -> skip Firestore, post Slack, navigate
  const handleNoCallButton = async () => {
    // store the user’s choice
    storeAnswer('contactMethod', 'NoCall');

    // Attempt Slack post
    try {
      await fetch('/api/slack', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...answers,
          contactMethod: 'NoCall',
          message: 'User selected "No call, just start now" on final PRP page, skipping Firestore.'
        }),
      });
    } catch (slackErr) {
      console.error('Error posting "NoCall" data to Slack:', slackErr);
    }

    // Then navigate
    window.location.href = '#/prp-packages';
  };

  // -------------------------------------------------------------------------
  // HOOKS
  // -------------------------------------------------------------------------
  useEffect(() => {
    updateProgressBar(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const pageIsActive = (num) => (currentPage === num ? 'form-page active' : 'form-page');

  const getContactButtonClass = (method) =>
    answers.contactMethod === method ? 'choice-btn active' : 'choice-btn';

  // -------------------------------------------------------------------------
  // RENDER
  // -------------------------------------------------------------------------
  return (
    <div className="outer-container">
      {/* Progress Bar */}
      <div className="progress-bar-fixed">
        <div className="progress-wrap">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>

      <div className="container fade-in">
        {/* NEW PAGE 1: two choices */}
        <div className={pageIsActive(1)}>
          <h2 className="fadeInRightTitle">Get Started With Your PRP Journey</h2>
          <p className="fadeInRightContent">
            Choose one of the following:
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => goToPage(2)}
            >
              Personalise Your Treatment
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                // Navigate directly to /prp-packages
                window.location.href = '#/prp-packages';
              }}
            >
              Book PRP Now
            </button>
          </div>
        </div>

        {/* PAGE 2: Intro (old page 1) */}
        <div className={pageIsActive(2)}>
          <h2 className="fadeInRightTitle">Welcome to Your Personalised PRP Journey</h2>
          <p className="fadeInRightContent">
            At <strong>The Wellness</strong>, we offer PRP therapy for <em>hair rejuvenation</em>, 
            <em> facial skin renewal</em>, and <em>joint relief</em>. By harnessing your own 
            platelets’ growth factors, PRP can help restore hair thickness, revitalize your complexion, 
            or relieve joint discomfort. We accept a limited number of new clients each month for 
            doctor-led, focused care.
          </p>
          <div className="info-box fadeInRightContent">
            <p>
              <strong>Note:</strong> PRP is typically recommended if you’re healthy enough for minor in-clinic sessions, 
              and not pregnant or dealing with certain blood disorders. Let’s see which approach suits you best.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(3)}>Start</button>
        </div>

        {/* PAGE 3: Choose PRP area (old page 2) */}
        <div className={pageIsActive(3)}>
          <h2 className="fadeInRightTitle">1. Which PRP Treatment Interests You Most?</h2>
          <p className="fadeInRightContent">
            Are you looking to address hair thinning, facial skin concerns (fine lines, acne scars), or joint issues?
          </p>
          <div className="choice-container fadeInRightContent">
            <button 
              className="choice-btn"
              onClick={() => { storeAnswer('prpArea', 'hair'); goToPage(4); }}
            >
              Hair PRP
            </button>
            <button 
              className="choice-btn"
              onClick={() => { storeAnswer('prpArea', 'face'); goToPage(4); }}
            >
              Face PRP
            </button>
            <button 
              className="choice-btn"
              onClick={() => { storeAnswer('prpArea', 'joints'); goToPage(4); }}
            >
              Joint PRP
            </button>
          </div>
        </div>

        {/* PAGE 4: Medical Condition (old page 3) */}
        <div className={pageIsActive(4)}>
          {answers.prpArea === 'hair' && (
            <>
              <h2 className="fadeInRightTitle">2. Relevant Medical Conditions? (Hair Flow)</h2>
              <p className="fadeInRightContent">
                Certain health conditions (like advanced autoimmune disorders, etc.)
                may limit hair PRP. Please select any that apply or choose “None/Unsure.”
              </p>
              <div className="choice-container fadeInRightContent">
                <button 
                  className="choice-btn" 
                  onClick={() => { storeAnswer('medicalHistory', 'none'); goToPage(5); }}
                >
                  None / Unsure
                </button>
                <button 
                  className="choice-btn" 
                  onClick={() => { storeAnswer('medicalHistory', 'thyroid'); goToPage(5); }}
                >
                  Thyroid / Hormonal
                </button>
                <button 
                  className="choice-btn" 
                  onClick={() => { storeAnswer('medicalHistory', 'alopecia'); goToPage(5); }}
                >
                  Alopecia / Scalp
                </button>
                <button 
                  className="choice-btn" 
                  onClick={() => { storeAnswer('medicalHistory', 'postpartum'); goToPage(5); }}
                >
                  Postpartum
                </button>
                <button 
                  className="choice-btn" 
                  onClick={() => { storeAnswer('medicalHistory', 'other'); goToPage(5); }}
                >
                  Other / Multiple
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'face' && (
            <>
              <h2 className="fadeInRightTitle">2. Relevant Medical Conditions? (Face Flow)</h2>
              <p className="fadeInRightContent">
                For facial PRP, let us know if you have any skin conditions, active infections, or other
                health factors that might influence healing. Otherwise, choose “None/Unsure.”
              </p>
              <div className="choice-container fadeInRightContent">
                <button 
                  className="choice-btn" 
                  onClick={() => { storeAnswer('medicalHistory', 'none'); goToPage(5); }}
                >
                  None / Unsure
                </button>
                <button 
                  className="choice-btn" 
                  onClick={() => { storeAnswer('medicalHistory', 'thyroid'); goToPage(5); }}
                >
                  Thyroid / Hormonal
                </button>
                <button 
                  className="choice-btn" 
                  onClick={() => { storeAnswer('medicalHistory', 'other'); goToPage(5); }}
                >
                  Skin Conditions / Other
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'joints' && (
            <>
              <h2 className="fadeInRightTitle">2. Relevant Medical Conditions? (Joint Flow)</h2>
              <p className="fadeInRightContent">
                For joint PRP, let us know if you have osteoarthritis, rheumatoid arthritis, meniscus/cartilage injuries, 
                or any other major joint/bone conditions that could affect healing. Otherwise, pick “None/Unsure.”
              </p>
              <div className="choice-container fadeInRightContent">
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('medicalHistory', 'none'); goToPage(5); }}
                >
                  None / Unsure
                </button>
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('medicalHistory', 'osteoarthritis'); goToPage(5); }}
                >
                  Osteoarthritis
                </button>
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('medicalHistory', 'rheumatoid'); goToPage(5); }}
                >
                  Rheumatoid Arthritis
                </button>
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('medicalHistory', 'cartilage'); goToPage(5); }}
                >
                  Meniscus / Cartilage Injury
                </button>
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('medicalHistory', 'other'); goToPage(5); }}
                >
                  Other / Multiple
                </button>
              </div>
            </>
          )}
        </div>

        {/* PAGE 5: Problem Duration (old page 4) */}
        <div className={pageIsActive(5)}>
          {answers.prpArea === 'hair' && (
            <>
              <h2 className="fadeInRightTitle">3. How Long Has Your Hair Issue Persisted?</h2>
              <p className="info-box fadeInRightContent">
                If it’s been over a year, we often recommend combining hair PRP with additional therapies
                to jumpstart regrowth.
              </p>
              <div className="choice-container fadeInRightContent">
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('problemDuration', 'recent'); goToPage(6); }}
                >
                  Within the last year
                </button>
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('problemDuration', 'longterm'); goToPage(6); }}
                >
                  Over a year
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'face' && (
            <>
              <h2 className="fadeInRightTitle">3. How Long Have You Had These Facial Concerns?</h2>
              <p className="info-box fadeInRightContent">
                If wrinkles, acne scars, or other skin issues have persisted over a year, 
                we may combine facial PRP with advanced topicals or microneedling for best results.
              </p>
              <div className="choice-container fadeInRightContent">
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('problemDuration', 'recent'); goToPage(6); }}
                >
                  Within the last year
                </button>
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('problemDuration', 'longterm'); goToPage(6); }}
                >
                  Over a year
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'joints' && (
            <>
              <h2 className="fadeInRightTitle">3. How Long Have You Had Joint Pain/Issues?</h2>
              <p className="info-box fadeInRightContent">
                Chronic joint pain over a year often needs multiple PRP sessions or additional rehab. 
                Let us know so we can tailor your plan.
              </p>
              <div className="choice-container fadeInRightContent">
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('problemDuration', 'recent'); goToPage(6); }}
                >
                  Within the last year
                </button>
                <button 
                  className="choice-btn"
                  onClick={() => { storeAnswer('problemDuration', 'longterm'); goToPage(6); }}
                >
                  Over a year
                </button>
              </div>
            </>
          )}
        </div>

        {/* PAGE 6: Family History (old page 5) */}
        <div className={pageIsActive(6)}>
          {answers.prpArea === 'hair' && (
            <>
              <h2 className="fadeInRightTitle">4. Family History (Hair)</h2>
              <p className="fadeInRightContent">
                Does thinning or pattern baldness run in your immediate family?
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('familyHistory', 'yes'); goToPage(7); }}
                >
                  Yes
                </button>
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('familyHistory', 'no'); goToPage(7); }}
                >
                  No / Unsure
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'face' && (
            <>
              <h2 className="fadeInRightTitle">4. Family History (Facial Skin Concerns?)</h2>
              <p className="fadeInRightContent">
                Are wrinkles, acne, or scarring prevalent in your family? This can shape 
                our approach with facial PRP.
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('familyHistory', 'yes'); goToPage(7); }}
                >
                  Yes
                </button>
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('familyHistory', 'no'); goToPage(7); }}
                >
                  No / Unsure
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'joints' && (
            <>
              <h2 className="fadeInRightTitle">4. Family History (Joint Issues?)</h2>
              <p className="fadeInRightContent">
                Do arthritis or similar joint conditions run in your family? If unsure, just pick “No / Unsure.”
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('familyHistory', 'yes'); goToPage(7); }}
                >
                  Yes
                </button>
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('familyHistory', 'no'); goToPage(7); }}
                >
                  No / Unsure
                </button>
              </div>
            </>
          )}
        </div>

        {/* PAGE 7: Stress & Lifestyle (old page 6) */}
        <div className={pageIsActive(7)}>
          <h2 className="fadeInRightTitle">5. Stress &amp; Lifestyle</h2>
          <p className="fadeInRightContent">
            How often do stress, lack of sleep, or nutritional gaps affect your health or recovery potential?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => { storeAnswer('stressLevel', 'often'); goToPage(8); }}
            >
              Often
            </button>
            <button
              className="choice-btn"
              onClick={() => { storeAnswer('stressLevel', 'sometimes'); goToPage(8); }}
            >
              Sometimes
            </button>
            <button
              className="choice-btn"
              onClick={() => { storeAnswer('stressLevel', 'rarely'); goToPage(8); }}
            >
              Rarely
            </button>
          </div>
        </div>

        {/* PAGE 8: Mid-Journey Review (old page 7) */}
        <div className={pageIsActive(8)}>
          <h2 className="fadeInRightTitle">Mid-Journey Review</h2>
          <p className="fadeInRightContent">Here’s what we’ve gathered so far:</p>
          <div className="info-box fadeInRightContent" style={{ fontSize: '15px' }}>
            {fillMidJourneyBox()}
          </div>
          <p className="fadeInRightContent">
            This suggests PRP may be highly beneficial for you. Next, let’s check your daily routine, 
            budget, and availability for in-clinic PRP sessions. Then we’ll confirm your final plan.
          </p>
          <button className="btn fadeInRightContent" onClick={() => goToPage(9)}>
            Continue
          </button>
        </div>

        {/* PAGE 9: Daily Routine (old page 8) */}
        <div className={pageIsActive(9)}>
          {answers.prpArea === 'hair' && (
            <>
              <h2 className="fadeInRightTitle">6. Your Daily Hair Routine</h2>
              <p className="fadeInRightContent">
                Do you spend minimal (&lt;5 mins), moderate (5–10 mins), or more advanced (10+ mins) on hair care daily?
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('dailyRoutine', 'simple'); goToPage(10); }}
                >
                  Minimal (&lt;5 mins)
                </button>
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('dailyRoutine', 'moderate'); goToPage(10); }}
                >
                  Moderate (5–10 mins)
                </button>
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('dailyRoutine', 'complex'); goToPage(10); }}
                >
                  Advanced (10+ mins)
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'face' && (
            <>
              <h2 className="fadeInRightTitle">6. Your Daily Facial Routine</h2>
              <p className="fadeInRightContent">
                How intensive is your daily face/skincare routine?
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('dailyRoutine', 'simple'); goToPage(10); }}
                >
                  Minimal (&lt;5 mins)
                </button>
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('dailyRoutine', 'moderate'); goToPage(10); }}
                >
                  Moderate (5–10 mins)
                </button>
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('dailyRoutine', 'complex'); goToPage(10); }}
                >
                  Advanced (10+ mins)
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'joints' && (
            <>
              <h2 className="fadeInRightTitle">6. Your Daily Joint Care Routine</h2>
              <p className="fadeInRightContent">
                Do you do regular stretching, physio, or minimal daily activity for joint relief?
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('dailyRoutine', 'simple'); goToPage(10); }}
                >
                  Minimal (&lt;5 mins)
                </button>
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('dailyRoutine', 'moderate'); goToPage(10); }}
                >
                  Moderate (5–10 mins)
                </button>
                <button
                  className="choice-btn"
                  onClick={() => { storeAnswer('dailyRoutine', 'complex'); goToPage(10); }}
                >
                  Advanced (10+ mins)
                </button>
              </div>
            </>
          )}
        </div>

        {/* PAGE 10: Budget (old page 9) */}
        <div className={pageIsActive(10)}>
          {answers.prpArea === 'hair' && (
            <>
              <h2 className="fadeInRightTitle">7. Your Monthly Budget (Hair PRP)</h2>
              <p className="info-box fadeInRightContent">
                Higher initial investment can accelerate regrowth, combining PRP with topicals or other therapies if needed.
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => chooseBudget('<£50')}
                >
                  Under £50
                </button>
                <button
                  className="choice-btn"
                  onClick={() => chooseBudget('£50–£100')}
                >
                  £50–£100
                </button>
                <button
                  className="choice-btn"
                  onClick={() => chooseBudget('£100–£200')}
                >
                  £100–£200
                </button>
                <button
                  className="choice-btn"
                  style={{ background: '#d19a94' }}
                  onClick={() => chooseBudget('£200+')}
                >
                  £200+
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'face' && (
            <>
              <h2 className="fadeInRightTitle">7. Your Monthly Budget (Facial PRP)</h2>
              <p className="info-box fadeInRightContent">
                Facial PRP can be paired with microneedling, advanced serums, or LED therapy. 
                Which range best fits your comfort level?
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => chooseBudget('<£50')}
                >
                  Under £50
                </button>
                <button
                  className="choice-btn"
                  onClick={() => chooseBudget('£50–£100')}
                >
                  £50–£100
                </button>
                <button
                  className="choice-btn"
                  onClick={() => chooseBudget('£100–£200')}
                >
                  £100–£200
                </button>
                <button
                  className="choice-btn"
                  style={{ background: '#d19a94' }}
                  onClick={() => chooseBudget('£200+')}
                >
                  £200+
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'joints' && (
            <>
              <h2 className="fadeInRightTitle">7. Your Monthly Budget (Joint PRP)</h2>
              <p className="info-box fadeInRightContent">
                Joint PRP may involve multiple sessions. A higher budget can include added physiotherapy or advanced injections.
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => chooseBudget('<£50')}
                >
                  Under £50
                </button>
                <button
                  className="choice-btn"
                  onClick={() => chooseBudget('£50–£100')}
                >
                  £50–£100
                </button>
                <button
                  className="choice-btn"
                  onClick={() => chooseBudget('£100–£200')}
                >
                  £100–£200
                </button>
                <button
                  className="choice-btn"
                  style={{ background: '#d19a94' }}
                  onClick={() => chooseBudget('£200+')}
                >
                  £200+
                </button>
              </div>
            </>
          )}
        </div>

        {/* PAGE 11: PRP Clinic Sessions (old page 10) */}
        <div className={pageIsActive(11)}>
          {answers.prpArea === 'hair' && (
            <>
              <h2 className="fadeInRightTitle">8. Hair PRP Clinic Sessions</h2>
              <p className="fadeInRightContent">
                Hair PRP typically requires monthly or bimonthly sessions. Does your schedule allow for that?
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => {
                    storeAnswer('clinicVisits', 'fine');
                    goToSummary();
                  }}
                >
                  Yes, I can do monthly PRP
                </button>
                <button
                  className="choice-btn"
                  onClick={() => {
                    storeAnswer('clinicVisits', 'limited');
                    goToSummary();
                  }}
                >
                  Limited visits only
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'face' && (
            <>
              <h2 className="fadeInRightTitle">8. Facial PRP Clinic Sessions</h2>
              <p className="fadeInRightContent">
                Facial PRP often requires 3–4 sessions spaced a few weeks apart. Can you accommodate that schedule?
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => {
                    storeAnswer('clinicVisits', 'fine');
                    goToSummary();
                  }}
                >
                  Yes, I can do regular PRP sessions
                </button>
                <button
                  className="choice-btn"
                  onClick={() => {
                    storeAnswer('clinicVisits', 'limited');
                    goToSummary();
                  }}
                >
                  Limited availability
                </button>
              </div>
            </>
          )}

          {answers.prpArea === 'joints' && (
            <>
              <h2 className="fadeInRightTitle">8. Joint PRP Clinic Sessions</h2>
              <p className="fadeInRightContent">
                Joint PRP typically needs multiple sessions ~4–6 weeks apart. Does that fit your schedule?
              </p>
              <div className="choice-container fadeInRightContent">
                <button
                  className="choice-btn"
                  onClick={() => {
                    storeAnswer('clinicVisits', 'fine');
                    goToSummary();
                  }}
                >
                  Yes, I can manage frequent PRP visits
                </button>
                <button
                  className="choice-btn"
                  onClick={() => {
                    storeAnswer('clinicVisits', 'limited');
                    goToSummary();
                  }}
                >
                  Limited in-person availability
                </button>
              </div>
            </>
          )}

          {showThinking && (
            <div className="thinking-overlay" style={{ display: 'flex' }}>
              <h3>Reviewing Your Answers...</h3>
              <p>Just a moment while we build your plan!</p>
              <div className="spinner"></div>
            </div>
          )}
        </div>

        {/* PAGE 12: final summary & form (old page 11) */}
        <div id="page12" className={pageIsActive(12)}>
          {!submitted && (
            <h2 className="fadeInRightTitle">Final Step: Reserve Your Spot</h2>
          )}

          {/* Error message (if any) */}
          {error && (
            <p
              style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}
              className="fadeInRightContent"
            >
              {error}
            </p>
          )}

          {submitted && (
            <div className="success-message">
              <p className="success-title">
                Your PRP Journey Submission was successful!
              </p>
              <p
                id="finalPlanIntro"
                dangerouslySetInnerHTML={{ __html: finalPlanIntro }}
              ></p>
            </div>
          )}

          {!submitted && (
            <>
              <p
                id="finalPlanIntro"
                className="fadeInRightContent"
                style={{ marginBottom: '16px' }}
              ></p>

              <form onSubmit={finishJourney} className="fadeInRightContent">
                <label htmlFor="fullName">
                  Full Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  id="fullName"
                  required
                  value={answers.fullName}
                  onChange={(e) => storeAnswer('fullName', e.target.value)}
                />

                <label htmlFor="email">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  required
                  value={answers.email}
                  onChange={(e) => storeAnswer('email', e.target.value)}
                />

                <label htmlFor="phone">
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  placeholder="+44 ..."
                  required
                  value={answers.phone}
                  onChange={(e) => storeAnswer('phone', e.target.value)}
                />

                <h3 style={{ marginTop: '15px' }}>Preferred Contact Method</h3>
                <div className="choice-container" style={{ marginBottom: 0 }}>
                  <button
                    className={getContactButtonClass('Email')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Email')}
                  >
                    Email
                  </button>
                  <button
                    className={getContactButtonClass('Call')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Call')}
                  >
                    Call
                  </button>
                  <button
                    className={getContactButtonClass('WhatsApp')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'WhatsApp')}
                  >
                    WhatsApp
                  </button>
                  <button
                    className={getContactButtonClass('NoCall')}
                    type="button"
                    onClick={async () => {
                      // user picks noCall => skip Firestore
                      storeAnswer('contactMethod', 'NoCall');
                      // post to Slack
                      try {
                        await fetch('/api/slack', {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify({
                            ...answers,
                            contactMethod: 'NoCall',
                            message: 'User selected "No call, just start now" on final PRP page, skipping Firestore.'
                          }),
                        });
                      } catch (slackErr) {
                        console.error('Error posting "NoCall" data to Slack:', slackErr);
                      }
                      // then navigate
                      window.location.href = '#/prp-packages';
                    }}
                  >
                    No call, just start now
                  </button>
                </div>

                <button className="btn" style={{ marginTop: 20 }}>
                  Submit &amp; Begin
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrpMultiAreaJourney;
