// src/components/LearnMore/LearnMoreBiomarker.js
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import SEO from '../SEO';
import './LearnMorePage.css';
import biomarkerImage from '../../images/blood.png';

const LearnMoreBiomarker = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <article className="learn-more-container">
      <SEO
        title="Biomarker Blood Screen - The Wellness London"
        description="Learn how our Biomarker Blood Screen can provide insights into your health, guiding personalized care and lifestyle adjustments at The Wellness London."
        keywords="biomarker, blood screen, health assessment, wellness"
      />

      <motion.header
        className="learn-more-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h1>Biomarker Blood Screen</h1>
        <p className="subheading">Illuminate Your Health from Within</p>
      </motion.header>

      <motion.section
        className="learn-more-hero"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2, duration: 0.6, ease: 'easeOut' }}
      >
        <img src={biomarkerImage} alt="Biomarker Blood Screen representation" className="learn-more-image" />
      </motion.section>

      <motion.section
        className="learn-more-content"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h2>What is a Biomarker Blood Screen?</h2>
        <p>
          Our Biomarker Blood Screen is a comprehensive test designed to provide in-depth insights into your 
          body’s biological functions. By analyzing key biomarkers, we can uncover early signs of imbalance, 
          guide targeted interventions, and help you optimize your overall health and wellness.
        </p>

        <h2>How It Works</h2>
        <p>
          Our qualified healthcare professionals will take a blood sample and run a thorough panel of tests. 
          These tests evaluate hormones, nutrients, metabolic markers, and more. Once complete, our team interprets 
          the results and develops a personalized plan to address any concerns and enhance your health.
        </p>

        <h2>Important Safety Information</h2>
        <p>
          The Biomarker Blood Screen is a low-risk procedure similar to a standard blood draw. Our team follows 
          strict protocols to ensure your comfort and safety. Any potential follow-up treatments or lifestyle recommendations 
          will be discussed in detail, so you can make informed decisions about your health.
        </p>

        <h2>Next Steps</h2>
        <p>
          Ready to gain a clearer picture of your health? Schedule a Biomarker Blood Screen and take the first step 
          toward a more informed and proactive approach to wellness.
        </p>
      </motion.section>

      <motion.footer
        className="learn-more-footer"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <button className="primary-cta" onClick={() => (window.location.href = '#/blood-tests')}>
          Get Started
        </button>
        <button className="secondary-cta" onClick={() => (window.location.href = '#/enquiry')}>
          Contact Us
        </button>
      </motion.footer>
    </article>
  );
};

export default LearnMoreBiomarker;
