import React from "react";
import "./CardScroll.css";

// Example images (import your own)
import sampleImage1 from "../images/7.png";
import sampleImage2 from "../images/9.png";
import sampleImage3 from "../images/8.png";
import sampleImage4 from "../images/10.png";

const imageList = [
  sampleImage1,
  sampleImage2,
  sampleImage3,
  sampleImage4
];

const CardScroll = () => {
  return (
    <div className="image-carousel-wrapper">
      <h2 className="title23">
        Faster Results. <br/> Improved Confidence.
      </h2>
      <div className="image-carousel-container">
        <div className="images-scroll-container">
          {imageList.map((imgSrc, idx) => (
            <div key={idx} className="image-card">
              <img src={imgSrc} alt={`carousel-${idx}`} className="carousel-image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardScroll;
