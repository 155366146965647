import React, { useState, useEffect, useRef } from 'react';
import './BloodTestsHairJourney.css';
import CanvaAnimation from '../../LandingPages/CanvaAnimation';
// Firestore (optional)
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';

const BloodTestsHairJourney = () => {
  console.log("BloodTestsHairJourney component mounted!");

  // Total pages is now 17
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 17;

  // Existing answers
  const [answers, setAnswers] = useState({
    medicalHistory: null,
    hairDuration: null,
    familyHistory: null,
    stressLevel: null,
    hairRoutine: null,
    budget: null,
    clinicVisits: null,
    contactMethod: null,
    fullName: '',
    email: '',
    phone: '',
  });

  // Which final option on new Page 13
  const [selectedOption, setSelectedOption] = useState(null);

  // Additional details (Page 14)
  const [extraDetails, setExtraDetails] = useState({
    address: '',
    dob: '',
    allergies: '',
    currentMedication: '',
  });

  // States for progress, overlay, etc.
  const [progress, setProgress] = useState(0);
  const [showThinking, setShowThinking] = useState(false);
  const [summaryProse, setSummaryProse] = useState('');
  const [finalPlanIntro, setFinalPlanIntro] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [consultationConfirmed, setConsultationConfirmed] = useState(false);

  // Payment success (Page17)
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentPackage, setPaymentPackage] = useState('');

  // For the new Page 12 (Canvas + Timer)
  const [timeLeft, setTimeLeft] = useState(10); // 10 seconds countdown

  // ----------------------------------------------------------------------------
  // UTILS
  // ----------------------------------------------------------------------------
  const goToPage = (num) => {
    setCurrentPage(num);
    updateProgressBar(num);
  };

  const storeAnswer = (key, value) => {
    setAnswers((prev) => ({ ...prev, [key]: value }));
    console.log('Storing Answer:', key, value);
  };

  const updateProgressBar = (targetPage) => {
    const fraction = (targetPage - 1) / (totalPages - 1);
    const percentage = Math.min(100, fraction * 100);
    setProgress(percentage);
  };

  // ----------------------------------------------------------------------------
  // PAGE 9->10 short thinking
  // ----------------------------------------------------------------------------
  const goToSummary = () => {
    setShowThinking(true);
    setTimeout(() => {
      setShowThinking(false);
      buildProseSummary();
      goToPage(11); // Summaries at Page11
    }, 2000);
  };

  // Page8->9 after choosing budget
  const chooseBudget = (value) => {
    storeAnswer('budget', value);
    goToPage(10);
  };

  // ----------------------------------------------------------------------------
  // PAGE7: Mid-Journey Summary
  // ----------------------------------------------------------------------------
  const fillMidJourneyBox = () => {
    let medTxt =
      answers.medicalHistory === 'none'
        ? 'No major medical concerns reported'
        : answers.medicalHistory === 'thyroid'
        ? 'Thyroid/Hormonal condition'
        : answers.medicalHistory === 'alopecia'
        ? 'Alopecia Areata indicated'
        : answers.medicalHistory === 'postpartum'
        ? 'Postpartum hair loss'
        : answers.medicalHistory === 'other'
        ? 'Another condition our doctors will discuss further'
        : 'Some medical factors to discuss further';

    let durationTxt =
      answers.hairDuration === 'longterm'
        ? 'Hair loss has lasted over 1 year'
        : answers.hairDuration === 'recent'
        ? 'Hair loss began within the last year'
        : 'Hair loss timeframe not fully specified';

    let familyTxt =
      answers.familyHistory === 'yes'
        ? 'Family history of hair thinning'
        : 'No definitive family history reported';

    let stressTxt =
      answers.stressLevel === 'often'
        ? 'Frequent stress or lifestyle factors'
        : answers.stressLevel === 'sometimes'
        ? 'Occasional stress/lifestyle impact'
        : answers.stressLevel === 'rarely'
        ? 'Rarely affected by stress'
        : 'Unclear stress impact';

    return (
      <>
        <strong>Medical:</strong> {medTxt}
        <br />
        <strong>Duration:</strong> {durationTxt}
        <br />
        <strong>Family History:</strong> {familyTxt}
        <br />
        <strong>Stress &amp; Lifestyle:</strong> {stressTxt}
      </>
    );
  };

  // ----------------------------------------------------------------------------
  // PAGE11: Build final summary text
  // ----------------------------------------------------------------------------
  const buildProseSummary = () => {
    let medStr = 'no major health concerns mentioned';
    if (answers.medicalHistory === 'thyroid') {
      medStr = 'a thyroid/hormonal condition we’ll adapt our plan around';
    } else if (answers.medicalHistory === 'alopecia') {
      medStr = 'Alopecia Areata—may require advanced scalp/injection therapy';
    } else if (answers.medicalHistory === 'postpartum') {
      medStr = 'postpartum hair loss—often responds best to gentle but focused support';
    } else if (answers.medicalHistory === 'other') {
      medStr = 'another condition our doctors will discuss further';
    }

    const durText =
      answers.hairDuration === 'longterm'
        ? 'you’ve faced hair thinning for over a year'
        : 'you’ve noticed hair loss within the last year';

    const famText =
      answers.familyHistory === 'yes'
        ? 'hair loss does run in your immediate family'
        : 'you’re unsure of a strong genetic link';

    const stressText = answers.stressLevel || 'some unmentioned lifestyle factors';

    const routineMap = {
      simple: 'a minimal daily routine (<5 mins)',
      moderate: 'a moderate approach (5–10 mins)',
      complex: 'an advanced styling/treatment routine (10+ mins)',
    };
    const routine = routineMap[answers.hairRoutine] || 'your usual daily approach';

    const prpText =
      answers.clinicVisits === 'fine'
        ? 'you can attend PRP sessions in our clinic every 4–6 weeks'
        : 'you’d prefer to avoid frequent in-person sessions';

    const finalText = `
      You have <strong>${medStr}</strong>, and <strong>${durText}</strong>.
      We’ve noted that <strong>${famText}</strong> and <strong>${stressText}</strong>.
      Your hair routine is <strong>${routine}</strong>, and <strong>${prpText}</strong>.
      <br /><br />
      <strong>Great news:</strong> you’re fully eligible for a custom treatment plan here at <strong>The Wellness</strong>!
    `;
    setSummaryProse(finalText);
  };

  // ----------------------------------------------------------------------------
  // PAGE12 (NEW): Canvas Animation + 10-second timer
  // ----------------------------------------------------------------------------
  useEffect(() => {
    if (currentPage === 12 && timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    }
    if (currentPage === 12 && timeLeft === 0) {
      // auto-jump to next page (the old page12, now page13)
      goToPage(13);
    }
    // eslint-disable-next-line
  }, [currentPage, timeLeft]);

  const skipCanvasPage = () => {
    goToPage(13);
  };

  // ----------------------------------------------------------------------------
  // PAGE13 (Old Page12): Provide 5 Options
  // ----------------------------------------------------------------------------
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    goToPage(14); // used to be page13, now 14
  };

  // ----------------------------------------------------------------------------
  // PAGE14 (Old Page13): Collect Info => Slack => Stripe or consult
  // ----------------------------------------------------------------------------
  const handleDetailsSubmit = async (e) => {
    e.preventDefault();

    const { address, dob, allergies, currentMedication } = extraDetails;
    if (!answers.fullName || !answers.email || !answers.phone || !address || !dob) {
      setError('Please fill out all required fields (Name, Email, Phone, Address, DOB).');
      return;
    }

    try {
      // 1) Save user data in localStorage for post-payment
      const userData = {
        selectedOption,
        ...answers,
        address,
        dob,
        allergies,
        currentMedication,
      };
      localStorage.setItem('hairUserData', JSON.stringify(userData));

      // 2) Post to Slack (server)
      const slackRes = await fetch('/api/slack', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          selectedOption,
          fullName: answers.fullName,
          email: answers.email,
          phone: answers.phone,
          address,
          dob,
          allergies,
          currentMedication,
          paymentStatus: 'Not Paid',
          message: 'New Hair Journey Submission',
        }),
      });
      if (!slackRes.ok) {
        throw new Error('Slack request failed');
      }

      // 3) Firestore
      await addDoc(collection(db, 'hairJourneyFinal'), {
        selectedOption,
        ...answers,
        address,
        dob,
        allergies,
        currentMedication,
        timestamp: new Date(),
      });

      // 4) Next step
      switch (selectedOption) {
        case 'foamOnly':
          window.location.href = 'https://buy.stripe.com/9AQg1X0Tn7Rb5Hy28e';
          break;
        case 'prpOnly':
          window.location.href = 'https://buy.stripe.com/eVa6rndG95J36LCcMR';
          break;
        case 'foam':
          window.location.href = 'https://buy.stripe.com/fZedTP7hLefzda0aEG';
          break;
        case 'prp':
          window.location.href = 'https://buy.stripe.com/cN2dTP6dHefz8TKbIL';
          break;
        default:
          // consult
          setConsultationConfirmed(true);
          goToPage(16);
          break;
      }
    } catch (err) {
      console.error('Error posting data to Slack/Firestore:', err);
      setError('Sorry, something went wrong. Please try again.');
    }
  };

  // ----------------------------------------------------------------------------
  // PAGE15 (Old Page14): (Optional) "reserve your spot"
  // ----------------------------------------------------------------------------
  const finishJourney = async (e) => {
    e.preventDefault();
    const { fullName, email, phone } = answers;

    if (!fullName || !email || !phone) {
      setError('Please provide your name, email, and phone so we can finalize your plan.');
      return;
    }

    try {
      await addDoc(collection(db, 'hairJourneys'), {
        ...answers,
        timestamp: new Date(),
      });
      setSubmitted(true);
      setFinalPlanIntro(`
        Thank you, <strong>${fullName}</strong>.
        We’ve reserved a place for you in our hair restoration programme.
      `);
    } catch (err) {
      console.error('Error submitting final journey data:', err);
      setError('Sorry, there was a problem submitting your information. Please try again.');
    }
  };
  // ----------------------------------------------------------------------------
  // PAGE15: If user chose “consult” => final "We'll be in contact"
  // ----------------------------------------------------------------------------
  const renderConsultationPage = () => (
    <div className="form-page active">
      <h2>Thank You!</h2>
      <p>
        A member of our team will be in contact soon to schedule your 
        10-minute doctor consultation.
      </p>
    </div>
  );

  // ----------------------------------------------------------------------------
  // PAGE16: Payment Success (redirect from Stripe)
  // ----------------------------------------------------------------------------
  const renderPaymentSuccessPage = () => (
    <div className="form-page active" style={{ textAlign: 'center' }}>
      <h2>Congratulations!</h2>
      <p>Your payment was successful. A member of the team will be in contact soon.</p>
      <p style={{ marginTop: '20px' }}>
        We’ve updated our records to confirm you purchased: <strong>{paymentPackage}</strong>.
      </p>
    </div>
  );

  // Called if the user returns with #/payment-success
  const handlePaymentSuccess = async () => {
    try {
      // Retrieve user data from localStorage
      const stored = localStorage.getItem('hairUserData');
      if (!stored) return;

      const userData = JSON.parse(stored);
      const { selectedOption, fullName, email, phone } = userData;

      // Figure out which package they purchased
      let pkgName = '';
      switch (selectedOption) {
        case 'foamOnly':
          pkgName = 'Hair Foam Only';
          break;
        case 'prpOnly':
          pkgName = 'Platelet Rich Plasma (PRP) Only';
          break;
        case 'foam':
          pkgName = 'Hair Foam + Supplements';
          break;
        case 'prp':
          pkgName = 'PRP + Hair Foam + Supplements';
          break;
        default:
          pkgName = 'Unknown Package';
      }
      setPaymentPackage(pkgName);

      // Post a "Payment success" update to Slack via /api/slack
      await fetch('/api/slack', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          selectedOption,
          fullName,
          email,
          phone,
          paymentStatus: 'Paid',
          message: `User has successfully paid for ${pkgName}`,
        }),
      });

      // Clear localStorage so we don’t update again
      localStorage.removeItem('hairUserData');

      setPaymentSuccess(true);
      goToPage(16);
    } catch (err) {
      console.error('Error updating payment status:', err);
    }
  };

  // ----------------------------------------------------------------------------
  // On mount or page change, check if #/payment-success
  // ----------------------------------------------------------------------------
  useEffect(() => {
    updateProgressBar(currentPage);

    if (window.location.href.includes('#/payment-success')) {
      handlePaymentSuccess();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  // Helper: Show/hide pages
  const pageIsActive = (pageNumber) =>
    currentPage === pageNumber ? 'form-page active' : 'form-page';

  // Helper: highlight contact method
  const getContactButtonClass = (method) => {
    return answers.contactMethod === method ? 'choice-btn active' : 'choice-btn';
  };

  // ----------------------------------------------------------------------------
  // RENDER
  // ----------------------------------------------------------------------------
  return (
    <div className="outer-container">
      {/* Progress Bar */}
      <div className="progress-bar-fixed">
        <div className="progress-wrap">
          <div className="progress-bar" style={{ width: progress + '%' }}></div>
        </div>
      </div>

      <div className="container fade-in">
        {/* --------------------------------------------------------------------- */}
        {/* PAGE 1 */}
        <div className={pageIsActive(1)} id="page1">
          <h2 className="fadeInRightTitle">Get Started With Your Hair Growth Journey</h2>
          <p className="fadeInRightContent">Choose one of the following:</p>
          <div className="choice-container fadeInRightContent">
            <button className="choice-btn" onClick={() => goToPage(2)}>
              Personalise My Treatment
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                // direct link or route to hair packages
                window.location.href = '#/hair-packages';
              }}
            >
              Start Clinically Proven Hair Foam Now
            </button>
          </div>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 2: Intro */}
        <div className={pageIsActive(2)} id="page2">
          <h2 className="fadeInRightTitle">Welcome to Your Personalised Hair Growth Journey</h2>
          <p className="fadeInRightContent">
            Want fuller hair—fast?
            We’ve already guided <strong>1,000+</strong> clients to success.
            Our spaces are limited for personalized doctor-led care. <br /><br />
            Answer a few short questions, and we’ll build your perfect plan—topicals, vitamins, or advanced PRP. Let’s get started!
          </p>
          <div className="info-box fadeInRightContent">
            <p>
              <strong>Note:</strong> If pregnant, postpartum, or breastfeeding, please let us know
              as certain medicines or PRP may be unsuitable. This short quiz helps guide us; 
              we’ll finalise your plan with you directly.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(3)}>
            Start
          </button>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 3: Medical Condition */}
        <div className={pageIsActive(3)} id="page3">
          <h2 className="fadeInRightTitle">1. Any Relevant Medical Conditions?</h2>
          <p className="fadeInRightContent">
            Are there any conditions that may influence your hair health or our treatment options?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalHistory', 'none');
                goToPage(4);
              }}
            >
              None / Unsure
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalHistory', 'thyroid');
                goToPage(4);
              }}
            >
              Thyroid / Hormonal
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalHistory', 'alopecia');
                goToPage(4);
              }}
            >
              Alopecia Areata
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalHistory', 'postpartum');
                goToPage(4);
              }}
            >
              Postpartum
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('medicalHistory', 'other');
                goToPage(4);
              }}
            >
              Other / Multiple
            </button>
          </div>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 4: Hair Loss Duration */}
        <div className={pageIsActive(4)} id="page4">
          <h2 className="fadeInRightTitle">2. Hair Loss Duration</h2>
          <p className="info-box fadeInRightContent">
            If it’s been over a year, we often recommend a more comprehensive plan—combining topical, nutritional,
            and regenerative steps—to jumpstart noticeable regrowth.
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('hairDuration', 'recent');
                goToPage(5);
              }}
            >
              Within the past year
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('hairDuration', 'longterm');
                goToPage(5);
              }}
            >
              Over a year
            </button>
          </div>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 5: Family History */}
        <div className={pageIsActive(5)} id="page5">
          <h2 className="fadeInRightTitle">3. Family History</h2>
          <p className="fadeInRightContent">
            Does thinning hair run in your immediate family?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('familyHistory', 'yes');
                goToPage(6);
              }}
            >
              Yes
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('familyHistory', 'no');
                goToPage(6);
              }}
            >
              No / Unsure
            </button>
          </div>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 6: Stress & Lifestyle */}
        <div className={pageIsActive(6)} id="page6">
          <h2 className="fadeInRightTitle">4. Stress &amp; Lifestyle</h2>
          <p className="fadeInRightContent">
            How frequently do stress, lack of sleep, or nutritional gaps disrupt your hair’s natural growth cycle?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'often');
                goToPage(7);
              }}
            >
              Often
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'sometimes');
                goToPage(7);
              }}
            >
              Sometimes
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'rarely');
                goToPage(7);
              }}
            >
              Rarely
            </button>
          </div>
          <div className="testimonial-bubble fadeInRightContent" style={{ animationDelay: '0.7s' }}>
            "Stress was my biggest culprit. Their tailored approach with vitamins &amp; scalp routines turned everything around.
            <br />
            — <em>Matt, Manchester</em>"
          </div>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 7: Mid-Journey Summary */}
        <div className={pageIsActive(7)} id="page7">
          <h2 className="fadeInRightTitle">Mid-Journey Review</h2>
          <p className="fadeInRightContent">
            Great job so far! Here’s a quick look at what we’ve noted:
          </p>
          <div className="info-box fadeInRightContent" id="midJourneyBox" style={{ fontSize: '15px' }}>
            {fillMidJourneyBox()}
          </div>
          <p className="fadeInRightContent">
            Up next, we’ll gather a few more details about your routine, budget, and availability for in-clinic options like PRP.
            Then we’ll provide a final, doctor-guided recommendation and get you on the path to fuller, healthier hair.
          </p>
          <button className="btn fadeInRightContent" onClick={() => goToPage(8)}>
            Next
          </button>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 8: Hair Routine */}
        <div className={pageIsActive(8)} id="page8">
          <h2 className="fadeInRightTitle">5. Your Hair Care Routine</h2>
          <p className="fadeInRightContent">
            Is your daily routine minimal (&lt;5 mins), moderate (5–10 mins), or more advanced (10+ mins)?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('hairRoutine', 'simple');
                goToPage(9);
              }}
            >
              Minimal
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('hairRoutine', 'moderate');
                goToPage(9);
              }}
            >
              Moderate
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('hairRoutine', 'complex');
                goToPage(9);
              }}
            >
              Advanced
            </button>
          </div>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 9: Budget */}
        <div className={pageIsActive(9)} id="page9">
          <h2 className="fadeInRightTitle">6. Your Monthly Budget</h2>
          <p className="info-box fadeInRightContent">
            We’ll match treatments to your comfort level, but a higher initial investment often means faster, more dramatic results.
          </p>
          <div className="choice-container fadeInRightContent">
            <button className="choice-btn" onClick={() => chooseBudget('<£50')}>
              Under £50
            </button>
            <button className="choice-btn" onClick={() => chooseBudget('£50–£100')}>
              £50–£100
            </button>
            <button className="choice-btn" onClick={() => chooseBudget('£100–£200')}>
              £100–£200
            </button>
            <button
              className="choice-btn"
              style={{ background: '#d19a94' }}
              onClick={() => chooseBudget('£200+')}
            >
              £200+
            </button>
          </div>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 10: PRP Availability */}
        <div className={pageIsActive(10)} id="page10">
          <h2 className="fadeInRightTitle">7. PRP Clinic Visits</h2>
          <p className="fadeInRightContent">
            PRP therapy typically requires in-clinic sessions every 4–6 weeks. Does that fit your schedule, or do you prefer fewer
            in-person visits?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('clinicVisits', 'fine');
                goToSummary();
              }}
            >
              Yes, I can do PRP visits
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('clinicVisits', 'limited');
                goToSummary();
              }}
            >
              Limited availability
            </button>
          </div>
        </div>

{/* THINKING OVERLAY */}
{showThinking && (
          <div className="thinking-overlay" style={{ display: 'flex' }}>
            <h3>Reviewing Your Answers...</h3>
            <p>Just a moment while we build your plan!</p>
            <div className="spinner"></div>
          </div>
        )}

        {/* PAGE 11 */}
        <div className={pageIsActive(11)} id="page11">
          <h2 className="fadeInRightTitle">Your Personalised Treatment</h2>
          <p
            id="summaryProse"
            className="fadeInRightContent"
            style={{ marginBottom: '16px' }}
            dangerouslySetInnerHTML={{ __html: summaryProse }}
          ></p>
          <button className="btn fadeInRightContent" onClick={() => goToPage(12)}>
            Next
          </button>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 12 (NEW): Canvas Animation + Timer */}
        <div className={pageIsActive(12)} id="page12">
          <h2 className="fadeInRightTitle">Hold On, Something Special...</h2>
          <p className="fadeInRightContent">
            We’re preparing your next steps! Please enjoy this short animation.
          </p>
          <div className="info-box fadeInRightContent" style={{ textAlign: 'center' }}>
<CanvaAnimation/>
            <p style={{ marginTop: '1rem' }}>
              Automatically continuing in <strong>{timeLeft}</strong> seconds...
            </p>
          </div>
          <button
            className="btn fadeInRightContent"
            style={{ marginTop: '20px' }}
            onClick={skipCanvasPage}
          >
            Skip & Go To Treatment Options
          </button>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 13 (Old Page12): 5 Options */}
        <div className={pageIsActive(13)} id="page13">
  <h2 className="fadeInRightTitle">Choose Your Next Step</h2>
  <p className="fadeInRightContent">
    How would you like to commence your hair growth journey?
  </p>

  {/* -- ADDED DISCLAIMER -- */}
  <div className="info-box fadeInRightContent" style={{ marginBottom: '1rem' }}>
    <p>
      <strong>Disclaimer:</strong> Our hair foam contains <em>minoxidil, biotin, caffeine, finasteride, 
      and saw palmetto</em>. Please be aware that all PRP treatments are subject to physician approval 
      prior to completion.
    </p>
  </div>

  <div className="choice-container fadeInRightContent">
    <button className="choice-btn" onClick={() => handleOptionClick('foamOnly')}>
      Hair Foam Only
    </button>
    <button className="choice-btn" onClick={() => handleOptionClick('prpOnly')}>
      Platelet Rich Plasma (PRP) Treatment
    </button>
    <button className="choice-btn" onClick={() => handleOptionClick('foam')}>
      Clinically Proven Hair Foam + Supplements
    </button>
    <button className="choice-btn" onClick={() => handleOptionClick('prp')}>
      PRP + Clinically Proven Hair Foam + Supplements
    </button>
    <button className="choice-btn" onClick={() => handleOptionClick('consult')}>
      Complimentary 10 Minute Doctor Consultation
    </button>
  </div>
</div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 14 (Old Page13): Gather Extra Info => Slack => Stripe */}
        <div className={pageIsActive(14)} id="page14">
          <h2 className="fadeInRightTitle">Tell Us More About You</h2>
          <p className="fadeInRightContent">
            Please fill in the details below so we can tailor your experience.
          </p>

          {error && (
            <p style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }} className="fadeInRightContent">
              {error}
            </p>
          )}

          <form onSubmit={handleDetailsSubmit} className="fadeInRightContent">
            {/* Full Name, Email, etc. */}
            <label htmlFor="fullName">Full Name *</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              required
              value={answers.fullName}
              onChange={(e) => storeAnswer('fullName', e.target.value)}
            />

            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={answers.email}
              onChange={(e) => storeAnswer('email', e.target.value)}
            />

            <label htmlFor="phone">Phone *</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="+44 ..."
              required
              value={answers.phone}
              onChange={(e) => storeAnswer('phone', e.target.value)}
            />

            <label htmlFor="address">Address *</label>
            <input
              type="text"
              id="address"
              name="address"
              required
              value={extraDetails.address}
              onChange={(e) =>
                setExtraDetails((prev) => ({ ...prev, address: e.target.value }))
              }
            />

            <label htmlFor="dob">Date of Birth *</label>
            <input
              type="date"
              id="dob"
              name="dob"
              required
              value={extraDetails.dob}
              onChange={(e) =>
                setExtraDetails((prev) => ({ ...prev, dob: e.target.value }))
              }
            />

            <label htmlFor="allergies">Allergies (if any)</label>
            <input
              type="text"
              id="allergies"
              name="allergies"
              placeholder="None"
              value={extraDetails.allergies}
              onChange={(e) =>
                setExtraDetails((prev) => ({ ...prev, allergies: e.target.value }))
              }
            />

            <label htmlFor="currentMedication">Current Medication (if any)</label>
            <input
              type="text"
              id="currentMedication"
              name="currentMedication"
              placeholder="None"
              value={extraDetails.currentMedication}
              onChange={(e) =>
                setExtraDetails((prev) => ({ ...prev, currentMedication: e.target.value }))
              }
            />

            <button className="btn" style={{ marginTop: '20px' }}>
              Submit &amp; Proceed
            </button>
          </form>
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 15 (Old Page14): (Optional) "Reserve your spot" */}
        <div
          id="page15"
          className={`form-page ${pageIsActive(15)} ${!submitted ? 'not-submitted' : ''}`}
        >
          {!submitted && (
            <h2 className="fadeInRightTitle">Final Step: Reserve Your Spot</h2>
          )}

          {error && (
            <p style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }} className="fadeInRightContent">
              {error}
            </p>
          )}

          {submitted && (
            <div className="success-message">
              <p className="success-title">
                Your Hair Journey Submission was successful!
              </p>
              <p
                id="finalPlanIntro"
                dangerouslySetInnerHTML={{ __html: finalPlanIntro }}
              ></p>
            </div>
          )}

          {!submitted && (
            <>
              <p
                id="finalPlanIntro"
                className="fadeInRightContent"
                style={{ marginBottom: '16px' }}
              ></p>

              <form onSubmit={finishJourney} className="fadeInRightContent">
                <label htmlFor="fullName">Full Name *</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  required
                  value={answers.fullName}
                  onChange={(e) => storeAnswer('fullName', e.target.value)}
                />

                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={answers.email}
                  onChange={(e) => storeAnswer('email', e.target.value)}
                />

                <label htmlFor="phone">Phone Number *</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="+44 ..."
                  required
                  value={answers.phone}
                  onChange={(e) => storeAnswer('phone', e.target.value)}
                />

                <h3 style={{ marginTop: '15px' }}>Preferred Contact Method</h3>
                <div className="choice-container" style={{ marginBottom: 0 }}>
                  <button
                    className={getContactButtonClass('Email')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Email')}
                  >
                    Email
                  </button>
                  <button
                    className={getContactButtonClass('Call')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Call')}
                  >
                    Call
                  </button>
                  <button
                    className={getContactButtonClass('WhatsApp')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'WhatsApp')}
                  >
                    WhatsApp
                  </button>
                </div>

                <button className="btn" style={{ marginTop: '20px' }}>
                  Submit &amp; Begin My Journey
                </button>
              </form>
            </>
          )}
        </div>

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 16 (Old Page15): If user chose consult */}
        {currentPage === 16 && consultationConfirmed && renderConsultationPage()}

        {/* --------------------------------------------------------------------- */}
        {/* PAGE 17 (Old Page16): Payment Success */}
        {currentPage === 17 && paymentSuccess && renderPaymentSuccessPage()}
      </div>
    </div>
  );
};

export default BloodTestsHairJourney;