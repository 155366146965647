// src/pages/PRPPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import './ServiceSummary.css';

const PRPPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/loading');
  };

  return (
    <div className="service-summary-page">
      <div className="animated-bg-wave" />

      <SEO
        title="PRP Therapy in London | Regenerative Platelet-Rich Plasma | The Wellness London"
        description="Explore PRP therapy at The Wellness London. Platelet-rich plasma treatments support hair growth, joint healing, and skin rejuvenation backed by emerging evidence."
        keywords="prp therapy, platelet-rich plasma, hair growth prp, joint pain prp, skin rejuvenation, prp london"
      />

      <div className="service-summary-container">
        <h1>Platelet-Rich Plasma (PRP) Therapy</h1>
        <p>
          Harness your body’s natural healing capabilities with <strong>PRP (Platelet-Rich Plasma) </strong>
          therapy. By extracting and concentrating platelets from your own blood, we can deliver a powerful
          boost to targeted areas for faster tissue repair, improved hair growth, and potential relief from
          joint discomfort.
        </p>
        <p>
          <strong>How PRP Works:</strong><br />
          - <strong>Hair Restoration</strong>: PRP injections can stimulate dormant hair follicles, promoting thicker, healthier hair growth.<br />
          - <strong>Joint Healing</strong>: PRP therapy may help reduce inflammation and speed up recovery in joints affected by minor injuries or early osteoarthritis.<br />
          - <strong>Skin Rejuvenation</strong>: As a cosmetic treatment, PRP facials can enhance skin texture, reduce fine lines, and support collagen production.<br />
          - <strong>Evidence &amp; Ongoing Studies</strong>: While PRP shows promising results in many areas, research is ongoing. Most patients report positive improvements, but individual outcomes can vary.
        </p>
        <p>
          Whether you’re looking to address hair thinning, joint discomfort, or rejuvenate your skin,
          our experienced team will guide you through PRP therapy with a personalized treatment
          plan designed for your unique goals.
        </p>

        <div className="button-wrapper" style={{ textAlign: 'center' }}>
  <button className="questionnaire-button" onClick={handleClick}>
  Your Personalised Treatment
  </button>
</div>

      </div>
    </div>
  );
};

export default PRPPage;
