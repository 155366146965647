import React from "react";
import "./HairLossBanner.css";
import { useNavigate } from 'react-router-dom';

const HairLossBanner = () => {

  const navigate = useNavigate();

  const handleClick = () => {
      navigate('/blood-tests-hair-journey');
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

  return (
    <section className="hairloss-banner">
      <div className="banner-content">
        {/* LEFT COLUMN: Heading Only */}
        <div className="left-column">
          <h1>Regrow Your Hair. Reclaim Your Confidence.</h1>
        </div>

        {/* RIGHT COLUMN: Checklist + CTA Box */}
        <div className="right-column">
          <ul className="checklist">
            <li>
              <span className="check-icon">&#10003;</span> See real regrowth in as few as 3–6 months<sup>*</sup>
            </li>
            <li>
              <span className="check-icon">&#10003;</span> Powered by doctor‑endorsed, clinically proven treatments
            </li>
            <li>
              <span className="check-icon">&#10003;</span> 100% online process with unlimited expert support
            </li>
          </ul>
          <div className="cta-box">
            <p className="cta-text">
              Start your personalized hair restoration journey—tell us about your goals, and we’ll tailor a plan just for you:
            </p>
            <button className="cta-button" onClick={handleClick}>
              Take the Quiz <span className="arrow">→</span>
            </button>
          </div>
        </div>
      </div>

      {/* FOOTER / DISCLAIMER */}
      <p className="disclaimer">
        Restrictions apply. See website for full details and important safety information.
        <br />
        <sup>*</sup>Based on separate individual studies of licensed hair loss treatments.
      </p>
    </section>
  );
};

export default HairLossBanner;
