import React, { useState, useEffect } from "react";
import "./Blood2Banner.css";
import { useNavigate } from "react-router-dom";

const PreventativeBanner = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    navigate("/blood-tests");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const HeroTextBlock = (
    <div className="hero-text3">
      <h1 className="hero-title3">Stay Ahead of Hidden Risks</h1>
      <p className="hero-subtitle3">
        Advanced blood tests reveal early warning signs in hormone, cardiovascular, 
        and organ health—helping you act before symptoms strike.
      </p>
    </div>
  );

  const HeroButton = (
    <button className="hero-button3" onClick={handleClick}>
      Check Your Blood Now
    </button>
  );

  return (
    <>
      {isMobile && <div className="hero-mobile-text3">{HeroTextBlock}</div>}

      <section className="hero-section3">
        <div className="hero-content3">
          {!isMobile && HeroTextBlock}
        </div>
      </section>

      {HeroButton}

      <p className="hero-disclaimer3">
        *We focus on prevention, not reaction. Visit our website for details, 
        safety information, and testing guidelines.
      </p>
    </>
  );
};

export default PreventativeBanner;
