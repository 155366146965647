import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";
import "./BloodTesting.css";

// Primary images for the card's main usage:
import lifestyle from "../../images/lifestyle.jpg";
import lifestylehormone from "../../images/lifestyle-hormone.jpg";
import advanced from "../../images/advanced.jpg";

// The separate images you want between title and price:
import middleLifestyle from "../../images/girl1.png";
import middleHormone from "../../images/girl2.png";
import middleAdvanced from "../../images/girl3.png";
import klarna from '../../images/klarna.png';

// Arrow icon
import arrow from "../../images/button.png";

function BloodTesting() {
  const location = useLocation();

  const tests = [
    {
      id: 0,
      title: "Lifestyle",
      price: "£400.00",
      imageSrc: lifestyle,
      middleImage: middleLifestyle,
      middleClass: "image-lifestyle",
      stripeLink: "https://buy.stripe.com/00g6rn7hL4EZgmc3cl",
      text: "*Your essential snapshot of internal health, checking 70+ biomarkers...",
    },
    {
      id: 1,
      title: "Lifestyle + Hormone",
      price: "£500.00",
      imageSrc: lifestylehormone,
      middleImage: middleHormone,
      middleClass: "image-hormone",
      stripeLink: "https://buy.stripe.com/bIYbLH0Tn3AVee45ku",
      text: "*Ideal if you’re experiencing low energy, mood swings, or weight fluctuations.",
    },
    {
      id: 2,
      title: "Advanced Health",
      price: "£650.00",
      imageSrc: advanced,
      middleImage: middleAdvanced,
      middleClass: "image-advanced",
      stripeLink: "https://buy.stripe.com/3csaHD6dH0oJfi828j",
      text: "*Highly recommended for leaders, athletes, or anyone determined to achieve peak performance and longevity.",
    },
  ];

  const [openModalId, setOpenModalId] = useState(null);
  const cardsRef = useRef([]);

  // Payment success logic
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useState("");

  // If user came from BloodTestsJourney => location.state.userData
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Animate with GSAP
    gsap.fromTo(
      cardsRef.current,
      { y: 50, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.2,
        stagger: 0.2,
        ease: "power3.out",
      }
    );
  }, []);

  useEffect(() => {
    // If we have location.state, user came from the 14-page journey
    if (location.state && location.state.userData) {
      setUserData(location.state.userData);
    }
  }, [location]);

  const handleOpenModal = (id) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setOpenModalId(id);
  };
  const handleCloseModal = () => setOpenModalId(null);

  // On card click => store data + open Stripe
  const openStripeLink = (link, test) => {
    // We'll store the user data in localStorage
    const storeObj = {
      ...userData,
      packageName: test.title,
    };
    localStorage.setItem("bloodUserData", JSON.stringify(storeObj));

    // Then open Stripe in a new tab or same tab
    window.open(link, "_blank", "noopener,noreferrer");
  };

  // On mount or re-render, if URL has #/blood-test-success, final Slack update
  useEffect(() => {
    if (window.location.href.includes("#/blood-test-success")) {
      const stored = localStorage.getItem("bloodUserData");
      if (!stored) return;
      const storedData = JSON.parse(stored);

      const pkgName = storedData.packageName || "Unknown Package";
      setPurchasedPackage(pkgName);
      setPurchaseSuccess(true);

      // Post Slack update => /api/slack
      fetch("/api/slack", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fullName: storedData.fullName || "",
          email: storedData.email || "",
          phone: storedData.phone || "",
          packageName: pkgName,
          message: `Blood Test purchase successful: ${pkgName}`,
        }),
      })
        .then((res) => {
          if (!res.ok) throw new Error("Slack request failed");
        })
        .catch((err) => {
          console.error("Error updating Slack after purchase:", err);
        })
        .finally(() => {
          // Clear localStorage
          localStorage.removeItem("bloodUserData");
        });
    }
  }, []);

  return (
    <div className="blood-testing-container">
      {/* if purchaseSuccess => show success message, else show the original structure */}
      {!purchaseSuccess ? (
        <>
          <div className="placeholder-text1">
            <h1>Better Health</h1>
            <p>personalised to you</p>

          </div>

          <div className="cards-container">
            {tests.map((test, index) => (
              <div
                className="card-wrapper"
                key={test.id}
                ref={(el) => (cardsRef.current[index] = el)}
              >
                <div
                  className="test-card"
                  onClick={() => openStripeLink(test.stripeLink, test)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="card-content">
                    <h2>{test.title}</h2>
                    <img
                      src={test.middleImage}
                      alt={`${test.title} - Additional Preview`}
                      className={`middle-image ${test.middleClass}`}
                    />
                  </div>

                  {/* "More Information" => open modal, stopPropagation */}
                  <p
                    className="view-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenModal(test.id);
                    }}
                  >
                    More Information
                  </p>

                  <div className="price-row">
                    <span className="purchase-link">{test.price}</span>
                    <img className="arrow-icon-right" src={arrow} alt="Arrow" />
                  </div>
                </div>

                <p className="outside-text">{test.text}</p>
              </div>
            ))}
                        <img
                      src={klarna}
                      alt="klarna available"
                      className="klarna"
                    />
          </div>

          {openModalId !== null && (
            <div className="modal-overlay" onClick={handleCloseModal}>
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={tests.find((t) => t.id === openModalId).imageSrc}
                  alt="Panel Preview"
                  className="modal-image"
                />
                <button className="close-btn" onClick={handleCloseModal}>
                  Close
                </button>
              </div>
              
            </div>
          )}
        </>
      ) : (
        <div className="success-section">
          <h2>Congratulations!</h2>
          <p>
            Your payment was successful for{" "}
            <strong>{purchasedPackage}</strong>. 
            A member of the team will be in contact soon.
          </p>
        </div>
      )}
    </div>
  );
}

export default BloodTesting;
