// App.js

import React, { useState, useEffect, createContext } from 'react';
import { HashRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import HomePage from './pages/Homepage';
import EnquiryPage from './pages/EnquiryPage';
import Navbar from './components/NavBar';
import './App.css';
// Import your loading gif. Adjust path as needed:
import loadingGif from './images/logo1.gif'; 

import BlogDetail from './pages/BlogDetail';
import BlogPage from './pages/BlogPage';
import WhatsAppButton from './components/WhatsAppButton';
import ServicePage from './pages/ServicePage';
import Safety from './components/Safety';
import PRPPage from './pages/PRPPage';
import WeightLossPage from './pages/WeightLossPage';
import TherapyPage from './pages/TherapyPage';
import BloodTestsPage from './pages/BloodTestPage';
import ExosomesPage from './pages/ExosomesPage';
import PrivateGPPage from './pages/PrivateGPPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import BloodTestsHairJourney from './QUESTIONNAIRES/PRP/BloodTestsHairJourney';
import BloodTestsJourney from './QUESTIONNAIRES/BLOOD/BloodTestsJourney';
import GpJourney from './QUESTIONNAIRES/GP/GPJourney';
import WeightLossJourney from './QUESTIONNAIRES/WEIGHT/WeightLossJourney';
import CardsRow from './components/CardsRow';
import PrpMultiAreaJourney from './QUESTIONNAIRES/PRP/PrpMultiAreaJourney';
import BloodTesting from './QUESTIONNAIRES/BLOOD/BloodTesting';
import GPPackages from './QUESTIONNAIRES/GP/GPPackages';
import HairPackages from './QUESTIONNAIRES/HAIRPRODUCTS/HairPackages';
import LearnMoreGLP1 from './components/LearnMore/LearnMoreGLP1';
import LearnMoreHormone from './components/LearnMore/LearnMoreHormone';
import LearnMoreBiomarker from './components/LearnMore/LearnMoreBiomarker';
import LearnMoreTherapy from './components/LearnMore/LearnMoreTherapy';
import LearnMoreExosome from './components/LearnMore/LearnMoreExosome';
import LandingPage from './LandingPages/LandingPage';
import BloodLanding from './LandingPages/BloodLanding';
import PRPbuy from './QUESTIONNAIRES/PRP/PRPbuy';

// Import the popup modal component
import PopupDiscountModal from './components/PopupDiscountModal';

// If you still use this context for other transitions, keep it:
export const TransitionContext = createContext();

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // 1) Are we loading? (controls fade-out)
  const [isLoading, setIsLoading] = useState(true);
  // 2) Should the overlay be shown in the DOM?
  const [overlayVisible, setOverlayVisible] = useState(true);

  // Simulate a loading period (2s). Replace with real logic if needed.
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // This triggers the fade-out
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  // Once fade-out completes, remove from DOM.
  const handleOverlayTransitionEnd = () => {
    if (!isLoading) {
      setOverlayVisible(false);
    }
  };

  // Disable scrolling while overlay is visible,
  // re-enable when overlay is hidden.
  useEffect(() => {
    if (overlayVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [overlayVisible]);

  const triggerTransition = (path) => {
    navigate(path);
  };


  return (
   <TransitionContext.Provider value={{ triggerTransition }}>
    {/* 1) Render the overlay only if 'overlayVisible' is true */}
    {overlayVisible && (
      <div
        className={`loading-overlay ${!isLoading ? 'fade-out' : ''}`}
        onTransitionEnd={handleOverlayTransitionEnd}
      >
          <img src={loadingGif} alt="Loading..." className='logoloading' />
        </div>
      )}

      {/* 2) The Actual App (behind the overlay if still loading) */}
      <Navbar />
      {/* Always rendered, so it checks localStorage on every app load */}
      <PopupDiscountModal />

      <div className="homepage">
        <Routes location={location}>
          <Route path="/" element={<HomePage />} />
          <Route path="/blood-test-packages" element={<BloodTesting />} />
          <Route path="/gp-packages" element={<GPPackages />} />
          <Route path="/hair-packages" element={<HairPackages />} />
          <Route path="/ad-landing" element={<LandingPage />} />
          <Route path="/bloods-ad-landing" element={<BloodLanding />} />
          <Route path="/cr" element={<CardsRow />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/enquiry" element={<EnquiryPage />} />
          <Route path="/prp-journey" element={<PrpMultiAreaJourney />} />
          <Route path="/weight-loss" element={<WeightLossJourney />} />
          <Route path="/gp-journey" element={<GpJourney />} />
          <Route path="/blood-tests" element={<BloodTestsJourney />} />
          <Route path="/wellness-services" element={<ServicePage />} />
          <Route path="/safety" element={<Safety />} />
          <Route path="/privategp" element={<PrivateGPPage />} />
          <Route path="/weightloss" element={<WeightLossPage />} />
          <Route path="/therapy-info" element={<TherapyPage />} />
          <Route path="/bloodtests" element={<BloodTestsPage />} />
          <Route path="/exosome" element={<ExosomesPage />} />
          <Route path="/prp" element={<PRPPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/blood-tests-hair-journey" element={<BloodTestsHairJourney />} />
          <Route path="/learn-more/glp-1-injections" element={<LearnMoreGLP1 />} />
          <Route path="/learn-more/hormone-test" element={<LearnMoreHormone />} />
          <Route path="/learn-more/biomarker-blood-screen" element={<LearnMoreBiomarker />} />
          <Route path="/learn-more/biomarker-blood-screen" element={<LearnMoreBiomarker />} />
          <Route path="/learn-more/personalised-therapy" element={<LearnMoreTherapy />} />
          <Route path="/learn-more/exosome-treatment" element={<LearnMoreExosome />} />
          <Route path="/payment-success" element={<BloodTestsHairJourney />} />
          <Route path="/prp-packages" element={<PRPbuy />} />
        </Routes>
      </div>

      <WhatsAppButton />
    </TransitionContext.Provider>
  );
};

export default App;
