// src/components/ServicePage.js
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import './ServicePage.css'; 
import SEO from '../components/SEO'; 

import gpAppointmentImage from '../images/steth.png';
import blood from '../images/blood.png';
import weightLossImage from '../images/ozempic.png';
import therapyImage from '../images/therapy.png';
import bloodTestsImage from '../images/blood2.png';
import exosomesImage from '../images/exosome1.png';

const ServicePage = () => {
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const placeholders = [
    { text: 'PRP', color: '#0048a6' },
    { text: 'Private GP Appointment', color: '#946b87' },
    { text: 'Exosomes', color: '#af921a' },
    { text: 'Weight Loss', color: '#6eb4cf' },
    { text: 'Therapy', color: '#9d1918' },
    { text: 'Blood Tests', color: '#5b8275' },
  ];

  const features = [
    {
      title: 'Blood & Hormone Tests',
      image: bloodTestsImage,
      link: '/bloodtests', // Route to your BloodTestsPage
      description: 'Access comprehensive blood & hormone tests in London for accurate results.',
    },
    {
      title: 'Private GP',
      image: gpAppointmentImage,
      link: '/privategp', // Route to your PrivateGPPage
      description: 'Get same-day private GP appointments in London for prompt care.',
    },
    {
      title: 'Platelet-rich plasma (PRP)',
      image: blood,
      link: '/prp', // Route to your PRPPage
      description: 'Experience regenerative treatments with PRP therapy in London.',
    },
    {
      title: 'Weight Loss',
      image: weightLossImage,
      link: '/weightloss', // Route to your WeightLossPage
      description: 'Explore personalized weight loss solutions tailored to your needs.',
    },
    // {
    //   title: 'Therapy',
    //   image: therapyImage,
    //   link: '/therapy-info', // Route to your TherapyPage
    //   description: 'Book therapy sessions with qualified professionals in London.',
    // },
    // {
    //   title: 'Exosomes',
    //   image: exosomesImage,
    //   link: '/exosome', // Route to your ExosomesPage
    //   description: 'Discover advanced exosome treatments for wellness and recovery.',
    // },
  ];

  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  // Placeholder text animation
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
      }, 400);
      setTimeout(() => {
        setIsAnimating(false);
      }, 800);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // Intersection Observer for animations
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          entries[0].target.classList.add('in-view-page');
          observer.unobserve(entries[0].target);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

// Example handleFeatureClick
const handleFeatureClick = (link) => {
    // link might be "/services/private-gp", "/services/weight-loss", etc.
    navigate(link);
  };
  

  return (
    <>
      {/* General SEO for the Services page listing */}
      <SEO
        title="Wellness Services | Private GP, Blood Tests & PRP in London"
        description="Discover our range of wellness services including same-day private GP appointments, comprehensive blood tests in London, platelet-rich plasma (PRP) therapies, weight loss programs, and more."
        keywords="private gp london, blood tests in london, prp therapy, exosomes, therapy, weight loss"
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalClinic",
            "name": "Wellness Services",
            "description":
              "Offering private GP appointments, blood tests, platelet-rich plasma (PRP) treatments, weight loss programs, therapy sessions, and exosomes treatments in London.",
            "url": "https://www.example.com/services",
            "image": "https://www.example.com/images/services.png",
            "medicalSpecialty": [
              "GeneralPractice",
              "PathologyTest",
              "RegenerativeMedicine"
            ],
            "availableService": [
              {
                "@type": "MedicalTest",
                "name": "Blood Tests",
                "availableOnDemand": true
              },
              {
                "@type": "MedicalTherapy",
                "name": "Platelet-Rich Plasma (PRP) therapy",
                "availableOnDemand": true
              },
              {
                "@type": "MedicalTest",
                "name": "Private GP Appointment",
                "availableOnDemand": true
              }
            ],
            "areaServed": "London, UK"
          })}
        </script>
      </SEO>

      <div className="how-it-works-container-page" ref={containerRef}>
        <div className="text-container-page">
          <h1
            className={`placeholder-text-page ${isAnimating ? 'animate-page' : ''}`}
            style={{ color: placeholders[currentPlaceholderIndex].color }}
          >
            {placeholders[currentPlaceholderIndex].text}
          </h1>
          <h1 className="personalised-text-page">personalised to you</h1>
        </div>
        <div className="services-scroll-container-page">
          <div className="features-grid-page">
            {features.map((feature, index) => (
              <div 
                className="feature-card-page" 
                key={index} 
                onClick={() => handleFeatureClick(feature.link)}
              >
                <div className="feature-content-page">
                  <div className="feature-text-page">
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                  </div>
                  <div className="feature-image-container-page">
                    <img
                      src={feature.image}
                      alt={`${feature.title} in London`}
                      className="feature-image-page"
                    />
                    <span className="arrow-icon-page">&gt;</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePage;
