// src/pages/TermsOfService.js

import React, { useEffect } from 'react';
import './PrivacyPolicy.css'; // Using the same CSS file for similar styling
import SEO from '../components/SEO';

const TermsOfService = () => {
  // Scroll to top on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title="Terms of Service | The Wellness London"
        description="Read the Terms of Service governing the use of The Wellness London's website and services, including disclaimers and legal protections for our clinic."
        keywords="terms of service, clinic terms, disclaimers, The Wellness London"
      />

      <div className="privacy-page">
        <div className="animated-bg-wave2" />
        <div className="privacy-container">
          <h1>Terms of Service</h1>
          <p>
            These Terms of Service (<strong>“Terms”</strong>) govern your use
            of all online and offline services provided by{' '}
            <strong>The Wellness London</strong> (<strong>“we”</strong>,{' '}
            <strong>“us”</strong>, <strong>“our”</strong>), including but not
            limited to our website (
            <strong>thewellnesslondon.com</strong>), mobile/online
            questionnaires, subdomains, direct communications, and any
            associated appointments or treatments (collectively, the{' '}
            <strong>“Services”</strong>).
          </p>
          <p>
            By accessing or using our Services, you (<strong>“user”</strong> or{' '}
            <strong>“you”</strong>) acknowledge that you have read, understood,
            and agree to be legally bound by these Terms. If you do not agree,
            you must discontinue use of our Services.
          </p>

          {/* 1. Overview of Services */}
          <h2>1. Overview of Services</h2>
          <p>
            The Wellness London is a UK-based clinic offering <strong>PRP</strong> treatments, 
            <strong> private GP appointments</strong>, <strong>comprehensive blood tests</strong>, 
            <strong> hormone panels</strong>, and the sale of prescription medications 
            (subject to physician approval). We strive to provide safe, professional, and 
            compliant health and wellness services in accordance with UK law.
          </p>

          {/* 2. Not a Substitute for Emergency Care */}
          <h2>2. Not a Substitute for Emergency Care</h2>
          <p>
            Our Services are not intended to replace emergency medical care. If you
            experience severe symptoms or believe you are in an emergency situation,
            please call 999 or go to the nearest hospital emergency department
            immediately.
          </p>

          {/* 3. Eligibility & User Responsibilities */}
          <h2>3. Eligibility &amp; User Responsibilities</h2>
          <p>
            <strong>(a) Medical Accuracy:</strong> To receive safe and effective care, 
            you must provide accurate, complete, and up-to-date information about your
            health history, current medications, and any other relevant details.
            Failure to do so may limit our ability to provide appropriate treatments or
            diagnoses, and we disclaim liability arising from incomplete or inaccurate
            disclosures.
          </p>
          <p>
            <strong>(b) Compliance with Instructions:</strong> You agree to
            follow pre-treatment and post-treatment instructions, medication regimens, 
            and any advice provided by our qualified practitioners. You acknowledge that 
            non-compliance with medical advice, including the misuse of prescription 
            medications, can pose significant health risks, and we disclaim liability
            for adverse outcomes arising from your non-compliance.
          </p>
          <p>
            <strong>(c) Age Requirements:</strong> You must be at least 18 years of
            age or have the valid permission of a parent or legal guardian to
            access certain Services. By using our Services, you represent and
            warrant that you meet this requirement.
          </p>
          <p>
            <strong>(d) Right to Refuse Service:</strong> We reserve the right to refuse
            appointments, treatments, or prescriptions if we determine, at our sole
            discretion, that doing so is necessary for legal, safety, or ethical reasons.
            This includes situations where providing treatment would contradict medical
            standards, or where your conduct breaches these Terms or applicable laws.
          </p>

          {/* 4. Medical Disclaimers */}
          <h2>4. Medical Disclaimers</h2>
          <p>
            <strong>(a) General Information Only:</strong> The content we provide 
            (online or offline) is for general informational purposes. While we strive 
            to offer accurate and up-to-date information, it should not be treated 
            as a substitute for direct consultation with a qualified medical practitioner.
          </p>
          <p>
            <strong>(b) No Guaranteed Results:</strong> Outcomes from PRP therapies, 
            blood tests, hormone panels, and other treatments vary among individuals. 
            We do not guarantee specific results or timelines. Each person’s health 
            condition is unique, and numerous factors may affect the success of 
            treatments or medications prescribed.
          </p>
          <p>
            <strong>(c) Prescription Medications:</strong> Any prescription medication 
            we provide is subject to review and authorization by a qualified physician. 
            We reserve the right to decline issuing a prescription if the physician 
            believes it is inappropriate or unsafe based on medical judgment and 
            applicable regulations.
          </p>

          {/* 5. Intellectual Property */}
          <h2>5. Intellectual Property</h2>
          <p>
            All content, including text, graphics, images, logos, and software,
            made available through our Services is the intellectual property of
            The Wellness London or its respective licensors. You agree not to
            reproduce, duplicate, modify, distribute, or create derivative works
            from any content without our express written permission, except
            where otherwise allowed by mandatory law.
          </p>

          {/* 6. Payment & Refund Policy */}
          <h2>6. Payment &amp; Refund Policy</h2>
          <p>
            <strong>(a) Payment Terms:</strong> Fees for consultations,
            treatments, or prescription medications must be paid in accordance
            with the rates displayed or communicated to you prior to purchase or
            booking.
          </p>
          <p>
            <strong>(b) Refunds:</strong> Due to the nature of medical services 
            and prescription items, refunds will be offered only under limited 
            circumstances, at our sole discretion, and in compliance with applicable 
            consumer protection laws.
          </p>

          {/* 7. Limitation of Liability */}
          <h2>7. Limitation of Liability</h2>
          <p>
            <strong>(a) No Liability for Certain Damages:</strong> To the maximum
            extent permitted by law, we shall not be liable for any indirect,
            incidental, consequential, or punitive damages arising from or in
            connection with your use of our Services, including but not limited
            to lost profits, personal injury, or emotional distress, unless such
            damages result from our willful misconduct or gross negligence.
          </p>
          <p>
            <strong>(b) Third-Party Services:</strong> Our Services may involve 
            referrals or links to third-party practitioners, labs, or websites 
            not controlled by us. We disclaim liability for the acts, omissions, 
            or privacy policies of such third parties.
          </p>
          <p>
            <strong>(c) Force Majeure &amp; Cybersecurity:</strong> We disclaim 
            liability for disruptions or losses caused by events outside our 
            reasonable control, including natural disasters, strikes, or advanced 
            cyberattacks.
          </p>

          {/* 8. Indemnification */}
          <h2>8. Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless The Wellness 
            London, its officers, directors, employees, affiliates, and agents 
            from any and all claims, liabilities, damages, losses, or expenses 
            (including reasonable legal fees) arising out of or in connection with: 
            (a) your breach of these Terms; (b) your violation of any law or the 
            rights of a third party; or (c) your misuse of our Services.
          </p>

          {/* 9. Changes to Terms */}
          <h2>9. Changes to These Terms</h2>
          <p>
            We reserve the right to update or modify these Terms at any time. 
            If we make significant changes, we will notify you by email (if 
            available) or by posting a conspicuous notice on our website. Your 
            continued use of our Services after the changes become effective 
            constitutes your acceptance of the revised Terms.
          </p>

          {/* 10. Governing Law & Dispute Resolution */}
          <h2>10. Governing Law &amp; Dispute Resolution</h2>
          <p>
            <strong>(a) Governing Law:</strong> These Terms shall be governed by 
            and construed in accordance with the laws of England and Wales, 
            without regard to conflict-of-law principles.
          </p>
          <p>
            <strong>(b) Jurisdiction &amp; Venue:</strong> You agree that any 
            legal action or proceeding arising from these Terms or your use of 
            our Services shall be brought exclusively in the courts of England 
            and Wales, and you consent to the personal jurisdiction of such courts.
          </p>
          <p>
            <strong>(c) Alternative Dispute Resolution:</strong> Where required 
            by law, you may have the option to seek dispute resolution via 
            an approved ADR scheme or mediator prior to initiating legal proceedings.
          </p>

          {/* 11. Severability */}
          <h2>11. Severability</h2>
          <p>
            If any provision of these Terms is found to be invalid or
            unenforceable by a court of competent jurisdiction, the remaining
            provisions shall remain in full force and effect, and the invalid
            provision shall be deemed modified to the extent necessary to make
            it valid and enforceable.
          </p>

          {/* 12. No Waiver */}
          <h2>12. No Waiver</h2>
          <p>
            Our failure or delay in exercising any right, remedy, or privilege
            under these Terms shall not operate as a waiver of that right,
            remedy, or privilege. Any waiver must be in writing and signed by an
            authorized representative of The Wellness London.
          </p>

          {/* 13. Entire Agreement */}
          <h2>13. Entire Agreement</h2>
          <p>
            These Terms, together with our <strong>Privacy Policy</strong> and
            any additional written agreements you enter into with us, constitute
            the entire agreement between you and The Wellness London regarding
            your use of our Services, superseding any prior oral or written
            understandings.
          </p>

          {/* 14. Contact Us */}
          <h2>14. Contact Us</h2>
          <p>
            If you have questions or concerns regarding these Terms or our
            Services, please contact us at:
          </p>
          <p>
            <strong>The Wellness London</strong>
            <br />
            Unit 4, 10 Portman Square, W1H 6AZ<br />
            London, UK<br />
            <a href="mailto:team@thewellnesslondon.com">team@thewellnesslondon.com</a>
            <br />
            Phone: +44 (0) 7399 323620
          </p>

          <p className="closing-note">
            Thank you for reading our Terms of Service. By continuing to use 
            The Wellness London’s Services, you acknowledge your agreement 
            to these Terms in full.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
