// src/components/Navbar.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../images/logo7.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Routes that show NO navbar at all (returns null).
  const hiddenRoutes = [
    '/loading',
    '/prp-options',
    '/prp-hair-goals',
    '/prp-hair-changes',
    '/prp-hair-loss-amount',
    '/prp-hair-loss-time',
    '/prp-hair-expectations',
    '/prp-hair-type',
    '/prp-hair-length',
    '/hair-analysis-result',
    '/therapy',
    '/therapy-primary-concern',
    '/therapy-duration-symptoms',
    '/therapy-impact-daily-life',
    '/therapy-previous-therapy',
    '/therapy-cta',
    '/weight-loss',
    '/weight-loss-health-metrics',
    '/weight-loss-goals',
    '/weight-loss-dietary-habits',
    '/weight-loss-exercise-routine',
    '/weight-loss-medical-history',
    '/weight-loss-cta',
    '/private-gp',
    '/gp-reason-appointment',
    '/gp-acute-illness',
    '/gp-chronic-condition',
    '/gp-medical-history',
    '/gp-lifestyle-factors',
    '/gp-mode-appointment',
    '/gp-cta',
    '/blood-tests',
    '/blood-tests-reason',
    '/blood-tests-symptoms',
    // '/blood-tests-medical-history', <-- removed from hidden
    '/blood-tests-cta',
    '/exosomes',
    '/exosomes-treatment-goals',
    '/exosomes-medical-history',
    '/exosomes-cta',
    '/prp-joints',
    '/joint-pain-diagnosis',
    '/skin-rejuvenation',
    '/skin-previous-treatments',
  ];

  // Routes that should show the special "centered logo" header
  const hairJourneyRoutes = [
    '/blood-tests-hair-journey',
    '/blood-tests',
    '/gp-journey',
    '/weight-loss',
    '/therapy',
    '/prp-journey'
  ];

  const isBloodTestsMedicalHistory = location.pathname === '/blood-tests-medical-history';
  const isHairJourneyRoute = hairJourneyRoutes.includes(location.pathname);
  const isNavbarHidden = hiddenRoutes.includes(location.pathname);

    // **Logo** should navigate to "/" and scroll top
    const handleHomeClick = () => {
      setIsMenuOpen(false);
      navigate('/');
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  // Disable body scroll if mobile menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  // 1) If route in hiddenRoutes (and not special route), hide navbar
  if (isNavbarHidden && !isHairJourneyRoute && !isBloodTestsMedicalHistory) {
    return null;
  }

  // 2) Special "centered-logo" navbar for hairJourneyRoutes
  if (isHairJourneyRoute) {
    return (
      <div className="bar blood-tests-header">
        <div className="centered-logo" onClick={handleHomeClick}>
          <img
            src={logo}
            alt="Moccet Logo"
            className="logo-image"
          />
        </div>
      </div>
    );
  }

  // Otherwise, standard navbar
  const handleBookNowClick = () => {
    setIsMenuOpen(false);
    navigate('/enquiry');
  };
  const handleBlogClick = () => {
    setIsMenuOpen(false);
    navigate('/blog');
  };
  const handleServicesClick = () => {
    setIsMenuOpen(false);
    navigate('/wellness-services');
  };

  return (
    <div className="bar">
      <div className={`navbar ${isBloodTestsMedicalHistory ? 'small-navbar' : ''}`}>
        {/* LOGO that goes to "/" and scrolls to top */}
        <div className="logo" onClick={handleHomeClick}>
          <img
            src={logo}
            alt="Moccet Logo"
            className="logo-image"
          />
        </div>

        <div className="mobile-menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? (
            <span className="close-icon">&times;</span>
          ) : (
            <span className="hamburger-icon">&#9776;</span>
          )}
        </div>

        <div className={`links-container ${isMenuOpen ? 'open' : ''}`}>
          <div className={`links ${isMenuOpen ? 'mobile-dropdown' : ''}`}>
            <p className="nav-link" onClick={handleBlogClick}>Blog</p>
            <p className="nav-link" onClick={handleServicesClick}>Services</p>
          </div>
          <p
            className="send-nuqoot-btn nav-link1"
            onClick={handleBookNowClick}
          >
            Book Now
          </p>
        </div>
      </div>
    </div>
  );
};
export default Navbar;