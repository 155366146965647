import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";  // <-- Import the hook
import "./GradientCard.css";

function Card({ gradientClass, title, subTitle, navigateTo }) {
  const cardRef = useRef(null);
  const [transformStyle, setTransformStyle] = useState("");
  const navigate = useNavigate();

  const handleMouseMove = (e) => {
    if (!cardRef.current) return;
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Calculate rotation based on mouse position
    const rotateX = 10 * ((y - rect.height / 2) / rect.height);
    const rotateY = -10 * ((x - rect.width / 2) / rect.width);

    setTransformStyle(`
      perspective(1000px)
      rotateX(${rotateX}deg)
      rotateY(${rotateY}deg)
      scale(1.03)
    `);
  };

  const handleMouseLeave = () => {
    // Reset transform
    setTransformStyle("");
  };

  const handleClick = () => {
    // Navigate to the assigned route
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  return (
    <div
      className={`card1 ${gradientClass}`}
      ref={cardRef}
      style={{ transform: transformStyle }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}             // <-- Click event
    >
      <div className="card-content1">
        {title} <br />
        <strong>{subTitle}</strong>
      </div>
      <div className="arrow-circle">
        <span className="arrow">→</span>
      </div>
    </div>
  );
}

export default function CardComponent() {
  return (
    <div className="card-container1">
      {/* First (Pink) Card */}
      <Card
        gradientClass="pink-card"
        title="The Importance of"
        subTitle="Hormone Tests"
        navigateTo="/learn-more/hormone-test"        // <-- Route to navigate
      />

      {/* Second (Green/Blue) Card */}
      <Card
        gradientClass="greenblue-card"
        title="Why GLP-1s are the secret to"
        subTitle="losing weight"
        navigateTo="/learn-more/glp-1-injections"    // <-- Route to navigate
      />
    </div>
  );
}
