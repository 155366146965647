// src/components/LearnMore/LearnMoreHormone.js
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import SEO from '../SEO';
import './LearnMorePage.css';
import hormoneImage from '../../images/ivf.png'; // <--- Replace with your hormone-related image

const LearnMoreHormone = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <article className="learn-more-container">
      <SEO
        title="Hormone Blood Test – The Wellness London"
        description="Discover how our Hormone Blood Test can identify imbalances, guide personalized treatments, and support optimal well-being at The Wellness London."
        keywords="hormone blood test, hormone imbalance, hormone panel, thyroid function, hormone therapy, The Wellness London"
      />

      <motion.header
        className="learn-more-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h1>Hormone Blood Test</h1>
        <p className="subheading">Balance Your Body, Elevate Your Wellness</p>
      </motion.header>

      <motion.section
        className="learn-more-hero"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2, duration: 0.6, ease: 'easeOut' }}
      >
        {/* Replace this with an appropriate hormone-related image */}
        <img 
          src={hormoneImage} 
          alt="Hormone Blood Test representation" 
          className="learn-more-image" 
        />
      </motion.section>

      <motion.section
        className="learn-more-content"
        initial={{ opacity: 0, y: 30 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <h2>What Is a Hormone Blood Test?</h2>
        <p>
          Our <strong>Hormone Blood Test</strong> is a targeted screening designed to 
          measure key hormones that influence everything from metabolism and energy levels 
          to mood and reproductive health. Imbalanced hormones can contribute to issues such 
          as unexplained weight changes, fatigue, mood swings, and more.
        </p>

        <h2>How It Works</h2>
        <p>
          A small blood sample is taken by our qualified healthcare professionals and analyzed 
          for hormonal markers such as thyroid hormones, sex hormones, cortisol, and other 
          important indicators. By evaluating these levels, our team can pinpoint potential 
          imbalances and suggest evidence-based interventions to restore optimal hormone 
          function.
        </p>

        <h2>Why Test Your Hormones?</h2>
        <p>
          Hormones play a vital role in regulating numerous bodily processes, including 
          metabolism, mood stability, sleep patterns, and reproductive function. Identifying 
          imbalances through a <strong>Hormone Blood Test</strong> allows us to:
        </p>
        <ul>
          <li>Address symptoms such as persistent fatigue or unexplained weight changes.</li>
          <li>Support conditions like hypothyroidism, PCOS, or perimenopause.</li>
          <li>Guide personalized treatment plans for hormone optimization.</li>
          <li>Enhance overall well-being and vitality.</li>
        </ul>

        <h2>Important Safety Information</h2>
        <p>
          Similar to a standard blood draw, a hormone test carries minimal risks such as mild 
          bruising or discomfort at the injection site. Our protocols adhere to strict safety 
          standards, ensuring a sterile and comfortable experience. Should your hormone levels 
          indicate potential concerns, our team can discuss possible next steps, including 
          lifestyle modifications or medical treatments.
        </p>

        <h2>Next Steps</h2>
        <p>
          Ready to unveil what your hormones are telling you about your health? <strong>Schedule 
          a Hormone Blood Test</strong> with The Wellness London and explore a proactive approach 
          to living your best life. With expert guidance and personalized solutions, you’ll gain 
          greater clarity and control over your well-being.
        </p>
      </motion.section>

      <motion.footer
        className="learn-more-footer"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
      >
        <button 
          className="primary-cta" 
          onClick={() => (window.location.href = '#/blood-tests')}
        >
          Get Started
        </button>
        <button 
          className="secondary-cta" 
          onClick={() => (window.location.href = '#/enquiry')}
        >
          Contact Us
        </button>
      </motion.footer>
    </article>
  );
};

export default LearnMoreHormone;
