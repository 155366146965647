import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../PRP/BloodTestsHairJourney.css';
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';

const BloodTestsJourney = () => {
  // -------------------------------------------------------------------------
  // 1) PAGE & ANSWERS STATE
  // -------------------------------------------------------------------------
  // Page 0..13 => total 14 pages
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = 14;

  // Existing answers from original code
  const [answers, setAnswers] = useState({
    reason: null,
    symptoms: null,
    stressLevel: null,
    pastTests: null,
    goal: null,
    budget: null,
    advancedHormones: null,
    contactMethod: null,
    fullName: '',
    email: '',
    phone: '',
  });

  // 2) PROGRESS BAR & "thinking" overlay
  const [progress, setProgress] = useState(0);
  const [showThinking, setShowThinking] = useState(false);

  // 3) Summaries (pages 10, 11, 12)
  const [summaryProse, setSummaryProse] = useState('');
  const [planSuggestions, setPlanSuggestions] = useState('');
  const [finalText, setFinalText] = useState('');

  // 4) SUBMISSION STATES
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  // 5) React Router
  const navigate = useNavigate();

  // -------------------------------------------------------------------------
  // UTILITY: Navigate pages, store answers, update progress
  // -------------------------------------------------------------------------
  const goToPage = (num) => {
    setCurrentPage(num);
    updateProgressBar(num);
  };

  const storeAnswer = (key, value) => {
    setAnswers((prev) => ({ ...prev, [key]: value }));
    console.log('Storing Answer:', key, value);
  };

  const updateProgressBar = (targetPage) => {
    // We have pages 0..13 => 14 total
    const fraction = targetPage / (totalPages - 1);
    const percentage = Math.min(100, fraction * 100);
    setProgress(percentage);
  };

  // -------------------------------------------------------------------------
  // PAGE FLOW: Budget => page9, then short thinking => summary
  // -------------------------------------------------------------------------
  const chooseBudget = (value) => {
    storeAnswer('budget', value);
    goToPage(9);
  };

  const goToSummary = () => {
    setShowThinking(true);
    setTimeout(() => {
      setShowThinking(false);
      buildProseSummary(); // build final summary for page10
      goToPage(10);
    }, 2000);
  };

  // -------------------------------------------------------------------------
  // (PAGE 6) fillMidReviewBox
  // -------------------------------------------------------------------------
  const fillMidReviewBox = () => {
    const reasonMap = {
      fatigue: 'Ongoing Fatigue / Low Energy',
      moodSwings: 'Mood Changes',
      generalHealth: 'General Health Check',
      other: 'Other Health Concern',
    };
    const symptomsMap = {
      fatigue: 'Chronic Fatigue',
      lowLibido: 'Low Libido',
      hairLoss: 'Hair Loss',
      weightChanges: 'Weight Fluctuations',
      none: 'None / Unsure',
    };
    const reasonStr = reasonMap[answers.reason] || 'No reason specified yet';
    const symptomsStr = symptomsMap[answers.symptoms] || 'None / Unsure';
    const stressStr = answers.stressLevel || 'Uncertain stress frequency';

    let pastTestsStr = 'Unclear test history';
    if (answers.pastTests === 'yes') {
      pastTestsStr = 'Recent blood tests (within 6-12 months)';
    } else if (answers.pastTests === 'longAgo') {
      pastTestsStr = 'Blood tests over a year ago';
    } else if (answers.pastTests === 'no') {
      pastTestsStr = 'No prior blood tests';
    }

    return (
      <>
        <strong>Key Concern:</strong> {reasonStr}
        <br />
        <strong>Symptoms:</strong> {symptomsStr}
        <br />
        <strong>Stress &amp; Lifestyle:</strong> {stressStr}
        <br />
        <strong>Past Tests:</strong> {pastTestsStr}
      </>
    );
  };

  // -------------------------------------------------------------------------
  // BUILD RECOMMENDATIONS
  // -------------------------------------------------------------------------
  const getDynamicTestRecommendations = () => {
    const { reason, symptoms, advancedHormones } = answers;
    const recommended = [];

    // Example logic from your original snippet
    if (reason === 'fatigue' || symptoms === 'fatigue' || symptoms === 'weightChanges') {
      recommended.push('Fatigue & Energy Panel (Iron, B12, Thyroid, Ferritin, etc.)');
    }
    if (symptoms === 'hairLoss') {
      recommended.push('Hair Loss Panel (Ferritin, Vitamin D, Thyroid, Hormone Levels)');
    }
    if (advancedHormones === 'yes') {
      recommended.push('Advanced Hormone Mapping (cortisol, extended thyroid, fertility markers, etc.)');
    }

    if (recommended.length === 0) {
      recommended.push('Comprehensive Basic Panel (Full Blood Count, Iron, Vitamin D, etc.)');
    }
    return recommended;
  };

  // -------------------------------------------------------------------------
  // BUILD PROSE SUMMARY (PAGE 10)
  // -------------------------------------------------------------------------
  const buildProseSummary = () => {
    let reasonText;
    switch (answers.reason) {
      case 'fatigue':
        reasonText = 'fatigue or low energy';
        break;
      case 'moodSwings':
        reasonText = 'mood changes';
        break;
      case 'generalHealth':
        reasonText = 'a general check-up';
        break;
      default:
        reasonText = 'some other health concern';
    }

    let symptomText;
    switch (answers.symptoms) {
      case 'fatigue':
        symptomText = 'chronic tiredness';
        break;
      case 'lowLibido':
        symptomText = 'reduced libido';
        break;
      case 'hairLoss':
        symptomText = 'hair loss concerns';
        break;
      case 'weightChanges':
        symptomText = 'weight fluctuations';
        break;
      default:
        symptomText = 'no specific extra symptoms';
    }

    const stressText = answers.stressLevel || 'unknown stress factors';

    let pastTestsText;
    if (answers.pastTests === 'yes') {
      pastTestsText = 'you’ve had labs in the last year';
    } else if (answers.pastTests === 'longAgo') {
      pastTestsText = 'you had tests more than a year ago';
    } else {
      pastTestsText = 'you’ve never done any major blood tests';
    }

    let goalText;
    switch (answers.goal) {
      case 'energyBoost':
        goalText = 'improving energy and overall vitality';
        break;
      case 'hormoneCheck':
        goalText = 'a deeper hormone check';
        break;
      case 'confirmSuspicions':
        goalText = 'verifying a suspicion (e.g., thyroid, testosterone)';
        break;
      default:
        goalText = 'gaining peace of mind';
    }

    const budgetText = answers.budget || 'no budget preference mentioned';
    const advancedHormonesText =
      answers.advancedHormones === 'yes'
        ? 'you’re open to a detailed hormone panel'
        : 'you prefer a simpler approach right now';

    const summaryText = `
      You came to us with concerns around <strong>${reasonText}</strong>,
      also mentioning <strong>${symptomText}</strong> while managing <strong>${stressText}</strong>
      in your day-to-day. We noted that <strong>${pastTestsText}</strong>, and your primary aim is
      <strong>${goalText}</strong>. You indicated a budget around <strong>${budgetText}</strong>,
      and <strong>${advancedHormonesText}</strong> if it aligns with your needs.<br><br>
      Based on this, you’re a strong candidate for testing at <strong>The Wellness</strong>.
      Click to see your personalised recommendation.
    `;
    setSummaryProse(summaryText);

    const recommendedList = getDynamicTestRecommendations();
    let recommendedHTML = '<ul>';
    recommendedList.forEach((test) => {
      recommendedHTML += `<li>${test}</li>`;
    });
    recommendedHTML += '</ul>';

    const planText = `
      <p style="margin-bottom: 0.5rem;"><strong>Potential Next Steps</strong></p>
      ${recommendedHTML}
      <p>
        Our team can fine-tune these based on your final preferences. 
        For some clients, we add inflammatory markers or advanced vitamin panels 
        if symptoms suggest it.
      </p>
    `;
    setPlanSuggestions(planText);
  };

  // -------------------------------------------------------------------------
  // FINAL SUBMISSION (PAGE 13)
  // -------------------------------------------------------------------------
  const finishJourney = async (e) => {
    e.preventDefault();

    const { fullName, email, phone, contactMethod } = answers;

    // If user picks "NoCall" -> skip Firestore & go to /blood-test-packages
    if (contactMethod === 'NoCall') {
      // 1) Post data to /api/slack so the server can handle Slack
      try {
        await fetch('/api/slack', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...answers,
            message: 'User selected "No call, just start now" - skipping Firestore',
          }),
        });
      } catch (err) {
        console.error('Error posting "NoCall" data to Slack:', err);
      }

      // 2) Then navigate away
      navigate('/blood-test-packages');
      return;
    }

    // If user wants contact => require name/email/phone
    if (!fullName || !email || !phone) {
      setError('Please provide your name, email, and phone so we can finalise your plan.');
      return;
    }

    // Store in Firestore (like your original code)
    try {
      await addDoc(collection(db, 'bloodJourneys'), {
        ...answers,
        timestamp: new Date(),
      });

      // Also post data to Slack
      try {
        await fetch('/api/slack', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            ...answers,
            message: 'User completed the full blood test journey with contact details',
          }),
        });
      } catch (slackErr) {
        console.error('Error posting final journey data to Slack:', slackErr);
      }

      // Mark as submitted
      setSubmitted(true);
      setFinalText(`
        Thank you, <strong>${fullName}</strong>. We’ve reserved your spot for blood & hormone testing. 
        We’ll confirm everything with you soon to ensure you get exactly the tests you need—nothing more, nothing less.
      `);
    } catch (err) {
      console.error('Error submitting blood journey data:', err);
      setError('Sorry, there was a problem submitting your information. Please try again.');
    }
  };

  // -------------------------------------------------------------------------
  // On page change => update progress
  // -------------------------------------------------------------------------
  useEffect(() => {
    updateProgressBar(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  // Helper: show/hide pages
  const pageIsActive = (pageNumber) =>
    currentPage === pageNumber ? 'form-page active' : 'form-page';

  // CONTACT BUTTON highlight
  const getContactButtonClass = (method) => {
    return answers.contactMethod === method ? 'choice-btn active' : 'choice-btn';
  };

  // -------------------------------------------------------------------------
  // RENDER
  // -------------------------------------------------------------------------
  return (
    <div className="outer-container">
      {/* PROGRESS BAR */}
      <div className="progress-bar-fixed">
        <div className="progress-wrap">
          <div className="progress-bar" style={{ width: progress + '%' }}></div>
        </div>
      </div>

      <div className="container fade-in">
        {/* PAGE 0: Let user pick "Personalise My Treatment" or "Book Test Now" */}
        <div className={pageIsActive(0)} id="page0">
          <h2>Welcome to The Wellness Blood Tests</h2>
          <p>
            Would you like a personalised treatment journey, or just book a test straight away?
          </p>
          <div style={{ marginTop: '2rem' }}>
            <button
              className="choice-btn"
              style={{ marginRight: '1rem' }}
              onClick={() => goToPage(1)}
            >
              Personalise My Treatment
            </button>
            <button
              className="choice-btn"
              onClick={() => navigate('/blood-test-packages')} 
            >
              Book Test Now
            </button>
          </div>
        </div>

        {/* PAGE 1: Intro */}
        <div className={pageIsActive(1)} id="page1">
          <h2 className="fadeInRightTitle">
            Unlock Your Best Self: A Tailored Blood &amp; Hormone Test Experience
          </h2>
          <p className="fadeInRightContent">
            At <strong>The Wellness</strong>, we uncover the hidden factors affecting your energy, mood, 
            and overall health. <br /><br />
            In just a few questions, we’ll pinpoint exactly what your body needs 
            and craft a test panel that delivers clear insights and real impact.
          </p>
          <div className="info-box fadeInRightContent">
            <p>
              <strong>Note:</strong> Some specialised or hormone-focused tests may need a quick review by our clinicians. 
              Don’t worry—if that applies, we’ll confirm everything with you afterwards.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(2)}>
            Start
          </button>
        </div>

        {/* PAGE 2: Key Reason for Testing */}
        <div className={pageIsActive(2)} id="page2">
          <h2 className="fadeInRightTitle">1. What Brings You Here?</h2>
          <p className="fadeInRightContent">
            Are you feeling low on energy, dealing with mood swings, or simply seeking a thorough health check?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('reason', 'fatigue');
                goToPage(3);
              }}
            >
              Ongoing Fatigue / Low Energy
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('reason', 'moodSwings');
                goToPage(3);
              }}
            >
              Mood Changes
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('reason', 'generalHealth');
                goToPage(3);
              }}
            >
              General Check-Up
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('reason', 'other');
                goToPage(3);
              }}
            >
              Other
            </button>
          </div>
          <div className="testimonial-bubble fadeInRightContent" style={{ animationDelay: '0.6s' }}>
            "I initially booked blood tests for persistent fatigue. The Wellness found 
             low iron and borderline thyroid levels—huge relief to finally know!
             <br />— <em>Sarah, Manchester</em>"
          </div>
        </div>

        {/* PAGE 3: Specific Symptoms */}
        <div className={pageIsActive(3)} id="page3">
          <h2 className="fadeInRightTitle">2. Any Specific Symptoms?</h2>
          <p className="info-box fadeInRightContent">
            Common signs include persistent tiredness, low libido, unexplained weight changes, or hair thinning.
            The more we know, the better we can customize your test panel.
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('symptoms', 'fatigue');
                goToPage(4);
              }}
            >
              Chronic Fatigue
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('symptoms', 'lowLibido');
                goToPage(4);
              }}
            >
              Low Libido
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('symptoms', 'hairLoss');
                goToPage(4);
              }}
            >
              Hair Loss
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('symptoms', 'weightChanges');
                goToPage(4);
              }}
            >
              Weight Fluctuations
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('symptoms', 'none');
                goToPage(4);
              }}
            >
              None / Unsure
            </button>
          </div>
        </div>

        {/* PAGE 4: Stress & Lifestyle */}
        <div className={pageIsActive(4)} id="page4">
          <h2 className="fadeInRightTitle">3. Stress &amp; Lifestyle</h2>
          <p className="fadeInRightContent">
            How frequently do stress, poor sleep, or diet gaps play a role in your day-to-day routine?
            These can influence hormone balance and overall lab results.
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'often');
                goToPage(5);
              }}
            >
              Often
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'sometimes');
                goToPage(5);
              }}
            >
              Sometimes
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('stressLevel', 'rarely');
                goToPage(5);
              }}
            >
              Rarely
            </button>
          </div>
          <div className="testimonial-bubble fadeInRightContent" style={{ animationDelay: '0.6s' }}>
            "Cutting back stress and adjusting supplements, as suggested by The Wellness test results, 
             completely changed my mood and energy. 
             <br />— <em>Tom, Leeds</em>"
          </div>
        </div>

        {/* PAGE 5: Past Blood Tests */}
        <div className={pageIsActive(5)} id="page5">
          <h2 className="fadeInRightTitle">4. Past Blood Tests?</h2>
          <p className="fadeInRightContent">
            Have you had any lab work in the last 6-12 months for hormones, vitamins, or a general check-up?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pastTests', 'yes');
                goToPage(6);
              }}
            >
              Yes, within 6-12 months
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pastTests', 'longAgo');
                goToPage(6);
              }}
            >
              Yes, but over a year ago
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('pastTests', 'no');
                goToPage(6);
              }}
            >
              No, never
            </button>
          </div>
        </div>

        {/* PAGE 6: Mid-Journey Review */}
        <div className={pageIsActive(6)} id="page6">
          <h2 className="fadeInRightTitle">Mid-Journey Check-In</h2>
          <p className="fadeInRightContent">Great job so far! Here’s a quick look at what we’ve noted:</p>
          <div className="info-box fadeInRightContent" style={{ fontSize: '15px' }}>
            {fillMidReviewBox()}
          </div>
          <p className="fadeInRightContent">
            Based on your responses, it sounds like you’re already a candidate for <strong>our advanced testing</strong> at 
            <strong> The Wellness</strong>. Next, we’ll gather a bit more about your goals, budget, and comfort with 
            deeper hormone panels. Then we’ll give you a personalised plan—carefully shaped by your specific 
            symptoms, lifestyle, and priorities.
          </p>
          <button className="btn fadeInRightContent" onClick={() => goToPage(7)}>
            Continue
          </button>
        </div>

        {/* PAGE 7: Goals / Focus */}
        <div className={pageIsActive(7)} id="page7">
          <h2 className="fadeInRightTitle">5. What’s Your Main Goal?</h2>
          <p className="fadeInRightContent">
            Are you trying to optimise energy levels, double-check hormones, confirm a suspicion, or simply get 
            peace of mind about your overall health?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('goal', 'energyBoost');
                goToPage(8);
              }}
            >
              Boost Energy / Vitality
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('goal', 'hormoneCheck');
                goToPage(8);
              }}
            >
              Deep Hormone Check
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('goal', 'confirmSuspicions');
                goToPage(8);
              }}
            >
              Confirm Suspicion (thyroid, low T, etc.)
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('goal', 'peaceOfMind');
                goToPage(8);
              }}
            >
              General Peace of Mind
            </button>
          </div>
        </div>

        {/* PAGE 8: Budget */}
        <div className={pageIsActive(8)} id="page8">
          <h2 className="fadeInRightTitle">6. Budget Range</h2>
          <p className="info-box fadeInRightContent">
            We aim to find the right balance between comprehensive testing and respecting your budget, 
            so you can get real answers without overspending.
          </p>
          <div className="choice-container fadeInRightContent">
            <button className="choice-btn" onClick={() => chooseBudget('<£100')}>
              Under £100
            </button>
            <button className="choice-btn" onClick={() => chooseBudget('£100-£200')}>
              £100 - £200
            </button>
            <button className="choice-btn" onClick={() => chooseBudget('£200+')}>
              £200+
            </button>
          </div>
        </div>

        {/* PAGE 9: Advanced Hormones */}
        <div className={pageIsActive(9)} id="page9">
          <h2 className="fadeInRightTitle">7. Comfort with More In-Depth Hormone Panels</h2>
          <p className="fadeInRightContent">
            Some advanced hormone tests (e.g., detailed thyroid, fertility markers, cortisol, sex hormones) 
            require extra samples or a bit more time in the clinic. Are you open to that for deeper insights?
          </p>
          <div className="choice-container fadeInRightContent">
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('advancedHormones', 'yes');
                goToSummary();
              }}
            >
              Yes, I want detailed hormone info
            </button>
            <button
              className="choice-btn"
              onClick={() => {
                storeAnswer('advancedHormones', 'no');
                goToSummary();
              }}
            >
              Keep it simpler for now
            </button>
          </div>
        </div>

        {/* THINKING OVERLAY */}
        {showThinking && (
          <div className="thinking-overlay" style={{ display: 'flex' }}>
            <h3>Analysing Your Responses...</h3>
            <p>One moment while we refine your personalised test panel.</p>
            <div className="spinner"></div>
          </div>
        )}

        {/* PAGE 10: Short Summary */}
        <div className={pageIsActive(10)} id="page10">
          <h2 className="fadeInRightTitle">Your Personalised Blood &amp; Hormone Overview</h2>
          <p
            id="summaryProse"
            className="fadeInRightContent"
            style={{ marginBottom: '16px' }}
            dangerouslySetInnerHTML={{ __html: summaryProse }}
          />
          <button className="btn fadeInRightContent" onClick={() => goToPage(11)}>
            Next
          </button>
        </div>

        {/* PAGE 11: Detailed Recommendations */}
        <div className={pageIsActive(11)} id="page11">
          <h2 className="fadeInRightTitle">Recommended Panels &amp; Next Steps</h2>
          <p
            id="planSuggestions"
            className="info-box fadeInRightContent"
            style={{ marginBottom: '16px' }}
            dangerouslySetInnerHTML={{ __html: planSuggestions }}
          />
          <div className="cta-block fadeInRightContent">
            <p style={{ fontSize: '12px', color: '#777', margin: '0' }}>
              Remember: you can often get tested on the same day you book, 
              and see initial results in as little as 1–3 days. Then, 
              you’ll enjoy a <strong>complimentary telehealth consult </strong> 
              with our doctors to interpret your results and customise next steps for your health goals.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(12)}>
            Next
          </button>
        </div>

        {/* PAGE 12: Why These Tests Matter */}
        <div className={pageIsActive(12)} id="page12">
          <h2 className="fadeInRightTitle">Why These Tests Matter</h2>
          <p className="fadeInRightContent">
            Most people ignore hidden imbalances until they become big problems—thyroid issues, hormone drops, 
            or vitamin deficiencies can sabotage your energy, mood, and even hair or skin health.
          </p>
          <p className="fadeInRightContent">
            By investing in targeted blood tests now, you’ll gain the clarity to take precise action—whether 
            that’s a small diet tweak or a treatment plan for deeper issues. 
            Armed with these results (and our doctor’s guidance), you’ll finally stop guessing and start thriving.
          </p>
          <div className="info-box fadeInRightContent">
            <p>
              Did you know? Minor iron or B12 deficiencies can mimic chronic fatigue, 
              and hormone imbalances can lead to stubborn weight gain or low mood. 
              Testing removes the guesswork and speeds up your path to better health.
            </p>
          </div>
          <button className="btn fadeInRightContent" onClick={() => goToPage(13)}>
            Final Step
          </button>
        </div>

        {/* PAGE 13: Contact & Final Submission */}
        <div className={pageIsActive(13)} id="page13">
          {!submitted && (
            <h2 className="fadeInRightTitle">Finalize Your Details</h2>
          )}

          {error && (
            <p style={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }} className="fadeInRightContent">
              {error}
            </p>
          )}

          {submitted && (
            <div className="success-message">
              <p className="success-title">
                Your Blood Testing Submission was successful!
              </p>
              <p
                id="finalPlanIntro"
                dangerouslySetInnerHTML={{ __html: finalText }}
              ></p>
            </div>
          )}

          {!submitted && (
            <>
              <p
                id="finalPlanIntro"
                className="fadeInRightContent"
                style={{ marginBottom: '16px' }}
              >
                Thank you for taking the time to answer our questions. 
                Just enter your details below to finalise your test panel and get started.
              </p>

              <form onSubmit={finishJourney} className="fadeInRightContent">
                <label htmlFor="fullName">
                  Full Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  required
                  value={answers.fullName}
                  onChange={(e) => storeAnswer('fullName', e.target.value)}
                />

                <label htmlFor="email">
                  Email <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={answers.email}
                  onChange={(e) => storeAnswer('email', e.target.value)}
                />

                <label htmlFor="phone">
                  Phone Number <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="+44 ..."
                  required
                  value={answers.phone}
                  onChange={(e) => storeAnswer('phone', e.target.value)}
                />

                <h3 style={{ marginTop: '15px' }}>Preferred Contact</h3>
                <div className="choice-container" style={{ marginBottom: 0 }}>
                  <button
                    className={getContactButtonClass('Email')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Email')}
                  >
                    Email
                  </button>
                  <button
                    className={getContactButtonClass('Call')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'Call')}
                  >
                    Call
                  </button>
                  <button
                    className={getContactButtonClass('WhatsApp')}
                    type="button"
                    onClick={() => storeAnswer('contactMethod', 'WhatsApp')}
                  >
                    WhatsApp
                  </button>

                  {/* If user clicks "No call, just start now" => route to /blood-test-packages plus Slack */}
                  <button
                    className={getContactButtonClass('NoCall')}
                    type="button"
                    onClick={async () => {
                      // store the choice
                      storeAnswer('contactMethod', 'NoCall');
                      // Post data to /api/slack
                      try {
                        await fetch('/api/slack', {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify({
                            ...answers,
                            contactMethod: 'NoCall',
                            message: 'User selected "No call" at final step - skipping Firestore submission',
                          }),
                        });
                      } catch (slackErr) {
                        console.error('Error posting "NoCall" to Slack:', slackErr);
                      }
                      // Then go to packages
                      navigate('/blood-test-packages');
                    }}
                  >
                    No call, just start now
                  </button>
                </div>

                <button className="btn" style={{ marginTop: '20px' }}>
                  Submit &amp; Access Your Personalised Plan
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BloodTestsJourney;
