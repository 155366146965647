import React from 'react';
import animationGif from '../images/canva.gif';

const SlowMotionGif = () => {
  return (
    <img
      src={animationGif}
      alt="Animated GIF"
      style={{ width: '100%', maxWidth: '1400px' }}
    />
  );
};

export default SlowMotionGif;
