import React, { useState, useEffect } from "react";
import "./HairBanner.css";
import { useNavigate } from "react-router-dom";

const HairBanner = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    navigate("/blood-tests-hair-journey");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  // The hero text (title + subtitle), rendered conditionally above or inside the background.
  const HeroTextBlock = (
    <div className="hero-text">
      <h1 className="hero-title">Stop Waiting. Reclaim Your Hair.</h1>
      <p className="hero-subtitle">
        74% wish they’d started sooner.* Skip the regret—begin now.
      </p>
    </div>
  );

  // The hero button stays inside the background image at the bottom in both layouts.
  const HeroButton = (
    <button className="hero-button" onClick={handleClick}>
      Begin Your Journey
    </button>
  );

  return (
    <>
      {/* If the screen is mobile, render hero text ABOVE the hero section */}
      {isMobile && <div className="hero-mobile-text">{HeroTextBlock}</div>}

      {/* Hero section with background image */}
      <section className="hero-section">
        <div className="hero-content">
          {/* If desktop, show hero text INSIDE the hero section; if mobile, skip it here */}
          {!isMobile && HeroTextBlock}

          {/* Button at the bottom remains inside hero-section for both layouts */}
          
        </div>
      </section>
      {HeroButton}

      {/* Disclaimer outside the hero section */}
      <p className="hero-disclaimer">
        *Based on a survey of 239 The Wellness customers.
      </p>
    </>
  );
};

export default HairBanner;
